import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function Faqs() {
  //traslate
  const { t, i18n } = useTranslation("global");

  useEffect(() => {
    document.title = "FAQS";

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container terms-conditions">
        <header className="bg-dark text-white text-center py-5 ">
          <h1 className="">{t("faqs.faqls")}</h1>
        </header>
        <div className="row pt-170 mt-5">
          <div className="content">
            <br />
            <h2>{t("faqs.Whatkind")}</h2>
            <p>{t("faqs.WhatkindText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.Platforms")}</h2>
            <p>{t("faqs.PlatformsText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.Responsive")}</h2>
            <p>{t("faqs.ResponsiveText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.Security")}</h2>
            <p>{t("faqs.SecurityText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.ThirdParty")}</h2>
            <p>{t("faqs.ThirdPartyText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.Considerations")}</h2>
            <p>{t("faqs.ConsiderationsText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.MobileApplications")}</h2>
            <p>{t("faqs.MobileApplicationsText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.ConnectedTVApplications")}</h2>
            <p>{t("faqs.ConnectedTVApplicationsText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.AutomobileApplications")}</h2>
            <p>{t("faqs.AutomobileApplicationsText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.Unique")}</h2>
            <p>{t("faqs.UniqueText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.Support")}</h2>
            <p>{t("faqs.SupportText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.SystemUpdates")}</h2>
            <p>{t("faqs.SystemUpdatesText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.OptimalPerformance")}</h2>
            <p>{t("faqs.OptimalPerformanceText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.TechnicalConsulting")}</h2>
            <p>{t("faqs.TechnicalConsultingText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.UIUXDesign")}</h2>
            <p>{t("faqs.UIUXDesignText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.InvisiblePathways")}</h2>
            <p>{t("faqs.InvisiblePathwaysText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.IntuitiveDesign")}</h2>
            <p>{t("faqs.IntuitiveDesignText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.HumanCenteredDesign")}</h2>
            <p>{t("faqs.HumanCenteredDesignText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.ProductResearch")}</h2>
            <p>{t("faqs.ProductResearchText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.IdealDigitalExperience")}</h2>
            <p>{t("faqs.IdealDigitalExperienceText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.TechnicalProductResearch")}</h2>
            <p>{t("faqs.TechnicalProductResearchText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.ProcessInAction")}</h2>
            <p>{t("faqs.ProcessInActionText")}</p>
          </div>
          <div className="content">
            <h2>{t("faqs.EvaluateUserExperience")}</h2>
            <p>{t("faqs.EvaluateUserExperienceText")}</p>
          </div>
        </div>
      </div>
    </>
  );
}
export { Faqs };
