import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

import particlesConfig from "./config/particles.config";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

//traslate
import { useTranslation, Trans } from "react-i18next";
//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//blog service
import blogService from "../../services/blog.service";

function Home() {
  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  //images
  const shape = require("../../assets/img/shape/3.webp");
  const background_video = require("../../assets/img/shape/video.webp");
  const development = require("../../assets/img/service/development_home.png");
  const securit = require("../../assets/img/service/system_integration.png");
  const integration = require("../../assets/img/service/data_security.png");
  const maintenance = require("../../assets/img/service/product_maintance.png");
  const l01 = require("../../assets/img/logos/01.png");
  const l02 = require("../../assets/img/logos/02.png");
  const l03 = require("../../assets/img/logos/03.png");
  const l04 = require("../../assets/img/logos/04.png");
  const l05 = require("../../assets/img/logos/05.png");
  const l06 = require("../../assets/img/logos/06.png");
  const l07 = require("../../assets/img/logos/07.png");
  const l08 = require("../../assets/img/logos/09.png");
  const l09 = require("../../assets/img/logos/09.png");
  const l10 = require("../../assets/img/logos/10.png");
  const l11 = require("../../assets/img/logos/11.png");
  const l12 = require("../../assets/img/logos/12.png");
  const l13 = require("../../assets/img/logos/13.png");
  const l14 = require("../../assets/img/logos/14.png");
  const l15 = require("../../assets/img/logos/15.png");
  const l16 = require("../../assets/img/logos/16.png");
  const l17 = require("../../assets/img/logos/17.png");
  const l18 = require("../../assets/img/logos/18.png");
  const l19 = require("../../assets/img/logos/19.png");
  const l20 = require("../../assets/img/logos/20.png");
  const l21 = require("../../assets/img/logos/21.png");
  const l22 = require("../../assets/img/logos/22.png");
  const l23 = require("../../assets/img/logos/23.png");
  const l24 = require("../../assets/img/logos/24.png");
  const l25 = require("../../assets/img/logos/25.png");
  const l26 = require("../../assets/img/logos/26.png");
  const l27 = require("../../assets/img/logos/27.png");
  const l28 = require("../../assets/img/logos/28.png");
  const l29 = require("../../assets/img/logos/29.png");
  const l30 = require("../../assets/img/logos/30.png");

  const videoCharlie =
    "https://charlieapps.b-cdn.net/cdn/videos/charlieapps-intro.mp4";
  const videoCharlieMovil =
    "https://charlieapps.b-cdn.net/cdn/videos/Video-Movil.mp4";

  /* slide home lasted post */
  const pt1 = require("../../assets/img/home/41image2.png");
  const pt2 = require("../../assets/img/home/4391692830563311-1.jpg");
  const pt3 = require("../../assets/img/home/9901693929779370.png");
  const autor = require("../../assets/img/home/autor.png");

  const particlesInit = async (main) => {
    await loadFull(main);
  };
  //traslate
  const { t, i18n } = useTranslation("global");
  const [showModal, setShowModal] = useState(false);
  const [ipad, setIpad] = useState(false);

  const [languageGet, setLanguageGet] = useState(i18n.language);
  const language_get = i18n.language;
  const [languageDate, setLanguageDate] = useState([]);

  //data blog
  const [arrBlog, setArrBlog] = useState("");

  //size detect
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    document.title = "Home";

    onGetBlog(language_get);
    switch (language_get) {
      case "es":
        setLanguageDate("es-ES");
        break;
      case "fr":
        setLanguageDate("fr-FR");
        break;
      case "it":
        setLanguageDate("it-IT");
        break;
      case "de":
        setLanguageDate("de-DE");
        break;
      case "en":
        setLanguageDate("en-US");
        break;
      default:
        setLanguageDate("en-US");
    }
    if (windowSize.width >= 1200 && windowSize.width <= 1210) {
      //console.log('resolucion')
      setIpad(true);
      // alert(ipad);
    } else {
      setIpad(false);
    }
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      const scrollPercentage =
        (scrollTop / (scrollHeight - clientHeight)) * 100;
      if (windowSize.width >= 900) {
        if (scrollPercentage >= 36 && scrollPercentage <= 37) {
        }
      } else if (windowSize.width >= 770 && windowSize.width <= 900) {
        if (scrollPercentage >= 34 && scrollPercentage <= 35) {
        }
      } else if (windowSize.width <= 770) {
        if (scrollPercentage >= 16 && scrollPercentage <= 17) {
        }
      }
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize, language_get, isMobile]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  //get lastet blog
  const onGetBlog = async (languageGet) => {
    try {
      const getBlogs = await blogService.getAllBlogsPublicLastet(languageGet);
      if (getBlogs.status === 200 && getBlogs.data.statusCode === 404) {
      } else if (getBlogs.status === 200) {
        const blogs = getBlogs.data;
        const chunks = splitArrayIntoChunks(blogs, 3);
        setArrBlog(chunks);
      } else {
      }
    } catch (error) {}
  };
  const splitArrayIntoChunks = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <>
      <div className="slider-area bg-img-12 fix">
        <Particles
          className="tspartilces"
          id="particles-here"
          init={particlesInit}
          options={particlesConfig}
        />
        <div
          className="container d-flex justify-content-center"
          style={{ position: "relative" }}
        >
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center home-bg">
              <h1 dangerouslySetInnerHTML={{ __html: t("home.title") }}></h1>
              {/* <p className=' '>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p> */}
              <NavLink to={"/services"} className="btn rt-btn">
                {t("home.btn-title")}
              </NavLink>
            </div>
          </div>
        </div>
        <div className="curve-bottom"></div>
      </div>

      <section className="home about-area style2 white-bg section-pt-2">
        <div className="container">
          <div className="row  ">
            <div className="col-md-12 text-center align-items-center justify-content-center about-home">
              <div className="shape">
                <img
                  loading="lazy"
                  src={shape}
                  alt="We Are A Digital Studio Crafting Beautiful Experiences, Creating Premium Designs With The Latest Technologies"
                />
              </div>
              <h3>{t("home.about-us-title")}</h3>
              <h1
                dangerouslySetInnerHTML={{
                  __html: t("home.aboutusdescription"),
                }}
              ></h1>
            </div>
          </div>
        </div>
      </section>
      <div className="services-area white-bg bg-img-20 section-padding-2 video-home">
        <div className="container">
          <div className="">
            <div className=" video-col text-center">
              <div>
                {isMobile ? (
                  <>
                    <video
                      playsInline
                      loop
                      autoPlay
                      muted
                      controls
                      className="video-element "
                    >
                      <source src={videoCharlieMovil} type="video/mp4" />
                    </video>
                  </>
                ) : (
                  <>
                    <video
                      playsInline
                      loop
                      autoPlay
                      muted
                      controls
                      className="video-element "
                    >
                      <source src={videoCharlie} type="video/mp4" />
                    </video>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services-area style2 bg-style white-bg section-padding-1">
        <div className="container-lg">
          <div className="col-xs-12 text-center">
            <div className=" mb-85 home about-home single-service-item ">
              <div className="shape">
                <img
                  loading="lazy"
                  src={shape}
                  alt="We Are A Digital Studio Crafting Beautiful Experiences, Creating Premium Designs With The Latest Technologies"
                />
              </div>
              <h3 className="theme-color">Charlie Apps</h3>
              <h1>{t("home.topService")}</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row text-center">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3  ">
              <NavLink to={"/app-development"}>
                <div className="single-service single-service-h ">
                  <div className="text relative">
                    <div className="shape">
                      <img
                        loading="lazy"
                        src={development}
                        alt="We build powerful custom mobile apps for iOS, Android, Apple TV, and other platforms, making your idea into a viable product."
                      />
                    </div>
                    <h4>{t("home.development")}</h4>
                    <p> {t("home.developmentText")}</p>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 ">
              <NavLink to={"services/system-cloud-integration"}>
                <div className="single-service single-service-h">
                  <div className="text relative">
                    <div className="shape">
                      <img
                        loading="lazy"
                        src={securit}
                        alt="System Integration We build powerful custom mobile apps for iOS, Android, Apple TV, and other platforms, making your idea into a viable product."
                      />
                    </div>
                    <h4>{t("home.systemIntegration")}</h4>
                    <p>{t("home.systemIntegrationText")}</p>
                  </div>
                </div>
              </NavLink>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 ">
              <NavLink to={"services/software-security-consulting"}>
                <div className="single-service single-service-h">
                  <div className="text relative">
                    <div className="shape">
                      <img
                        loading="lazy"
                        src={integration}
                        alt="Data security Enhance your digital products and brands with multiple security systems currently standard in the market, SSL, hash encryptions and more!"
                      />
                    </div>
                    <h4>{t("home.dataSecurity")}</h4>
                    <p>{t("home.dataSecurityText")}</p>
                  </div>
                </div>
              </NavLink>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 ">
              <NavLink to={"/Product-Support-Maintenance"}>
                <div className="single-service single-service-h">
                  <div className="text relative">
                    <div className="shape">
                      <img
                        loading="lazy"
                        src={maintenance}
                        alt="Product maintenance Is your brand or product currently stuck with the wrong technical partner? let us help you maintain your products and get your features up to date. "
                      />
                    </div>
                    <h4>{t("home.ProductMaintenance")}</h4>
                    <p>{t("home.ProductMaintenanceText")}</p>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="lastes-articles ">
        <div className="container">
          <div className="shape">
            <img
              loading="lazy"
              src={shape}
              alt="Lastet Articles charlie apps"
            />
            <h5>{t("home.lastetArticles")}</h5>
          </div>
          <div id="carouselLastedArticles" className="carousel slide ">
            <div className="carousel-indicators ">
              {Array.isArray(arrBlog) && arrBlog.length > 0 ? (
                arrBlog.map((item, i) => {
                  return (
                    <div key={i}>
                      {" "}
                      <button
                        type="button"
                        data-bs-target="#carouselLastedArticles"
                        data-bs-slide-to={i}
                        className={`${
                          i == 0 ? "active" : ""
                        } d-none d-lg-block`}
                        aria-current="true"
                        aria-label={`Slide ${i + 1}`}
                      ></button>
                    </div>
                  );
                })
              ) : (
                <> </>
              )}
              {/*  <button type="button" data-bs-target="#carouselLastedArticles" data-bs-slide-to="0" className="active d-none d-lg-block" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselLastedArticles" data-bs-slide-to="1" className='d-none d-lg-block' aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselLastedArticles" data-bs-slide-to="2" className='d-none d-lg-block' aria-label="Slide 3"></button> */}
            </div>
            <div className="carousel-inner">
              {Array.isArray(arrBlog) && arrBlog.length > 0 ? (
                arrBlog.map((item, i) => {
                  return (
                    <div
                      className={`carousel-item  ${i === 0 ? "active" : ""}`}
                      key={i}
                    >
                      <div className="row">
                        {item.map((itemG, iG) => {
                          const fechaG = new Date(itemG.bloCreateAt);
                          const opcionesFecha = { month: "long" };
                          const dateMonth = fechaG.toLocaleString(
                            `${languageDate}`,
                            opcionesFecha
                          );
                          const day = { day: "numeric" };
                          const dateDay = fechaG.toLocaleString(
                            `${languageDate}`,
                            day
                          );
                          return (
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-4 box"
                              key={iG}
                            >
                              <div className="header">
                                <NavLink
                                  to={`/blog-details/${encodeURIComponent(
                                    itemG.bloTitle
                                      .replace(/^\s+|\s+$/g, "")
                                      .replace(/\s+/g, "-")
                                  )}`}
                                >
                                  <img
                                    className="rounded"
                                    src={itemG.bloImage}
                                    alt="Lastet Articles charlie apps"
                                  />
                                </NavLink>
                              </div>
                              <div className="description">
                                <div className="autor-img">
                                  <Link
                                    to={`/blog-details/${encodeURIComponent(
                                      itemG.bloTitle
                                        .replace(/^\s+|\s+$/g, "")
                                        .replace(/\s+/g, "-")
                                    )}`}
                                  >
                                    <img
                                      loading="lazy"
                                      src={autor}
                                      alt="Lastet Articles charlie apps"
                                    />
                                  </Link>
                                </div>
                                <div className="titles">
                                  <h5>
                                    <NavLink
                                      to={`/blog-details/${encodeURIComponent(
                                        itemG.bloTitle
                                          .replace(/^\s+|\s+$/g, "")
                                          .replace(/\s+/g, "-")
                                      )}`}
                                    >
                                      {itemG.bloTitle}
                                    </NavLink>
                                  </h5>
                                  <p>
                                    {dateMonth}, {dateDay}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="text-center placeholder-glow">
                    <span className="placeholder col-6"></span>
                    <span className="placeholder w-75"></span>
                    <br />
                    <span className="placeholder w-25"></span>
                  </div>
                </>
              )}
            </div>
            <button
              className="carousel-control-prev d-none d-lg-block"
              type="button"
              data-bs-target="#carouselLastedArticles"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true">
                <i className="fa-solid fa-chevron-left"></i>
              </span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next d-none d-lg-block"
              type="button"
              data-bs-target="#carouselLastedArticles"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true">
                <i className="fa-solid fa-chevron-right"></i>
              </span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div className="view-more d-lg-none .d-xl-block">
          <NavLink className="btn btn-primary " to={"/blog"}>
            View More <i className="fa fa-angle-right"></i>
          </NavLink>
        </div>
      </div>
      <section className="bg-img-13 home mt-5 ">
        <div className="container">
          <div className="row mt-5">
            <div className="col-md-12 text-center align-items-center justify-content-center about-home-slide top-services content">
              <div className="shape">
                <img
                  loading="lazy"
                  src={shape}
                  alt="integrate platforms charlie apps"
                />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: t("home.platforms") }}
              ></div>
            </div>
          </div>
        </div>
        <div
          id="carouselExampleInterval"
          className="carousel slide carousel-dark integrate"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner container">
            <div className="carousel-item active" data-bs-interval="3000">
              <div className="row">
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l01}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l02}
                    alt="integrate platforms charlie apps"
                  />
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l03}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l04}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l05}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l06}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l07}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l08}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <div className="row">
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l09}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l10}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l11}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l12}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l13}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l14}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l15}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l16}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <div className="row">
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l17}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l18}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l19}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l20}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l21}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l22}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l23}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l24}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <div className="row">
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l25}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l26}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l27}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l28}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l29}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
                <div className="col-6 col-md-3 col-lg-3 d-flex justify-content-center">
                  {" "}
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={l30}
                    alt="integrate platforms charlie apps"
                  />{" "}
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>
      {showModal && (
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="videoModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-fullscreen-sm-down modal-xl">
            <div className="modal-content">
              <div className="modal-body">
                <div className="ratio ratio-16x9">
                  <iframe
                    width="560"
                    height="315"
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/LJchv-79jQU"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={show}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        onHide={handleClose}
        centered
        className="modal fade"
      >
        <div className="ratio ratio-16x9">
          <iframe
            class="venoframe vbvid figlio"
            lassName="embed-responsive-item"
            webkitallowfullscreen=""
            mozallowfullscreen=""
            allowfullscreen
            src="https://www.youtube.com/embed/LJchv-79jQU?rel=0&amp;autoplay=1"
            frameborder="0"
          ></iframe>
        </div>
      </Modal>
    </>
  );
}
export { Home };
