import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

//contact
import { FormContact } from "../form-contact";

function TechnicalProductResearch() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const shape = require("../../../assets/img/shape/3.webp");
  const TPRSE = require("../../../assets/img/Technical-Product-Research-Services/Elemento-1.png");
  const icon1 = require("../../../assets/img/Technical-Product-Research-Services/Ico-a.png");
  const icon2 = require("../../../assets/img/Technical-Product-Research-Services/Ico-b.png");
  const icon3 = require("../../../assets/img/Technical-Product-Research-Services/Ico-c.png");
  const img1_1 = require("../../../assets/img/Technical-Product-Research-Services/Ico-1.png");
  const img1_2 = require("../../../assets/img/Technical-Product-Research-Services/ico-2.png");
  const img1_3 = require("../../../assets/img/Technical-Product-Research-Services/ico-3.png");
  const imgHow = require("../../../assets/img/Technical-Product-Research-Services/Img.png");

  // const img = require('../../../');
  useEffect(() => {
    document.title = "Technical Product Research";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="tpr-container" className="container-fluid mb-50 mt-5">
        <div className="row">
          <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-6 order-2 order-lg-1">
            <div id="tpr-services-div" className="col-12  mb-70">
              <div id="tpr-shape" className="shape">
                <img
                  loading="lazy"
                  src={shape}
                  alt="charlieapps charlie apps technical product research"
                />
              </div>
              <h3 className="theme-color">
                {t("TechnicalProductResearch.title")}
              </h3>
              <h1 className="mb-75">
                {t("TechnicalProductResearch.subTitle")}
              </h1>
              <p id="tpr-main-description">
                {t("TechnicalProductResearch.titleText")}
              </p>
              <div className="RSbutton-div mt-20 rest-top">
                <Link
                  to={"/contact"}
                  id="tpr-rsbutton"
                  className="btn btn-primary RSBtn w-50 "
                >
                  {" "}
                  {t("TechnicalProductResearch.btnRequest")}{" "}
                  <i className="fa fa-angle-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-6 order-1 order-lg-2">
            <div id="tpr-main-img" className="center-img-tpr tpr-main-img">
              <img
                loading="lazy"
                className="img-fluid"
                src={TPRSE}
                alt="charlieapps charlie apps technical product research"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="tpr-info-div row">
        <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div className="tpr-item-image text-center mb-20">
            <img
              className="tpr-item-logo"
              src={icon1}
              alt="charlieapps charlie apps technical product research We help you identify technical integrations that make sense for your product."
            />
          </div>
          <div className="tpr-item-text mb-20">
            <p>{t("TechnicalProductResearch.text1")}</p>
          </div>
          <div className="tpr-hr-lines">
            <hr />
          </div>
        </div>

        <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div className="tpr-item-image text-center mb-20">
            <img
              className="tpr-item-logo"
              src={icon2}
              alt="charlieapps charlie apps technical product research We assist in providing feasible technical end-results."
            />
          </div>
          <div className="tpr-item-text mb-20">
            <p>
              {" "}
              {t("TechnicalProductResearch.text2")}
              <br />
              <br />
            </p>
          </div>
          <div className="tpr-hr-lines">
            <hr />
          </div>
        </div>

        <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div className="tpr-item-image text-center mb-20">
            <img
              className="tpr-item-logo"
              src={icon3}
              alt="charlieapps charlie apps technical product research We help develop products or services to help avoid unforseen risks"
            />
          </div>
          <div className="tpr-item-text mb-20">
            <p>
              {t("TechnicalProductResearch.text3")}
              <br />
              <br />
            </p>
          </div>
          <div className="tpr-hr-lines">
            <hr className="tpr-mobi-hide" />
          </div>
        </div>
      </div>

      <div id="tpr-mid-container container-fluid">
        <div className="row">
          <div className="col-sm-12 col-md-6 tpr-mid-image-padding">
            <img
              className="tpr-sub-image tpr-item-1 img-fluid"
              src={imgHow}
              alt="charlieapps charlie apps technical product research"
            />
          </div>
          <div className="col-sm-12 col-md-6 tpr-mid-text-padding text-center">
            <h1 className="mb-20">{t("TechnicalProductResearch.howDone")}</h1>
            <p className="mb-20">{t("TechnicalProductResearch.howDoneText")}</p>
            <hr />
            <div className="tpr-info-icons">
              <img
                loading="lazy"
                className="img-fluid"
                src={img1_1}
                alt="charlieapps charlie apps technical product research"
              />
              <img
                loading="lazy"
                className="img-fluid"
                src={img1_2}
                alt="charlieapps charlie apps technical product research"
              />
              <img
                loading="lazy"
                className="img-fluid"
                src={img1_3}
                alt="charlieapps charlie apps technical product research"
              />
            </div>
            <div>
              <br />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-100">
        <div className="col-xs-12 text-center tpr-bottom-div">
          <h3 className="theme-color">
            {t("TechnicalProductResearch.WhatWeDo")}
          </h3>
          <h1 className="">{t("TechnicalProductResearch.Ourapproach")}</h1>
          <p id="tpr-bottom-description">
            {t("TechnicalProductResearch.OurapproachText")}
          </p>
        </div>
      </div>

      <div className="contact-form-area section-pt pb-50 gray-bg psm-bottom-contact">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <div className="heading mb-85 tpr-contact-heading">
                <h3>{t("TechnicalProductResearch.tell")}</h3>
                <p>{t("TechnicalProductResearch.tellDescription")}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="contact-form text-center">
                <div id="contact-response" className="mb-15 alert"></div>
                <div className="loader">
                  <i className="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <FormContact />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { TechnicalProductResearch };
