import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

//blog service
import blogService from "../../../../services/blog.service";

//auth
import { useAuth } from "../../../../context/AuthProvider";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

function BlogDashboard() {
  //data array blog
  const [arrBlog, setArrBlog] = useState([]);

  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  //validation auth
  const auth = useAuth();

  useEffect(() => {
    onGetBlog();
  }, []);

  const onGetBlog = async () => {
    try {
      let bearer_Token = {
        headers: {
          Authorization: `Bearer ${auth.authToken()}`,
        },
      };
      const getBlogs = await blogService.getAllBlogs(bearer_Token);
      //console.log(getBlogs);
      if (getBlogs.status === 200 && getBlogs.data.statusCode === 404) {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      } else if (getBlogs.status === 200) {
        setArrBlog(getBlogs.data);
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      if (error.response.status === 401) {
        auth.logout();
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    }
  };

  //active blog
  const onActive = async (id) => {
    try {
      let data = {
        bloActive: "yes",
      };
      let bearer_Token = {
        headers: {
          Authorization: `Bearer ${auth.authToken()}`,
        },
      };
      const getBlogs = await blogService.updateActiveBlog(
        id,
        data,
        bearer_Token
      );
      if (getBlogs.status === 200 && getBlogs.data.statusCode === 404) {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      } else if (
        (getBlogs.status === 200) &
        (getBlogs.data.statusCode === 200)
      ) {
        onGetBlog();
        setShowAlert(true);
        setMessage({ error: false, msg: "Updated successfully" });
      } else if (
        getBlogs.status === 200 &&
        getBlogs.data.output.statusCode === 401
      ) {
        auth.logout();
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      if (error.response.status === 401) {
        auth.logout();
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    }
  };

  //inactive blog
  const onInactive = async (id) => {
    try {
      let data = {
        bloActive: "no",
      };
      let bearer_Token = {
        headers: {
          Authorization: `Bearer ${auth.authToken()}`,
        },
      };
      const getBlogs = await blogService.updateActiveBlog(
        id,
        data,
        bearer_Token
      );
      if (getBlogs.status === 200 && getBlogs.data.statusCode === 404) {
        setShowAlert(true);
        setMessage({ error: true, msg: "There is no associated information" });
      } else if (
        (getBlogs.status === 200) &
        (getBlogs.data.statusCode === 200)
      ) {
        onGetBlog();
        setShowAlert(true);
        setMessage({ error: false, msg: "Updated successfully" });
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      if (error.response.status === 401) {
        auth.logout();
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    }
  };

  //search
  const [searchTerm, setSearchTerm] = useState("");
  const filteredProducts = arrBlog.filter((item) =>
    item.bloTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const handleClick = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };
  const prevButtonDisabled = currentPage === 1;
  const nextButtonDisabled =
    currentPage === Math.ceil(arrBlog.length / itemsPerPage);
  //emd pagination

  return (
    <>
      <div className="container-fluid">
        <h3>BLOGS</h3>
        <div className="row">
          <div className="d-flex justify-content-end mb-3">
            <Link
              to={"/dashboard/new-blog"}
              type="button"
              className="btn btn-outline-info btn-sm"
            >
              New <i className="fa-solid fa-plus"></i>
            </Link>
          </div>
          <div>
            {message ? (
              <Alert
                show={showAlert}
                variant={`${message.error ? "danger" : "success"}`}
              >
                <p className="alert-message">{message.msg}</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => setShowAlert(false)}
                    size="sm"
                    variant={`outline-${message.error ? "danger" : "success"}`}
                  >
                    Close
                  </Button>
                </div>
              </Alert>
            ) : null}
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-3">
            <form className="form-floating">
              <input
                className="form-control "
                type="search"
                placeholder="Search by Name"
                value={searchTerm}
                onChange={handleSearch}
              />
              <label className="mx-2" htmlFor="floatingInputValue">
                {" "}
                <i className="fa-solid fa-magnifying-glass"></i> Search
              </label>
            </form>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#image</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Autor</th>
                <th scope="col">Language</th>
                <th scope="col">Created At</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, i) => {
                const fecha = new Date(item.bloCreateAt);
                const opcionesFecha = {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                };
                const fechaFormateada = fecha.toLocaleString(
                  "es-ES",
                  opcionesFecha
                );
                if ("EN" === item.bloLanguage) {
                  return (
                    <tr key={i}>
                      <th scope="row">
                        <img
                          loading="lazy"
                          src={item.bloImage}
                          style={{ width: "60px" }}
                          className="rounded float-end"
                        />
                      </th>
                      <td>{item.bloTitle}</td>
                      <td>
                        {item.bloDescription.slice(0, 20)}
                        {item.bloDescription.length > 20 ? "..." : ""}
                      </td>
                      <td>{item.editor}</td>
                      <td>
                        <span className="badge text-bg-success">
                          {item.bloLanguage}
                        </span>
                      </td>
                      <td>{fechaFormateada}</td>
                      <td>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                        >
                          <Link
                            to={`/dashboard/edit-blog/${item.bloId}`}
                            type="button"
                            className="btn btn-outline-info btn-lg"
                          >
                            <i className="fa-solid fa-pen"></i>
                          </Link>
                          {item.bloActive == "yes" ? (
                            <>
                              <button
                                onClick={() => onInactive(item.bloId)}
                                type="button"
                                className="btn btn-outline-danger btn-lg"
                              >
                                {" "}
                                <i className="fa-solid fa-toggle-on"></i>{" "}
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => onActive(item.bloId)}
                                type="button"
                                className="btn btn-outline-dark btn-lg"
                              >
                                <i className="fa-solid fa-toggle-off"></i>{" "}
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                } else if (item.bloLanguage === null) {
                  return (
                    <tr key={i}>
                      <th scope="row">
                        <img
                          loading="lazy"
                          src={item.bloImage}
                          style={{ width: "60px" }}
                          className="rounded float-end"
                        />
                      </th>
                      <td>{item.bloTitle}</td>
                      <td>
                        {item.bloDescription.slice(0, 20)}
                        {item.bloDescription.length > 20 ? "..." : ""}
                      </td>
                      <td>{item.editor}</td>
                      <td>
                        <span className="badge text-bg-warning">
                          Doesn't have languages
                        </span>
                      </td>
                      <td>{fechaFormateada}</td>
                      <td>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic mixed styles example"
                        >
                          <Link
                            to={`/dashboard/edit-blog/${item.bloId}`}
                            type="button"
                            className="btn btn-outline-info btn-lg"
                          >
                            <i className="fa-solid fa-pen"></i>
                          </Link>
                          {item.bloActive == "yes" ? (
                            <>
                              <button
                                onClick={() => onInactive(item.bloId)}
                                type="button"
                                className="btn btn-outline-danger btn-lg"
                              >
                                {" "}
                                <i className="fa-solid fa-toggle-on"></i>{" "}
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => onActive(item.bloId)}
                                type="button"
                                className="btn btn-outline-dark btn-lg"
                              >
                                <i className="fa-solid fa-toggle-off"></i>{" "}
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li
                className={
                  prevButtonDisabled ? "disabled page-item" : "page-item"
                }
              >
                <a onClick={handlePrevClick} className="page-link">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              {pageNumbers.map((number) => (
                <li className="page-item" key={number}>
                  <a
                    className="page-link"
                    href="#"
                    onClick={(e) => handleClick(e, number)}
                  >
                    {number}
                  </a>
                </li>
              ))}
              <li
                className={
                  nextButtonDisabled ? "disabled page-item" : "page-item"
                }
              >
                <a onClick={handleNextClick} className="page-link">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}
export { BlogDashboard };
