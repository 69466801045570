import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

import { useAuth } from "../../../../context/AuthProvider";
//user service
import blogService from "../../../../services/blog.service";

//editor
import Editor from "./editor";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//validation form
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

function NewBlog() {
  //validation auth
  const auth = useAuth();

  const logo = require("../../../../assets/img/logo.png");
  //avatar
  const [photo, setPhoto] = useState(null);
  const [imageBlog, setimageBlog] = useState("");

  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  //text editor
  const [editorContent, setEditorContent] = useState("");

  //imaghe blog
  const handlePhotoSelect = (event) => {
    const file = event.target.files[0];
    let error = false;
    if (file && !/^(image\/jpeg|image\/png)$/.test(file.type)) {
      setPhoto("");
      error = true;
      setMessage({ error: true, msg: "The file must be of type PNG or JPG" });
      return;
    }

    const maxSize = 1 * 1024 * 1024; // 5 MB
    if (file && file.size > maxSize) {
      setPhoto("");
      error = true;
      setMessage({
        error: true,
        msg: `The file must be less than or equal to  ${
          maxSize / 1024 / 1024
        } MB`,
      });
      return;
    }
    setPhoto(event.target.files[0]);

    if (error === false) {
      handleSubmitAvatar(file);
    }
  };

  const handleSubmitAvatar = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    //console.log(formData);
    if (file) {
      let bearer_Token = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.authToken()}`,
        },
      };
      try {
        const uploadImage = await blogService.createBlogImages(
          formData,
          bearer_Token
        );
        // console.log(uploadImage.data.imaUrl)
        if (uploadImage.status === 200 && uploadImage.data.statusCode === 201) {
          //resolve(uploadImage.data.imaUrl);
          setimageBlog(uploadImage.data.imaUrl);
        } else {
          setShowAlert(true);
          setMessage({ error: true, msg: "An error has occurred! try again" });
        }
      } catch (error) {
        if (error.response.status === 401) {
          auth.logout();
        } else {
          setShowAlert(true);
          setMessage({ error: true, msg: "An error has occurred! try again" });
        }
      }
    }
  };

  //text editor and formmik send data
  const handleEditorContentChange = (content) => {
    setEditorContent(content);
  };
  const handleSubmit = async (values) => {
    const data = {
      title: values.title,
      description: values.description,
      image: imageBlog ? imageBlog : "image",
      editorContent: editorContent, // Agrega el contenido del editor al objeto de datos
    };
    try {
      let bearer_Token = {
        headers: {
          Authorization: `Bearer ${auth.authToken()}`,
        },
      };
      const createBlog = await blogService.createBlog(data, bearer_Token);
      if (createBlog.status === 200 && createBlog.data.statusCode === 201) {
        //create blog
        setShow(true);
      } else if (
        createBlog.status === 200 &&
        createBlog.data.statusCode === 400
      ) {
        //error
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      } else if (
        createBlog.status === 200 &&
        createBlog.data.output.statusCode === 401
      ) {
        auth.logout();
      }
    } catch (error) {
      if (error.response.status === 401) {
        auth.logout();
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    }
  };
  //formik
  const formik = useFormik({
    initialValues: { title: "", description: "" },
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  //back button
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="d-flex justify-content-start mb-3">
            <Link
              onClick={goBack}
              type="button"
              className="btn btn-outline-dark btn-sm"
            >
              {" "}
              <i className="fa-solid fa-chevron-left"></i> Back
            </Link>
          </div>
          <h3>New Blog</h3>
          {message ? (
            <Alert
              show={showAlert}
              variant={`${message.error ? "danger" : "success"}`}
            >
              <p className="alert-message">{message.msg}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => setShowAlert(false)}
                  size="sm"
                  variant={`outline-${message.error ? "danger" : "success"}`}
                >
                  Close
                </Button>
              </div>
            </Alert>
          ) : null}

          <form onSubmit={formik.handleSubmit}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="mb-3 ">
                <label className="form-label">Title</label>
                <input
                  name="title"
                  type="text"
                  placeholder="Enter Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.title && formik.touched.title && (
                  <label className="text-danger">{formik.errors.title}</label>
                )}
              </div>
              <div className="mb-3 ">
                <label className="form-label">Description</label>
                <textarea
                  name="description"
                  type="text"
                  placeholder="Enter Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.description && formik.touched.description && (
                  <label className="text-danger">
                    {formik.errors.description}
                  </label>
                )}
              </div>
              <div className="row mb-3">
                <div className="col-lg-6  col-md-12 col-sm-12 col-xs-12">
                  <img
                    loading="lazy"
                    src={imageBlog ? imageBlog : logo}
                    className="w-50 avatar img-circle img-thumbnail"
                    alt="item"
                  />
                  <div className="form-text">Image Item.</div>
                </div>
                <div className="col-lg-9  col-md-12 col-sm-12 col-xs-12">
                  <input
                    id="item_image"
                    onChange={handlePhotoSelect}
                    name="item_image"
                    className="form-control form-control-sm mt-3"
                    type="file"
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 border">
              <Editor onEditorContentChange={handleEditorContentChange} />
            </div>
            <button
              disabled={!formik.isValid}
              className="w-100 btn btn-lg primary-color-bg mt-3"
              type="submit"
            >
              Save
            </button>
          </form>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className=" modal-login"
      >
        <div className="modal-content content">
          <div className="modal-body">
            <p className="text-center"> Blog has been created!.</p>
          </div>
          <div className="modal-footer footer-login-modal  d-md-block  text-center">
            <NavLink to={"/dashboard/blog-dashboard"}>
              <button type="button" className="btn btn-sm btn-send ">
                Close
              </button>
            </NavLink>
          </div>
        </div>
      </Modal>
    </>
  );
}
export { NewBlog };
