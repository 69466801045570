import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function SystemCloudIntegration() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const shape = require("../../../assets/img/service/new/shape.png");
  const cloudImage = require("../../../assets/img/service/new/cloud_integration.jpg");
  const cloudIcon = require("../../../assets/img/service/new/cloundIcon.png");
  const topCharlie = require("../../../assets/img/service/new/top_charlie.png");

  const icon1 = require("../../../assets/img/service/new/skicon.png");
  const icon2 = require("../../../assets/img/service/new/swicon.png");
  const icon3 = require("../../../assets/img/service/new/suxicon.png");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = "System and cloud Integration";

    window.scrollTo(0, 0);
  }, [screenWidth]);
  return (
    <>
      <div className="services-essb pt-essb">
        <div className="row enteripse">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 img order-2 order-sm-2 order-md-1 order-lg-1">
            <img
              loading="lazy"
              className="top"
              src={topCharlie}
              alt="services charlieapps charlie system and cloud integration "
            />
            <img
              loading="lazy"
              className="img-bg"
              src={cloudImage}
              alt="services charlieapps charlie system and cloud integration "
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 header order-1 order-sm-1 order-md-2 order-lg-2">
            <div className="shape">
              <img
                loading="lazy"
                src={shape}
                alt="services charlieapps charlie system and cloud integration "
              />
            </div>
            <div className="title">
              <img
                loading="lazy"
                src={cloudIcon}
                alt="services charlieapps charlie system and cloud integration "
              />
              <h6>{t("systemIntegration.title")}</h6>
              <h4
                dangerouslySetInnerHTML={{
                  __html: t("systemIntegration.subTitle"),
                }}
              ></h4>
              <p>{t("systemIntegration.descriptionTitle")}</p>
              <p>{t("systemIntegration.descriptionTitleTwo")}</p>
              <div className="btn-essb">
                <NavLink to={"/contact"}>
                  {t("systemIntegration.serviceBtn")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="cloud-container container mt-5">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 box">
              <div className="content">
                <div className="description">
                  <img
                    loading="lazy"
                    src={icon2}
                    alt="services charlieapps charlie system and cloud integration Unified Assessment"
                  />
                  <hr />
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: t("systemIntegration.serviceOneTitle"),
                    }}
                  ></h4>
                  <p>{t("systemIntegration.serviceOne")}</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 box">
              <div className="content">
                <div className="description">
                  <img
                    loading="lazy"
                    src={icon1}
                    alt="services charlieapps charlie system and cloud integration Tailored Integration Strategy"
                  />
                  <hr />
                  <h4>{t("systemIntegration.serviceTwoTitle")}</h4>
                  <p>{t("systemIntegration.serviceTwo")}</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 box">
              <div className="content">
                <div className="description">
                  <img
                    loading="lazy"
                    src={icon3}
                    alt="services charlieapps charlie system and cloud integration Implementation & Scaling"
                  />
                  <hr />
                  <h4>{t("systemIntegration.serviceThreeTitle")}</h4>
                  <p>{t("systemIntegration.serviceThree")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { SystemCloudIntegration };
