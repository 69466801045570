import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function AppDevelopment() {
  //traslate
  const { t, i18n } = useTranslation("global");
  const shape = require("../../../assets/img/shape/3.webp");
  const phone = require("../../../assets/img/app-development/phone-1.gif");

  const ios = require("../../../assets/img/app-development/Ico--ios.webp");
  const ondroid = require("../../../assets/img/app-development/Ico-Android.webp");

  const PDF = require("../../../assets/img/app-development/Product-Discovery-and-Feasibility.webp");
  const MAP = require("../../../assets/img/app-development/Mobile-App-Development.webp");
  const CTAA = require("../../../assets/img/app-development/Connected-TV-and-Automobile-App.webp");

  const IPDF = require("../../../assets/img/app-development/Ico-Product-Discovery-and-Feasibility.webp");
  const IPDFF = require("../../../assets/img/app-development/Product-Discovery-and-Feasibility.webp");
  const MADICON = require("../../../assets/img/app-development/Mobile-Applications-Development.webp");
  const MAD = require("../../../assets/img/app-development/Mobile-App-Development.webp");
  const CTAICON = require("../../../assets/img/app-development/Ico-Connected TV Applications.webp");
  const CTAS = require("../../../assets/img/app-development/Connected-TV-Applications.webp");
  const AUPICON = require("../../../assets/img/app-development/Automobile-Applications.webp");
  const AUP = require("../../../assets/img/app-development/Connected-TV-and-Automobile-App.webp");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = "App Development";

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  return (
    <>
      <div
        id="awd-slider"
        className="slider-area height-100 bg-img-21 overlay dark-4 fix"
      >
        <div className="d-table-changes">
          <div className="dt-cell">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <div className="slide-caption style4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="proper-spacing" className="contact-info-area white-bg">
        <div className="container-fluid">
          <div className="row ">
            <div
              id="proper-spacing"
              className="col-12 col-sm-12 col-md-12 col-lg-4 order-2 order-lg-1"
            >
              <div
                id="resinfoarea"
                className="equal-style height-600 d-table app-dev-phone"
              >
                <div className="row dt-cell text-center">
                  <div className="col-xs-12 text-center">
                    <div id="infoareaheading" className="heading">
                      <div className="shape">
                        <img
                          loading="lazy"
                          src={shape}
                          alt="services app development"
                        />
                      </div>
                      <h3 className="theme-color">
                        {t("appDevelopment.title")}
                      </h3>
                      <h1>{t("appDevelopment.subtitle")}</h1>
                      <p>{t("appDevelopment.titleText")}</p>
                      <hr />
                    </div>
                  </div>
                  <div id="flex-res ">
                    <div className="mobi-mb-50">
                      <div className="info-box">
                        <img loading="lazy" src={ios} alt="iOS App" />
                      </div>
                    </div>
                    <div className="mb-50">
                      <div className="info-box">
                        <img
                          loading="lazy"
                          src={ondroid}
                          alt="charlie apps charlieapps services app development android app"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="relative-div"
              className="col-12 col-sm-12 col-md-4 col-lg-4 order-1 order-lg-2"
            >
              <div className="phone">
                <img
                  className="img-fluid"
                  src={phone}
                  alt="charlie apps charlieapps services System Integration"
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4  services-development order-2 order-lg-3">
              <div id="resinforight" className="equal-style  d-table">
                <div id="margin-control" className="row dt-cell">
                  <div id="infodiv-landscape" className="col-12 col-sm-12 ">
                    <div id="" className="text-mobi ">
                      <div
                        id="mobi-align-image"
                        className="col-12 col-sm-12 col-md-12 col-lg-2 mobi-align-img"
                      >
                        <img
                          className="sub-img "
                          src={PDF}
                          alt="charlie apps charlieapps services Enterprise Solutions"
                        />
                      </div>
                      <div
                        id="info-div"
                        className="col-12 col-sm-12 col-md-12 col-lg-10 size-dev"
                      >
                        <h4>
                          {t("appDevelopment.ProductDiscoveryFeasibility")}
                        </h4>
                        <p className="text-justify">
                          {t("appDevelopment.ProductDiscoveryFeasibilityText")}
                        </p>
                        <br />
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div id="infodiv-landscape" className="col-xs-12 col-sm-12 ">
                    <div id="mobi-mb-25" className="text-mobi">
                      <div
                        id="mobi-align-image"
                        className="col-12 col-sm-12 col-md-12 col-lg-2 mobi-align-img"
                      >
                        <img
                          className="sub-img "
                          src={MAP}
                          alt="charlie apps charlieapps services Security Consulting"
                        />
                      </div>
                      <div
                        id="info-div"
                        className="col-12 col-sm-12 col-md-12 col-lg-10 size-dev"
                      >
                        <h4>{t("appDevelopment.MobileAppDevelopment")}</h4>
                        <p className="text-justify">
                          {" "}
                          {t("appDevelopment.MobileAppDevelopmentText")}
                        </p>
                        <br />
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div id="infodiv-landscape" className="col-xs-12 col-sm-12 ">
                    <div id="mobi-mb-25" className="text-mobi">
                      <div
                        id="mobi-align-image"
                        className="col-12 col-sm-12 col-md-12 col-lg-2 mobi-align-img "
                      >
                        <img
                          className="sub-img  "
                          src={CTAA}
                          alt="charlie apps charlieapps services Security Consulting"
                        />
                      </div>
                      <div
                        id="info-div"
                        className="col-12 col-sm-12 col-md-12 col-lg-10 size-dev"
                      >
                        <h4>{t("appDevelopment.ConnectedAutomobileApp")}</h4>
                        <p className="text-justify">
                          {t("appDevelopment.ConnectedAutomobileAppText")}
                        </p>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="RSbutton-div ui text-center mt-5 ">
        <Link to={"/contact"} className="btn btn-primary RSBtn w-25">
          {t("appDevelopment.btnRequest")} <i className="fa fa-angle-right"></i>
        </Link>
      </div>
      <div id="awd-sp" className="white-bg section-padding new-row-pd ">
        <div className="">
          <div id="no-pad" className="flex-hybrid row ">
            <div
              id="no-pad"
              className="col-xs-12 col-sm-6 col-md-6 p-0"
              style={{ order: screenWidth < 900 ? "2" : "1" }}
            >
              <div className="featured-areaawd">
                <div id="text-relativebox" className="text relative">
                  <div className="shape-awd">
                    <img
                      loading="lazy"
                      src={IPDF}
                      alt="charlie apps charlieapps services System Integration"
                    />
                  </div>
                  <h4>{t("appDevelopment.ProductDiscoveryandFeasibility")}</h4>
                  <p>
                    {t("appDevelopment.ProductDiscoveryandFeasibilityText")}
                  </p>
                </div>
              </div>
            </div>
            <div
              id="no-pad"
              className="col-xs-12 col-sm-6 col-md-6 p-0"
              style={{ order: screenWidth < 900 ? "1" : "1" }}
            >
              <div className="image relative">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={IPDFF}
                  alt="charlie apps charlieapps services Product Discovery And Feasibility"
                />
              </div>
            </div>
          </div>
          <div id="no-pad" className="flex-hybrid row">
            <div
              id="no-pad"
              className="col-xs-12 col-sm-6 col-md-6 p-0"
              style={{ order: "1" }}
            >
              <div className="image relative">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={MADICON}
                  alt="charlie apps charlieapps services Mobile Applications Development"
                />
              </div>
            </div>
            <div
              id="no-pad"
              className="col-xs-12 col-sm-6 col-md-6 p-0"
              style={{ order: "2" }}
            >
              <div className="featured-areaawd">
                <div id="text-relativebox" className="text relative">
                  <div className="shape-awd">
                    <img
                      loading="lazy"
                      src={MAD}
                      alt="charlie apps charlieapps services Mobile Applications Development"
                    />
                  </div>
                  <h4>{t("appDevelopment.MobileApplicationsDevelopment")}</h4>
                  <hr />
                  <p>{t("appDevelopment.MobileApplicationsDevelopmentText")}</p>
                </div>
              </div>
            </div>
          </div>
          <div id="no-pad" className="flex-hybrid row">
            <div
              id="no-pad"
              className="col-xs-12 col-sm-6 col-md-6 p-0"
              style={{ order: screenWidth < 900 ? "2" : "1" }}
            >
              <div className="featured-areaawd">
                <div id="text-relativebox" className="text relative">
                  <div className="shape-awd">
                    <img
                      className="img-fluid"
                      src={CTAICON}
                      alt="charlie apps charlieapps Connected TV Applications"
                    />
                  </div>
                  <h4>{t("appDevelopment.ConnectedTvApplications")}</h4>
                  <p>{t("appDevelopment.ConnectedTvApplicationsText")}</p>
                </div>
              </div>
            </div>
            <div
              id="no-pad"
              className="col-xs-12 col-sm-6 col-md-6 p-0"
              style={{ order: "1" }}
            >
              <div className="image relative">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={CTAS}
                  alt="charlie apps charlieapps Connected TV Applications"
                />
              </div>
            </div>
          </div>
          <div id="no-pad" className="flex-hybrid row">
            <div
              id="no-pad"
              className="col-xs-12 col-sm-6 col-md-6 p-0"
              style={{ order: "1" }}
            >
              <div className="image relative">
                <img
                  loading="lazy"
                  className="img-fluid"
                  src={AUPICON}
                  alt="charlie apps charlieapps Automobile Applications"
                />
              </div>
            </div>
            <div
              id="no-pad"
              className="col-xs-12 col-sm-6 col-md-6 p-0"
              style={{ order: "2" }}
            >
              <div className="featured-areaawd">
                <div id="text-relativebox" className="text relative">
                  <div className="shape-awd">
                    <img
                      loading="lazy"
                      src={AUP}
                      alt="charlie apps charlieapps Automobile Applications"
                    />
                  </div>
                  <h4>{t("appDevelopment.AutomobileApplications")}</h4>

                  <p>{t("appDevelopment.AutomobileApplicationsText")} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="about-areapad" className="about-area style2 mb-150">
        <div className="container">
          <div className="col-xs-12 col-sm-11 col-md-10 column-center">
            <div className="about-text text-center">
              <h1 className="theme-color">
                {t("appDevelopment.ReliableDevelopmentTitle")}
              </h1>
              <h1 className="sub-text">
                {t("appDevelopment.ReliableDevelopmentTitleSolution")}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { AppDevelopment };
