import React, { useState, useEffect } from "react";
import {
  Link,
  NavLink,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

//user service
import AuthService from "../../../services/auth.service";

//auth
import { useAuth, AuthRoute } from "../../../context/AuthProvider.js";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//bootstrap
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//validation form
const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/(?=.*[0-9])/, "Password must contain a number."),
});

function LogIn() {
  const logo = require("../../../assets/img/logo.png");

  //naviagte
  const navigate = useNavigate();
  const location = useLocation();

  //validation auth
  const auth = useAuth();

  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  const onLogin = async (values) => {
    try {
      let data = {
        email: values.email,
        password: values.password,
      };
      const login = await AuthService.login(data);
      //console.log(login)
      //console.log(login.data)
      if (
        (login.status === 200) & (login.data.userEmail === values.email) &&
        login.data.roles === "admin"
      ) {
        //rendirect log in
        const accessToken = login.data?.accessToken;
        sessionStorage.setItem(
          "auth",
          JSON.stringify({
            email: login.email,
            roles: login.data?.roles,
            accessToken: accessToken,
            user: login.data?.userId,
          })
        );
        setMessage({
          error: false,
          msg: "Login successfully ... redirecting ... ",
        });

        setTimeout(() => {
          auth.loginOnNew(login.userEmail);
          //veryfi direction to log in
          if (!location.state?.from) {
            navigate("/dashboard");
          } else {
            navigate(location.state.from);
          }
        }, 2000);
      } else if ((login.status === 200) & (login.data.statusCode === 404)) {
        //not found
        setShowAlert(true);
        setMessage({ error: true, msg: "Email incorrect! try again" });
      } else if ((login.status === 200) & (login.data.statusCode === 401)) {
        //unautorized
        setShowAlert(true);
        setMessage({ error: true, msg: "Password incorrect! try again" });
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "you don't have permissions" });
      }
    } catch (error) {
      setShowAlert(true);
      setMessage({ error: true, msg: "An error has occurred!" });
    }
  };

  //formik
  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validateOnBlur: true,
    onSubmit: (values) => {
      onLogin(values);
    },
    validationSchema: validationSchema,
  });

  return (
    <>
      <div className="container top-header">
        <div className="row">
          <main className="form-signin w-100 m-auto text-center">
            <form onSubmit={formik.handleSubmit}>
              <img
                loading="lazy"
                className="mb-4 img-fluid mx-auto d-block"
                src={logo}
              />
              <h1 className="h3 mb-3 mt-3 fw-normal"> sign in</h1>
              {message ? (
                <Alert
                  show={showAlert}
                  variant={`${message.error ? "danger" : "success"}`}
                >
                  <p className="alert-message">{message.msg}</p>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => setShowAlert(false)}
                      size="sm"
                      variant={`outline-${
                        message.error ? "danger" : "success"
                      }`}
                    >
                      Close
                    </Button>
                  </div>
                </Alert>
              ) : null}
              <div className="form-floating">
                <input
                  name="email"
                  type="text"
                  placeholder="Enter your email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.email && formik.touched.email ? (
                  <label className="text-danger">
                    Email: {formik.errors.email}
                  </label>
                ) : (
                  <label>Email</label>
                )}
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />

                {formik.errors.password && formik.touched.password ? (
                  <label className="text-danger">
                    Password: {formik.errors.password}
                  </label>
                ) : (
                  <label>Password</label>
                )}
              </div>
              <button
                disabled={!formik.isValid}
                className="w-100 btn btn-lg  primary-color-bg"
                type="submit"
              >
                Sign in
              </button>
            </form>
            <div className="mt-3 mb-3">
              <Link to={"/recovery"} className="no-underline primary-color">
                recover password
              </Link>
              <hr />
              <Link to={"/new-user"} className="no-underline primary-color">
                Register
              </Link>
            </div>
            <p className="mt-3 mb-3 text-body-secondary">&copy; 2023</p>
          </main>
          <div></div>
        </div>
      </div>
    </>
  );
}
export { LogIn };
