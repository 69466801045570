import React, { useEffect } from "react";
import Slider from "react-slick";

const SliderSocial = (props) => {
  useEffect(() => {}, [props]);

  const imagesSlide = () => {
    const { data } = props;

    if (!data || !Array.isArray(data) || data.length === 0) {
      return null;
    }

    const baseUrl = window.location.origin;

    const settings = {
      customPaging: function (i) {
        return (
          <a className="thumbnail">
            <img src={`${data[i]}`} />
          </a>
        );
      },
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="slider-container slider-social">
        <Slider {...settings}>
          {data.map((image, index) => (
            <div key={index} className="banner">
              <img src={`${image}`} />
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  return <>{imagesSlide()}</>;
};

export default SliderSocial;
