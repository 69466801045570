import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function About() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const shape = require("../../assets/img/shape/3.webp");
  const about01 = require("../../assets/img/about/Our-Vision.webp");
  const about02 = require("../../assets/img/about/We-Create-Design.webp");
  const about03 = require("../../assets/img/about/Lets-connect.webp");
  const carlos = require("../../assets/img/about/Carlos-Ovalles.webp");
  const maju = require("../../assets/img/about/Maju-Sandino.webp");
  //size detect
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  useEffect(() => {
    document.title = "About Us";

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <section className="about-us-vision mt-div">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 description order-2 order-lg-1">
              <div className="title">
                <img loading="lazy" src={shape} alt="about us" />
                <h5>{t("about.title")}</h5>
                <h3>{t("about.titleVision")}</h3>
              </div>
              <div className="content">
                <p>{t("about.textVisionOne")}</p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 image order-1 order-lg-2">
              <img
                loading="lazy"
                src={about01}
                alt="Charlie Apps is a full-stack technology and design"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="about-us-mission">
        <div className="container">
          <h3> {t("about.textMission")}</h3>
          <p> {t("about.textMissionDescription")}</p>
          <div className="btn-request">
            <NavLink to={"/contact"}>
              {t("about.btnAbout")}{" "}
              <i className="fa-solid fa-chevron-right"></i>
            </NavLink>
          </div>
        </div>
      </section>
      <section className="about-we-create">
        <div className="container">
          <div className="create-content">
            <h3>{t("about.TextWeCreate")} </h3>
          </div>
        </div>
        <img
          loading="lazy"
          src={about02}
          alt="We Create Reliable Designs A Development Solutions"
        />
      </section>
      <section className="about-history">
        <div className={`${isMobile ? "" : "container"}`}>
          <div className="title">
            <h3 className="title">{t("about.historyTitle")}</h3>
            <h3 className="subTitle">{t("about.historySubTitle")}</h3>
            <p>{t("about.historyDescription")}</p>
          </div>
          <div className="founders">
            <div className={`row ${isMobile ? "" : "container"}`}>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                <div className="c-m box-shadow ">
                  <p>{t("about.historyCarlosDescription")}</p>
                  <div className="profile">
                    <div className="avatar">
                      <img loading="lazy" src={carlos} alt="carlos ovalles" />
                    </div>
                    <div className="description">
                      <h3>Carlos Ovalles</h3>
                      <h4>{t("about.historyCarlosPosition")}</h4>
                      <a
                        href="https://www.linkedin.com/in/carlosovalles/"
                        target="_blank"
                      >
                        <img
                          loading="lazy"
                          src={about03}
                          alt="carlos ovalles"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                <div className="c-m box-shadow ">
                  <p>{t("about.historysMajuDescription")}</p>
                  <div className="profile">
                    <div className="avatar">
                      <img loading="lazy" src={maju} alt="Maju sandino" />
                    </div>
                    <div className="description">
                      <h3>Maju Sandino</h3>
                      <h4>{t("about.historyMajuPosition")}</h4>
                      <a
                        href="https://www.linkedin.com/in/majusandino/"
                        target="_blank"
                      >
                        <img
                          loading="lazy"
                          src={about03}
                          alt="maju sandino maria juliana sandino"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-start">
        <div className="content">
          <h3>{t("about.weWould")}</h3>
          <div className="btn-request">
            <NavLink to={"/contact"}>
              {t("about.btnAbout")}{" "}
              <i className="fa-solid fa-chevron-right"></i>
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}
export { About };
