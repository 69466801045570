import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function SecurityConsulting() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const shape = require("../../../assets/img/service/new/shape.png");
  const securityImage = require("../../../assets/img/service/new/softwareSecurity.jpg");
  const securityIcon = require("../../../assets/img/service/new/softwareSecurityIcon.png");
  const topCharlie = require("../../../assets/img/service/new/top_charlie.png");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = "Software Security Consulting";
    window.scrollTo(0, 0);
  }, [screenWidth]);
  return (
    <>
      <div className="services-essb pt-essb">
        <div className="row enteripse">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 img order-2 order-sm-2 order-md-1 order-lg-1">
            <img
              loading="lazy"
              className="top"
              src={topCharlie}
              alt="charlieapps charlie apps software security consulting"
            />
            <img
              loading="lazy"
              className="img-bg"
              src={securityImage}
              alt="charlieapps charlie apps software security consulting"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 header order-1 order-sm-1 order-md-2 order-lg-2">
            <div className="shape">
              <img
                loading="lazy"
                src={shape}
                alt="charlieapps charlie apps software security consulting"
              />
            </div>
            <div className="title">
              <img
                loading="lazy"
                src={securityIcon}
                alt="charlieapps charlie apps software security consulting"
              />
              <h6>{t("softwareConsulting.title")}</h6>
              <h4
                dangerouslySetInnerHTML={{
                  __html: t("softwareConsulting.subTitle"),
                }}
              ></h4>
              <p>{t("softwareConsulting.descriptionTitle")}</p>
              <p>{t("softwareConsulting.descriptionTitleTwo")}</p>
              <div className="btn-essb">
                <NavLink to={"/contact"}>
                  {t("softwareConsulting.serviceBtn")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="security-container container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 box">
              <div className="security-header ">
                <div className="number">
                  <h1>01</h1>
                  <hr />
                </div>
                <div className="title">
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: t("softwareConsulting.serviceOneTitle"),
                    }}
                  ></h4>
                </div>
              </div>
              <div className="description">
                <p>{t("softwareConsulting.serviceOne")}</p>
              </div>
              <hr />
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 box">
              <div className="security-header ">
                <div className="number">
                  <h1>02</h1>
                  <hr />
                </div>
                <div className="title">
                  <h4>{t("softwareConsulting.serviceTwoTitle")}</h4>
                </div>
              </div>
              <div className="description">
                <p>{t("softwareConsulting.serviceTwo")}</p>
              </div>
              <hr />
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 box">
              <div className="security-header ">
                <div className="number">
                  <h1>03</h1>
                  <hr />
                </div>
                <div className="title">
                  <h4>{t("softwareConsulting.serviceThreeTitle")}</h4>
                </div>
              </div>
              <div className="description">
                <p>{t("softwareConsulting.serviceThree")}</p>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { SecurityConsulting };
