import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function UxDesing() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const shape = require("../../../assets/img/shape/3.webp");
  const UEITE = require("../../../assets/img/User-Experience/Ico-Tailored Experience.png");
  const ResponsiveDesign = require("../../../assets/img/User-Experience/Ico-Responsive-design.png");
  const EmotionalConnection = require("../../../assets/img/User-Experience/Ico-Emotional-Connection.png");
  const imgBGVAR = require("../../../assets/img/User-Experience/Virtual-_-Augmented-Reality.jpg");
  //const img = require('../../../');
  // const img = require('../../../');
  useEffect(() => {
    document.title = "User Experience";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div
        id="ux-slider"
        className="slider-area height-100 bg-img-23 overlay dark-4 fix"
      >
        <div className="d-table-changes">
          <div className="dt-cell">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <div className="slide-caption style4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ux-dev-container">
        <div className="ux-title">
          <div id="ui-shape" className="shape">
            <img
              loading="lazy"
              src={shape}
              alt="charlieapps charlie apps user experience"
            />
          </div>
          <h3 className="theme-color">{t("ux.tite")}</h3>
          <h1>{t("ux.subTitle")}</h1>
          <p>{t("ux.titletext")}</p>
        </div>
        <div className="ux-info-div row">
          <div className="ux-item col-sm-12 col-md-12 col-lg-4 web-item-text">
            <div className="ux-item-image"></div>
            <div className="ux-item-text">
              <hr />
              <img
                className="ux-item-logo"
                src={UEITE}
                alt="charlieapps charlie apps user experience tailored design"
              />
              <h3 className="mb-30">{t("ux.TailoredDesign")}</h3>
              <p className="mb-30">{t("ux.TailoredDesignText")} </p>
            </div>
          </div>

          <div className="ux-item col-sm-12 col-md-12 col-lg-4 web-item-text">
            <div className="ux-item-image"></div>
            <div className="ux-item-text">
              <hr />
              <img
                className="ux-item-logo"
                src={ResponsiveDesign}
                alt="charlieapps charlie apps user experience responsive design"
              />
              <h3 className="mb-30">{t("ux.ResponsiveDesign")}</h3>
              <p className="mb-65">{t("ux.ResponsiveDesignText")} </p>
            </div>
          </div>

          <div className="ux-item col-sm-12 col-md-12 col-lg-4 web-item-text">
            <div className="ux-item-image"></div>
            <div className="ux-item-text">
              <hr />
              <img
                className="ux-item-logo"
                src={EmotionalConnection}
                alt="charlieapps charlie apps user experience emotional connection and value"
              />
              <h3 className="mb-30">{t("ux.EmotionalConnectionValue")}</h3>
              <p>{t("ux.EmotionalConnectionValueText")}</p>
            </div>
          </div>
        </div>
        <div className="RSbutton-div text-center ux-rsbtn-div mt-5 ui">
          <button className="btn btn-primary RSBtn ux-rsbtn w-25">
            {" "}
            {t("ux.btnRequest")}
            <i className="fa fa-angle-right"></i>
          </button>
        </div>

        <div id="ux-sp" className="white-bg">
          <img
            loading="lazy"
            src={imgBGVAR}
            alt="charlieapps charlie apps user experience"
          />
        </div>
        <div
          id="about-areapad "
          className="about-area about-pad-control style2 mb-150 mt-50 "
        >
          <div className="container ">
            <div className="col-xs-12 col-sm-11 col-md-10 column-center ">
              <div className="about-text text-center ">
                <h1 className="theme-color ">{t("ux.titleFooter")}</h1>
                <p>{t("ux.subtitleFooterText")}</p>
                <div className="RSbutton-div text-center ux-rsbtn-div  mt-3 mb-3">
                  <a
                    href="tel:18555277932"
                    className="btn btn-primary RSBtn ux-rsbtn w-25 "
                  >
                    {t("ux.btnFooter")} <i className="fa fa-phone "></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { UxDesing };
