import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class BlogService {

    //create images blog
    createBlogImages(data, authorization) {
        return http.post(`/blog/new/images`, data, authorization);
    }

    //create blog
    createBlog(data, authorization) {
        return http.post(`/blog/new/blog`, data, authorization);
    }

    //get blog dashboard
    getAllBlogs(authorization) {
        return http.get(`/blog/blog/get`, authorization);
    }

    //get blog by id
    getBlogById(id, authorization) {
        return http.get(`/blog/blog/get/${id}`, authorization);
    }
    //get blog by Languages
    getBlogByIdLg(id, data, authorization) {
        return http.post(`/blog/blog/getLg/${id}`, data, authorization);
    }

    //update blog
    updateBlog(id, data, authorization) {
        return http.patch(`/blog/blog/update/${id}`, data, authorization);
    }

    //update blog
    updateActiveBlog(id, data, authorization) {
        return http.patch(`/blog/blog/update_state/${id}`, data, authorization);
    }


    //public blog
    //get lastet 9 blog
    getAllBlogsPublicLastet(lg) {
        return http.get(`/blog/blog/public/lastet/${lg}`);
    }
    //get blog dashboard
    getAllBlogsPublic() {
        return http.get(`/blog/blog/public/get`);
    }
    getBlogByIdPublic(id) {
        return http.get(`/blog/blog/public/get/${id}`);
    }
    getBlogByIdNamePublic(id) {
        return http.get(`/blog/blog/public/geName/${id}`);
    }
}
export default new BlogService();