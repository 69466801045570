import React, { useEffect } from "react";

function VideoReel(props) {
  useEffect(() => {}, [props]);

  return (
    <>
      {props.data ? (
        <div className="video-reel">
          <video playsInline loop autoPlay controls={true} muted className=" ">
            <source src={props.data} type="video/mp4" />
          </video>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default VideoReel;
