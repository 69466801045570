import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

//fonts
import "@fortawesome/fontawesome-free/css/all.min.css";
//import styles #1
import "./assets/css/style.css";
import "./assets/css/venobox.css";
import "./assets/css/responsive.css";
import "./assets/js/main.js";
import "./assets/css/portfolio.css";
import "./assets/css/modal.css";
import "./assets/css/carousel3d.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBUvX83xc9kc_NdqHsd993dqaAL7u8KJa4",
  authDomain: "charlieapps-2014.firebaseapp.com",
  projectId: "charlieapps-2014",
  storageBucket: "charlieapps-2014.appspot.com",
  messagingSenderId: "96198065580",
  appId: "1:96198065580:web:ed4ba51d59cefc6e6c4e09",
  measurementId: "G-Y9MQDHS603",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
