import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function TermsConditions() {
  //traslate
  const { t, i18n } = useTranslation("global");

  useEffect(() => {
    document.title = "Terms And Conditions";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container terms-conditions">
        <header className="bg-dark text-white text-center py-5">
          <h1 className="">{t("terms.terms")}</h1>
        </header>
        <div className="row">
          <div className="content">
            <p>{t("terms.termsText")}</p>
            <br />
            <p>{t("terms.termsText2")}</p>
          </div>
          <div className="content">
            <h2>{t("terms.Useof")}</h2>
            <p>{t("terms.UseofText")}</p>
          </div>
          <div className="content">
            <h2>{t("terms.Regionalization")}</h2>
            <p>{t("terms.RegionalizationText")}</p>
          </div>
          <div className="content">
            <h2>{t("terms.Intellectual")}</h2>
            <p>{t("terms.IntellectualText")}</p>
          </div>
          <div className="content">
            <h2>{t("terms.Limitation")}</h2>
            <p>{t("terms.LimitationText")}</p>
          </div>
          <div className="content">
            <h2>{t("terms.Changes")}</h2>
            <p>{t("terms.ChangesText")}</p>
          </div>
          <div className="content">
            <h2>{t("terms.Governing")}</h2>
            <p>{t("terms.GoverningText")}</p>
          </div>
          <div className="content">
            <h2>{t("terms.Contacting")}</h2>
            <p>{t("terms.ContactingText")}</p>
            <ul>
              <li>{t("terms.email")}</li>
              <li>{t("terms.postal")}</li>
              <li>{t("terms.phone")}</li>
            </ul>
          </div>
          <div className="content">
            <h2>{t("terms.Termination")}</h2>
            <p>{t("terms.TerminationText")}</p>
          </div>
          <div className="content">
            <h2>{t("terms.Miscellaneous")}</h2>
            <p>{t("terms.MiscellaneousText")}</p>
          </div>
        </div>
      </div>
    </>
  );
}
export { TermsConditions };
