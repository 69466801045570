import React, { useState, useEffect } from 'react';
import { Link, NavLink, } from 'react-router-dom';

//blog service
import contact from '../../../../services/contact.service'

//auth
import { useAuth } from '../../../../context/AuthProvider';

//bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function ContactDashboard() {
    //data array blog
    const [arrContact, setArrContact] = useState([]);

    //message error
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);

    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    //validation auth
    const auth = useAuth();

    useEffect(() => {
        onGetContact()
    }, []);

    const onGetContact = async () => {
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const getContact = await contact.getAllContact(bearer_Token);
            //console.log(getContact)

            if (getContact.status === 200 && getContact.data.statusCode === 404) {
                setShowAlert(true);
                setMessage({ error: true, msg: "There is no associated information" });
            }
            else if (getContact.status === 200) {
                setArrContact(getContact.data);

            } else {
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            }
        } catch (error) {
            if (error.response.status === 401) {
                auth.logout()
            } else {
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            }
        }
    }


    //search
    const [searchTerm, setSearchTerm] = useState('');
    const filteredProducts = arrContact.filter((item) =>
        item.conName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };
    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
    const handleClick = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };
    const prevButtonDisabled = currentPage === 1;
    const nextButtonDisabled = currentPage === Math.ceil(arrContact.length / itemsPerPage);
    //emd pagination


    return (
        <>
            <div className='container-fluid'>
            <h3>CONTACT</h3>
                <div className='row'>
                    <div>
                        {message ?
                            <Alert show={showAlert} variant={`${message.error ? 'danger' : 'success'}`}>
                                <p className='alert-message'>{message.msg}</p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => setShowAlert(false)} size="sm" variant={`outline-${message.error ? 'danger' : 'success'}`}>
                                        Close
                                    </Button>
                                </div>
                            </Alert>
                            : null
                        }
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-3'>
                        <form className="form-floating">
                            <input className="form-control " type="search" placeholder="Search by Name" value={searchTerm} onChange={handleSearch} />
                            <label className='mx-2' htmlFor="floatingInputValue"> <i className="fa-solid fa-magnifying-glass"></i> Search</label>
                        </form>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#Request</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Subject</th>
                                <th scope="col">Created At</th>
                                <th scope="col">View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, i) => {
                                const fecha = new Date(item.conCreateAt);
                                const opcionesFecha = { day: 'numeric', month: 'long', year: 'numeric' };
                                const fechaFormateada = fecha.toLocaleString('es-ES', opcionesFecha);
                                return (
                                    <tr key={i}>
                                        <th scope="row">{item.conId}</th>
                                        <td>{item.conName}</td>
                                        <td>{item.conEmail}</td>
                                        <td>{item.conSubject}</td>
                                        <td>{fechaFormateada}</td>
                                        <td>
                                            <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                                <Link to={`/dashboard/view-contact/${item.conId}`} type="button" className="btn btn-outline-info btn-lg"><i className="fa-solid fa-eye"></i></Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                        <ul className="pagination">
                            <li className={prevButtonDisabled ? 'disabled page-item' : 'page-item'} ><a onClick={handlePrevClick} className="page-link">
                                <span aria-hidden="true">&laquo;</span></a></li>
                            {pageNumbers.map(number => (
                                <li className="page-item" key={number}>
                                    <a className="page-link" href="#" onClick={e => handleClick(e, number)}>
                                        {number}
                                    </a>
                                </li>
                            ))}
                            <li className={nextButtonDisabled ? 'disabled page-item' : 'page-item'}><a onClick={handleNextClick} className="page-link">
                                <span aria-hidden="true">&raquo;</span></a></li>
                        </ul>
                    </nav>

                </div>
            </div>
        </>
    )
}
export { ContactDashboard }