import React, { useState, useEffect } from "react";
import {
  Link,
  NavLink,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

//user service
import AuthService from "../../../services/auth.service";

//auth
import { useAuth, AuthRoute } from "../../../context/AuthProvider.js";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//bootstrap
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//validation form
const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

function RecoveryPassword() {
  const logo = require("../../../assets/img/logo.png");

  //naviagte
  const navigate = useNavigate();
  const location = useLocation();

  //validation auth
  const auth = useAuth();

  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  const onRecovery = async (values) => {
    try {
      const recoveryPassword = await AuthService.recoveryPassword(values.email);
      console.log(recoveryPassword);
      if (
        recoveryPassword.status === 200 &&
        recoveryPassword.data.statusCode === 200
      ) {
        setShowAlert(true);
        setMessage({ error: false, msg: recoveryPassword.data.message });
      } else if (
        recoveryPassword.status === 200 &&
        recoveryPassword.data.statusCode === 404
      ) {
        setShowAlert(true);
        setMessage({
          error: true,
          msg: "Check your mail, there is something wrong with it",
        });
      }
    } catch (error) {
      setShowAlert(true);
      setMessage({ error: true, msg: "An error has occurred!" });
    }
  };

  //formik
  const formik = useFormik({
    initialValues: { email: "" },
    validateOnBlur: true,
    onSubmit: (values) => {
      onRecovery(values);
    },
    validationSchema: validationSchema,
  });

  return (
    <>
      <div className="container top-header">
        <div className="row">
          <main className="form-signin w-100 m-auto text-center">
            <form onSubmit={formik.handleSubmit}>
              <img
                loading="lazy"
                className="mb-4 img-fluid mx-auto d-block"
                src={logo}
              />
              <h1 className="h3 mb-3 mt-3 fw-normal">
                {" "}
                Recovery your password
              </h1>
              {message ? (
                <Alert
                  show={showAlert}
                  variant={`${message.error ? "danger" : "success"}`}
                >
                  <p className="alert-message">{message.msg}</p>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => setShowAlert(false)}
                      size="sm"
                      variant={`outline-${
                        message.error ? "danger" : "success"
                      }`}
                    >
                      Close
                    </Button>
                  </div>
                </Alert>
              ) : null}
              <div className="form-floating">
                <input
                  name="email"
                  type="text"
                  placeholder="Enter your email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.email && formik.touched.email ? (
                  <label className="text-danger">
                    Email: {formik.errors.email}
                  </label>
                ) : (
                  <label>Email</label>
                )}
              </div>
              <button
                disabled={!formik.isValid}
                className="w-100 btn btn-lg  primary-color-bg mt-3"
                type="submit"
              >
                Recovery
              </button>
            </form>
            <div to={"/login"} className="mt-3 mb-3">
              <Link to={"/login"} className="no-underline primary-color">
                Sig In
              </Link>
              <hr />
              <Link to={"/new-user"} className="no-underline primary-color">
                Register
              </Link>
            </div>
            <p className="mt-3 mb-3 text-body-secondary">&copy; 2023</p>
          </main>
          <div></div>
        </div>
      </div>
    </>
  );
}
export { RecoveryPassword };
