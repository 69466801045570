import React from "react";
import { Modal } from "react-bootstrap";

const CustomModal = ({
  size,
  show,
  onHide,
  modal_content,
  modal_title,
  closeButtonDisabled,
}) => {
  return (
    <Modal
      size={size}
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      className="modal-custom"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="title">
        {/*   <Modal.Title>{modal_title}</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>{modal_content}</Modal.Body>
    </Modal>
  );
};

export default CustomModal;
