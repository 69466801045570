import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function Branding() {
  //traslate
  const { t, i18n } = useTranslation("global");
  const shape = require("../../../assets/img/shape/3.webp");
  const BSE = require("../../../assets/img/branding/ElementoNew.png");
  const BSIC1 = require("../../../assets/img/Branding Services/Ico-1.png");
  const Identity = require("../../../assets/img/Branding Services/Ico-2.png");
  const Recognition = require("../../../assets/img/Branding Services/Ico-3.png");
  const simbol = require("../../..//assets/img/Branding Services/Simbolo.png");
  const slide1 = require("../../../assets/img/Branding Services/Img-slider-1.png");
  const slide2 = require("../../../assets/img/Branding Services/Img-slider-2.png");
  const slide3 = require("../../../assets/img/Branding Services/Img-slider-3.png");
  const gift1 = require("../../../assets/img/Branding Services/After-Before-Gif.gif");
  // const  = require('../../../');
  useEffect(() => {
    document.title = "Branding";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="brand-top-container" className="container-fluid">
        <div id="brand-main-container" className="col-xs-12 text-center mb-70">
          <div id="brand-shape" className="shape">
            <img
              loading="lazy"
              src={shape}
              alt="services charlieapps charlie branding"
            />
          </div>
          <h3 className="theme-color brand-sub-text">{t("branding.title")}</h3>
          <h1 className="mb-50">{t("branding.subTitle")}</h1>
          <div id="brand-main-img" className="center">
            <img
              loading="lazy"
              className="img-fluid"
              src={BSE}
              alt="services charlieapps charlie branding"
            />
          </div>
        </div>

        <div className="brand-info-div row">
          <div className="brand-item col-xs-12 col-sm-12 col-md-12 col-lg-4 align-bottom">
            <div className="brand-item-image">
              <img
                loading="lazy"
                className="ux-item-logo"
                src={BSIC1}
                alt="services charlieapps charlie branding strategy"
              />
            </div>
            <div className="brand-item-text ">
              <h3 className="mb-30">{t("branding.Strategy")}</h3>
              <p>{t("branding.StrategyText")}</p>
              <hr />
            </div>
          </div>

          <div className="brand-item col-xs-12 col-sm-12 col-md-12 col-lg-4 align-bottom">
            <div className="brand-item-image">
              <img
                className="ux-item-logo"
                src={Identity}
                alt="services charlieapps charlie identity image"
              />
            </div>
            <div className="brand-item-text">
              <h3 className="mb-30">{t("branding.Identity")}</h3>
              <p className="weird-p-margin">{t("branding.IdentityText")}</p>
              <hr />
            </div>
          </div>

          <div className="brand-item col-xs-12 col-sm-12 col-md-12 col-lg-4 align-bottom">
            <div className="brand-item-image">
              <img
                className="ux-item-logo img-fluid"
                src={Recognition}
                alt="services charlieapps charlie brand recognition image"
              />
            </div>
            <div className="brand-item-text ">
              <h3 className="mb-30">{t("branding.BrandRecognition")}</h3>
              <p>{t("branding.BrandRecognitionText")}</p>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="RSbutton-div text-center mt-50 ui">
        <Link to={"/contact"} className="btn btn-primary RSBtn w-25">
          {" "}
          {t("branding.btnRequest")} <i className="fa fa-angle-right"></i>
        </Link>
      </div>
      <div className="brand-mid row">
        <div className="col-lg-5 brand-text-div pb-70">
          <div className="brand-mid-symbol percent-50">
            <img
              className="brand-symbol img-fluid"
              src={simbol}
              alt="services charlieapps charlie branding"
            />
          </div>
          <div className="brand-mid-text mt-auto">
            <p>Creative ideas make increased profits.</p>
            <h2
              style={{ color: "rgba(0, 0, 0, 0.75)", marginBottom: "0" }}
              dangerouslySetInnerHTML={{ __html: t("branding.brandinTitle") }}
            ></h2>
            <div className="RSbutton-div ">
              <button className="btn btn-primary RSBtn brand-text-button w-50">
                {" "}
                {t("branding.btnVisual")} <i className="fa fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-7 dark-gray-bg">
          <div id="carouselExample" className="carousel slide">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  loading="lazy"
                  src={slide1}
                  className="d-block w-100"
                  alt="services charlieapps charlie branding"
                />
              </div>
              <div className="carousel-item">
                <img
                  loading="lazy"
                  src={slide2}
                  className="d-block w-100"
                  alt="services charlieapps charlie branding"
                />
              </div>
              <div className="carousel-item">
                <img
                  loading="lazy"
                  src={slide3}
                  className="d-block w-100"
                  alt="services charlieapps charlie branding"
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>

      <div className="brand-bottom-div">
        <div className="container">
          <div className="col-xs-12 col-sm-11 col-md-10 column-center">
            <div className="about-text text-center">
              <h3 className="theme-color">{t("branding.ArtDirection")}</h3>
              <h2
                style={{ color: "rgba(0, 0, 0, 0.75)", marginbottom: " 3vh" }}
              >
                {t("branding.WeDeliverFrom")}
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: t("branding.creatingBrands"),
                }}
              ></p>
            </div>
            <div className="brand-bottom-image d-flex justify-content-center">
              <img
                loading="lazy"
                className="img-fluid"
                src={gift1}
                alt="services charlieapps charlie branding"
              />
            </div>
            <div className="about-text text-center bottom-div-bottom-text mt-100">
              <h2
                className="brand-bottom-title"
                style={{ color: "rgba(0, 0, 0, 0.466)" }}
              >
                {t("branding.VisualIdentity")}
              </h2>
              <h2 style={{ color: "rgba(0, 0, 0, 0.75)" }}>
                {" "}
                {t("branding.DigitalTransformation")}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { Branding };
