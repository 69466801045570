import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

//contact
import { FormContact } from "../form-contact";

function UiDesing() {
  //traslate
  const { t, i18n } = useTranslation("global");
  const shape = require("../../../assets/img/shape/3.webp");
  const element = require("../../../assets/img/User-Interface/Elemento.png");
  const UIIcon1 = require("../../../assets/img/User-Interface/Ico-1.png");
  const UIIcon2 = require("../../../assets/img/User-Interface/Ico-2.png");
  const UIIcon3 = require("../../../assets/img/User-Experience/Ico-Tailored Experience.png");
  const img1 = require("../../../assets/img/User-Interface/Img-Com-5.png");
  const img2 = require("../../../assets/img/User-Interface/Img-Com-1.png");
  const img3 = require("../../../assets/img/User-Interface/Img-Com-6.png");
  const img4 = require("../../../assets/img/User-Interface/Ico-Com.png");
  const img5 = require("../../../assets/img/User-Interface/Img-Com-2.png");
  const img6 = require("../../../assets/img/User-Interface/Img-Com-3.png");
  const img7 = require("../../../assets/img/User-Interface/Img-Com-4.png");
  // const img = require('../../../');
  useEffect(() => {
    document.title = "User Interface";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="ui-top-container" className="container-fluid">
        <div id="ui-info-container" className="col-xs-12 text-center mb-70">
          <div id="ui-shape" className="shape">
            <img
              loading="lazy"
              src={shape}
              alt="charlieapps charlie apps user interface"
            />
          </div>
          <h3 className="theme-color">{t("ui.title")}</h3>
          <h1 className="mb-50">{t("ui.subTitle")}</h1>
          <p id="ui-main-description">{t("ui.titleText")}</p>
        </div>
        <div id="ui-main-img" className="center">
          <img
            loading="lazy"
            className="img-fluid"
            src={element}
            alt="charlieapps charlie apps user interface"
          />
        </div>

        <div className="ux-info-div row">
          <div className="ux-item col-12 col-sm-12 col-md-12 col-lg-4">
            <div className="ux-item-image"></div>
            <div className="ux-item-text web-item-text">
              <hr />
              <img
                className="ux-item-logo img-fluid"
                src={UIIcon1}
                alt="charlieapps charlie apps user interface minimalistic components"
              />
              <h3 className="mb-30">{t("ui.MinimalisticComponents")}</h3>
              <p className="text-justify">
                {t("ui.MinimalisticComponentsText")}
              </p>
            </div>
          </div>

          <div className="ux-item col-12 col-sm-12 col-md-12 col-lg-4">
            <div className="ux-item-image"></div>
            <div className="ux-item-text web-item-text">
              <hr />
              <img
                className="ux-item-logo img-fluid"
                src={UIIcon2}
                alt="charlieapps charlie apps user interface invisible pathways"
              />
              <h3 className="mb-30">{t("ui.InvisiblePathways")}</h3>
              <p>{t("ui.InvisiblePathwaysText")}</p>
            </div>
          </div>

          <div className="ux-item col--12 col-sm-12 col-md-12 col-lg-4">
            <div className="ux-item-image"></div>
            <div className="ux-item-text web-item-text">
              <hr />
              <img
                className="ux-item-logo img-fliud"
                src={UIIcon3}
                alt="charlieapps charlie apps user interface intuitive design"
              />
              <h3 className="mb-30">{t("ui.IntuitiveDesign")}</h3>
              <p>{t("ui.IntuitiveDesignText")}</p>
            </div>
          </div>
        </div>
        <div className="RSbutton-div ui text-center mt-50">
          <Link to={"/contact"} className="btn btn-primary RSBtn w-25">
            {t("ui.btnRequest")} <i className="fa fa-angle-right"></i>
          </Link>
        </div>
      </div>

      <div
        id="ui-bot-section"
        className="container-fluid mt-100 gray-bg pb-80 pt-80"
      >
        <div id="mobi-ui-hidden" className="col-xs-12 text-center mb-50">
          <h1 className="mb-50">{t("ui.DigitalTransformation")}</h1>
          <p id="ui-main-description">{t("ui.DigitalTransformationText")}</p>
        </div>
        <div id="mobi-ui-hidden" className="ui-bot-center mt-50">
          <div className="ui-bot-links">
            <button>{t("ui.btn1")}</button>
            <button>{t("ui.btn2")}</button>
            <button>{t("ui.btn3")}</button>
            <button>{t("ui.btn4")}</button>
          </div>
        </div>

        <div className="ui-grid-image-wrapper">
          <div id="ui-item1">
            <img
              loading="lazy"
              className="img-fluid"
              src={img1}
              alt="charlieapps charlie apps user interface"
            />
          </div>
          <div id="ui-item2">
            <img
              loading="lazy"
              className="img-fluid"
              src={img2}
              alt="charlieapps charlie apps user interface"
            />
          </div>
          <div id="ui-item3">
            <img
              loading="lazy"
              className="img-fluid"
              src={img3}
              alt="charlieapps charlie apps user interface"
            />
          </div>
          <div id="ui-item4">
            <img
              loading="lazy"
              src={img4}
              alt="charlieapps charlie apps user interface"
            />
            <h3>{t("ui.DesignThinking")}</h3>
            <p>{t("ui.DesignThinkingText")}</p>
          </div>
          <div id="ui-item5">
            <img
              loading="lazy"
              className="img-fluid"
              src={img5}
              alt="charlieapps charlie apps user interface"
            />
          </div>
          <div id="ui-item6">
            <img
              loading="lazy"
              className="img-fluid"
              src={img6}
              alt="charlieapps charlie apps user interface"
            />
          </div>
          <div id="ui-item7">
            <img
              loading="lazy"
              className="img-fluid"
              src={img7}
              alt="charlieapps charlie apps user interface"
            />
          </div>
        </div>
      </div>

      <div className="contact-form-area section-pt pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <div className="ui-heading">
                <h1 className="theme-color">{t("ui.GetTouch")}</h1>
                <p>{t("ui.GetTouchText")}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="contact-form text-center">
                <div id="contact-response" className="mb-15 alert"></div>
                <div className="loader">
                  <i className="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <FormContact />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { UiDesing };
