import React, { useEffect } from 'react';
import $ from 'jquery';

function App() {
  useEffect(() => {
    /*
      MOBILE MENU
      ================================== */
    //  Main menu
    const dropdownMenu = $('.mainmenu li.dropdown, .mainmenu li.sub-dropdown');
    const dropBtnAppend = $('<div class="dropdown-btn"></div>');
    dropdownMenu.append(dropBtnAppend);
    // Dropdown Button
    const dropdownBtn = $('.mainmenu li.dropdown .dropdown-btn');
    if (dropdownBtn.length) {
      dropdownBtn.on('click', function () {
        $(this).toggleClass('submenu-icon');
        $(this).prev('ul').slideToggle(400);
        return false;
      });
    }

    /*
      MENU A ACTIVE JQUERY
      ================================== */
    const pgurl = window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
    const aActive = $('nav ul li a');
    if (aActive.length) {
      aActive.each(function () {
        if ($(this).attr('href') === pgurl || $(this).attr('href') === '') $(this).addClass('active');
      });
    }

    /*
      Searching & Expand Menu Popup
      ================================== */
    const sToggle = $('.scale-toggle');
    const closeA = $('.close-scale');
    const closeB = $('.searching button');
    const cBody = $('body');
    const closeScale = closeA.add(closeB);
    if (sToggle.length > 0) {
      sToggle.on('click', function () {
        cBody.toggleClass('open');
        return false;
      });
    }
    if (closeScale.length > 0) {
      closeScale.on('click', function () {
        cBody.removeClass('open');
        return false;
      });
    }

    /*
      STICKY
      ================================== */
    const AcSticky = $('#active-sticky');
    const WinD = $(window);
    WinD.on('scroll', getStickyMenu);
    $(document).on('ready', getStickyMenu);

    function getStickyMenu() {
      const scroll = $(window).scrollTop();
      const AESticky = AcSticky;
      if (scroll < 80) {
        AESticky.removeClass('is-sticky');
      } else {
        AESticky.addClass('is-sticky');
      }
    }

    /*
      ZEPTO PARALLAX
      ================================== */
    const $layerClass = $('.layer-parent div');
    const $wrapperID = $('.wrapper');
    if ($layerClass.hasClass('layer') === true) {
      $wrapperID.attr('id', 'scene');
    }
    const $scene = $('#scene');
    $scene.parallax();

    /*
      CALL GRIDDER
      ================================= */
    const $gridderID = $('#gridder');
    const $carouselActive = $('.carousel');
    $gridderID.gridderExpander({
      scroll: true,
      scrollOffset: 80,
      scrollTo: 'panel', // panel or listitem
      animationSpeed: 400,
      animationEasing: 'easeInOutExpo',
      showNav: true, // Show Navigation
      nextText: '<i class="fa fa-angle-right"></i>', // Next button text
      prevText: '<i class="fa fa-angle-left"></i>', // Previous button text
      closeText: '', // Close button text
      onStart: function () {
        // Gridder Inititialized
        console.log('On Gridder Initialized...');
      },
      onContent: function () {
        // Gridder Content Loaded
        console.log('On Gridder Expand...');
        $carouselActive.carousel();
      },
      onClosed: function () {
        // Gridder Closed
        console.log('On Gridder Closed...');
      },
    });

    /*
      ACCORDION ACTIVE CLASS
      ================================== */
    const cActive = $('.panel-heading a');
    cActive.on('click', function () {
      cActive.removeClass('active');
      $(this).addClass('active');
      return false;
    });

    /*
      PORTFOLIO FILTER
      ================================== */
    const gridContainer = $('.grid-container');
    const filterLi = $('.filter li');
    const gItem = $('.grid-item');
    gridContainer.imagesLoaded(function () {
      gridContainer.isotope({
        itemSelector: '.grid-item',
        layoutMode: 'masonry',
      });
    });
    filterLi.on('click', function () {
      filterLi.removeClass('active');
      $(this).addClass('active');
      const selector = $(this).attr('data-filter');
      gridContainer.isotope({
        filter: selector,
      });
      return false;
    });
    $(window).resize(function () {
      setTimeout(function () {
        gridContainer.isotope();
      }, 1000);
    });

    /*
      COUNTDOWN
      ================================== */
    const countdownTimer = $('.countdown-timer');
    countdownTimer.countdown('2025/01/01', function (event) {
      $(this).html(
        event.strftime(
          '<div class="cdt-box"> <span class="cdt-num">%-D</span> <span class="cdt-txt">days</span> </div> <div class="cdt-box"> <span class="cdt-num">%H</span> <span class="cdt-txt">hours</span> </div> <div class="cdt-box"> <span class="cdt-num">%M</span> <span class="cdt-txt">minutes</span> </div> <div class="cdt-box"> <span class="cdt-num">%S</span> <span class="cdt-txt">seconds</span> </div>'
        )
      );
    });

    /*
      OWL CAROUSEL
      ================================== */
    const testiSlide = $('.testimonials-slide');
    if (testiSlide.length > 0) {
      testiSlide.owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 8000,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      });
    }

    const clientSlide = $('.client-carousel');
    if (clientSlide.length > 0) {
      clientSlide.owlCarousel({
        loop: true,
        margin: 30,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 4,
          },
        },
      });
    }

    const portfolioSlide = $('.portfolio-carousel');
    if (portfolioSlide.length > 0) {
      portfolioSlide.owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      });
    }

    const singleSlide = $('.single-carousel');
    if (singleSlide.length > 0) {
      singleSlide.owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        autoplay: true,
        autoplayTimeout: 5000,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      });
    }

    /*
      TOOLTIP
      ================================== */
    const tooltipActive = $('[data-toggle="tooltip"]');
    tooltipActive.tooltip();
  }, []);

  return <div className="App">Content here</div>;
}

export default App;
