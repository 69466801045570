import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';

//traslate
import { useTranslation, Trans } from 'react-i18next';

//recapchat
import ReCAPTCHA from "react-google-recaptcha";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//user service
import contactService from '../../services/contact.service'

//bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

//validation form
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email()
        .required("Required"),
    phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid'),
    subject: Yup.string()
        .required("Required"),
    message: Yup.string()
        .required("Required"),

});

function FormContact() {
    //traslate
    const { t, i18n } = useTranslation('global');
    //message error
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);

    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    //recapchat
    const capchat = useRef(null);
    const [recapchatView, setRecapchatView] = useState(false);


    //formik
    const formik = useFormik({
        initialValues: { name: "", email: "", phone: "", subject: "", message: "" },
        validateOnBlur: true,
        onSubmit: (values) => {
            if (recapchatView === true) {
                onContact(values);
            } else {
                setShowAlert(true);
                setMessage({ error: true, msg: "please mark the recaptcha" });
            }
        },
        validationSchema: validationSchema,
    });

    const onContact = async (values) => {
        const formattedMessage = values.message.replace(/\n/g, '<br/>');
        let data = {
            name: values.name,
            email: values.email,
            phone: values.phone,
            subject: values.subject,
            message: formattedMessage,
            key: process.env.REACT_APP_URL_SECRET_KEY_FORM
        };
        try {
            const createContact = await contactService.createContactForm(data);
            if (createContact.status === 200 && createContact.data.statusCode === 201) {
                formik.resetForm();
                setShow(true);
            } else if (createContact.status === 200 && createContact.data.statusCode === 404) {
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            }

        } catch (error) {
            setShowAlert(true);
            setMessage({ error: true, msg: "An error has occurred! try again" });
        }
    }

    function onCapchat() {
        if (capchat.current.getValue()) {
            setRecapchatView(true);
        } else {
            setRecapchatView(false);
        }
    }
    return (
        <>

            {message ?
                <Alert show={showAlert} variant={`${message.error ? 'danger' : 'success'}`}>
                    <p className='alert-message'>{message.msg}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => setShowAlert(false)} size="sm" variant={`outline-${message.error ? 'danger' : 'success'}`}>
                            Close
                        </Button>
                    </div>
                </Alert>
                : null
            }
            <form onSubmit={formik.handleSubmit} className="custom-input" id="contact_form" method="post" encType="multipart/form-data" noValidate="noValidate">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <input type="text"
                            className={`requiredField  ${formik.errors.name && formik.touched.name ? 'text-danger' : ''}`}
                            placeholder={formik.errors.name && formik.touched.name ? '*' + t('contact.name') + ' ' + formik.errors.name : t('contact.name')}
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <input type="email"
                            className={`requiredField  ${formik.errors.email && formik.touched.email ? 'text-danger' : ''}`}
                            placeholder={formik.errors.name && formik.touched.name ? '*' + t('contact.email') + ' ' + formik.errors.name : t('contact.email')}
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <input type="text"
                            className={`requiredField  ${formik.errors.phone && formik.touched.phone ? 'text-danger' : ''}`}
                            placeholder={formik.errors.name && formik.touched.name ? '*' + t('contact.phone') + ' ' + formik.errors.name : t('contact.phone')}
                            id="phone"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <input type="text"
                            className={`requiredField  ${formik.errors.subject && formik.touched.subject ? 'text-danger' : ''}`}
                            placeholder={formik.errors.subject && formik.touched.subject ? '*' + t('contact.subject') + ' ' + formik.errors.name : t('contact.subject')}
                            id="subject"
                            name="subject"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </div>
                </div>
                <textarea
                    className={`requiredField  ${formik.errors.message && formik.touched.message ? 'text-danger' : ''}`}
                    placeholder={formik.errors.subject && formik.touched.message ? '*' + t('contact.message') + ' ' + formik.errors.message : t('contact.message')}
                    id="message"
                    name="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}></textarea>
                <ReCAPTCHA
                    ref={capchat}
                    sitekey={process.env.REACT_APP_URL_RECAPCHAT_KEY}
                    onChange={onCapchat}
                />

                <button disabled={!formik.isValid} className="btn rt-btn2 mt-20" type="submit" name="submit" id="contact_submit" data-complete-text="Well done!">
                    {t('contact.btnFormService')}
                </button>
            </form>
            <Modal show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered className=' modal-login'>
                <div className="modal-content content">
                    <div className="modal-body">
                        <p className='text-center'> The form has been sent, we will contact you soon!.</p>
                    </div>
                    <div className="modal-footer footer-login-modal  d-md-block  text-center">
                        <NavLink to={'/'}><button type="button" className="btn btn-sm btn-send ">Close</button></NavLink>
                    </div>
                </div>
            </Modal>
        </>
    )
}
export { FormContact }