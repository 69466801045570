import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function WebDevelopment() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const shape = require("../../../assets/img/shape/3.webp");
  const laptop = require("../../../assets/img/WebServices/laptop.png");

  // const  = require('../../../');
  const html = require("../../../assets/img/WebServices/html.png");
  const unity = require("../../../assets/img/WebServices/Unity.png");
  const wordpress = require("../../../assets/img/WebServices/wordpress.png");
  const RWA = require("../../../assets/img/WebServices/Responsive-Web-Aplications.png");
  const AAS = require("../../../assets/img/WebServices/Analytics-and-Security.png");
  const RPI = require("../../../assets/img/WebServices/3rd-Party-Integrations.png");

  const RWAImage = require("../../../assets/img/WebServices/Responsive-Web-Aplications.jpg");
  const AASImage = require("../../../assets/img/WebServices/Analytics-and-Security.jpg");
  const RPIImage = require("../../../assets/img/WebServices/3rd-Party-Integrations.jpg");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = "Web Development";
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);
  return (
    <>
      <div
        id="web-slider"
        className="slider-area height-100 bg-img-22 overlay dark-4 fix"
      >
        <div className="d-table-changes">
          <div className="dt-cell">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <div className="slide-caption style4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid web-dev-container">
        <div className="web-img-container">
          <img
            loading="lazy"
            src={laptop}
            alt="charlieapps charlie apps web development"
            className="web-laptop"
          />
        </div>
        <div className="web-title">
          <div className="shape">
            <img
              loading="lazy"
              src={shape}
              alt="charlieapps charlie apps web development"
            />
          </div>
          <h3 className="theme-color">{t("webDevelopment.Services")}</h3>
          <h1>{t("webDevelopment.title")}</h1>
          <p>{t("webDevelopment.subTitle")}</p>
          <hr />
        </div>
        <div className="web-icon-container">
          <img
            loading="lazy"
            src={html}
            alt="charlieapps charlie apps web development HTML Logo"
          />
          <img
            loading="lazy"
            src={unity}
            alt="charlieapps charlie apps web development Unity Logo"
          />
          <img
            loading="lazy"
            src={wordpress}
            alt="charlieapps charlie apps web development Wordpress Logo"
          />
        </div>

        <div className="web-info-div row">
          <div className="web-item col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <div className="web-item-text">
              <img
                className="web-item-logo img-fluid"
                src={RWA}
                alt="charlieapps charlie apps web developmen Responsive Web App Image"
              />
              <h3>{t("webDevelopment.ResponsiveWebApplications")}</h3>
              <p>{t("webDevelopment.ResponsiveWebApplicationsText")}</p>
              <hr />
            </div>
          </div>

          <div className="web-item col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <div className="web-item-text">
              <img
                className="web-item-logo img-fluid"
                src={AAS}
                alt="charlieapps charlie apps web development analytics security"
              />
              <h3 className="mb-30">
                {t("webDevelopment.AnalyticsSecurity")}y
              </h3>
              <p>{t("webDevelopment.AnalyticsSecurityText")}</p>
              <hr />
            </div>
          </div>

          <div className="web-item col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <div className="web-item-text">
              <img
                className="web-item-logo img-fluid"
                src={RPI}
                alt="charlieapps charlie apps web development 3rd party integrations"
              />
              <h3 className="mb-30">{t("webDevelopment.PartyIntegrations")}</h3>
              <p>{t("webDevelopment.PartyIntegrationsText")}</p>
              <hr />
            </div>
          </div>
        </div>
        <div className="RSbutton-div ui text-center web-rsbtn-div">
          <button className="btn btn-primary RSBtn web-rsbtn w-25">
            {t("webDevelopment.RequestQuoteBTN")}{" "}
            <i className="fa fa-angle-right"></i>
          </button>
        </div>

        <div id="awd-sp" className="white-bg section-padding">
          <div className="">
            <div id="no-pad" className="flex-hybrid row">
              <div
                id="no-pad"
                className="col-xs-12 col-sm-6 col-md-6 p-0"
                style={{ order: screenWidth < 900 ? "2" : "1" }}
              >
                <div className="featured-areaawd">
                  <div id="text-relativebox" className="text relative">
                    <div className="shape-awd">
                      <img
                        loading="lazy"
                        src={RWA}
                        alt="charlieapps charlie apps web development Responsive Web Applications"
                      />
                    </div>
                    <h4>{t("webDevelopment.ResponsiveWebApplications2")}</h4>
                    <hr />
                    <p>{t("webDevelopment.ResponsiveWebApplicationsText2")}</p>
                  </div>
                </div>
              </div>
              <div
                id="no-pad"
                className="col-xs-12 col-sm-6 col-md-6 p-0"
                style={{ order: "1" }}
              >
                <div className="image relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={RWAImage}
                    alt="charlieapps charlie apps web development Responsive Web Applications"
                  />
                </div>
              </div>
            </div>
            <div id="no-pad" className="flex-hybrid row">
              <div
                id="no-pad"
                className="col-xs-12 col-sm-6 col-md-6 p-0"
                style={{ order: "1" }}
              >
                <div className="image relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={AASImage}
                    alt="charlieapps charlie apps web development Analytics And Security"
                  />
                </div>
              </div>
              <div
                id="no-pad"
                className="col-xs-12 col-sm-6 col-md-6 p-0"
                style={{ order: "2" }}
              >
                <div className="featured-areaawd">
                  <div id="text-relativebox" className="text relative">
                    <div className="shape-awd">
                      <img
                        loading="lazy"
                        src={AAS}
                        alt="charlieapps charlie apps web development Analytics And Security"
                      />
                    </div>
                    <h4>{t("webDevelopment.AnalyticsSecurity2")}</h4>
                    <hr />
                    <p>{t("webDevelopment.AnalyticsSecurityText2")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div id="no-pad" className="flex-hybrid row">
              <div
                id="no-pad"
                className="col-xs-12 col-sm-6 col-md-6 p-0"
                style={{ order: screenWidth < 900 ? "2" : "1" }}
              >
                <div className="featured-areaawd">
                  <div id="text-relativebox" className="text relative">
                    <div className="shape-awd">
                      <img
                        loading="lazy"
                        src={RPI}
                        alt="charlieapps charlie apps web development 3rd Party Integrations"
                      />
                    </div>
                    <h4>{t("webDevelopment.PartyIntegration2")}</h4>
                    <hr />
                    <p>{t("webDevelopment.PartyIntegrationText2")}</p>
                  </div>
                </div>
              </div>
              <div
                id="no-pad"
                className="col-xs-12 col-sm-6 col-md-6 p-0"
                style={{ order: "1" }}
              >
                <div className="image relative">
                  <img
                    loading="lazy"
                    className="img-fluid"
                    src={RPIImage}
                    alt="charlieapps charlie apps web development 3rd Party Integrations"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="about-areapad" className="about-area style2 mb-150">
          <div className="container">
            <div className="col-xs-12 col-sm-11 col-md-10 column-center">
              <div className="about-text text-center">
                <h1 className="theme-color">
                  {t("webDevelopment.titileFooter")}
                </h1>
                <h1 className="sub-text">
                  {t("webDevelopment.SubTitileFooter")}
                </h1>
                <p>{t("webDevelopment.titileFooterText")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { WebDevelopment };
