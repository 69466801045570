import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function Enteripse() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const shape = require("../../../assets/img/service/new/shape.png");
  const enteripeImage = require("../../../assets/img/service/new/enteripese.jpg");
  const enteripeIcon = require("../../../assets/img/service/new/enteripse_icon.png");
  const topCharlie = require("../../../assets/img/service/new/top_charlie.png");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = "Enterprise Solutions";

    window.scrollTo(0, 0);
  }, [screenWidth]);
  return (
    <>
      <div className="services-essb pt-essb">
        <div className="row enteripse">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 img order-2 order-sm-2 order-md-1 order-lg-1">
            <img
              loading="lazy"
              className="top "
              src={topCharlie}
              alt="charlieapps charlie apps enterprise solutions"
            />
            <img
              loading="lazy"
              className="img-bg"
              src={enteripeImage}
              alt="charlieapps charlie apps enterprise solutions"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 header order-1 order-sm-1 order-md-2 order-lg-2">
            <div className="shape">
              <img
                loading="lazy"
                src={shape}
                alt="charlieapps charlie apps enterprise solutions"
              />
            </div>
            <div className="title">
              <img
                loading="lazy"
                src={enteripeIcon}
                alt="charlieapps charlie apps enterprise solutions"
              />
              <h6>{t("Enterprise.title")}</h6>
              <h4
                dangerouslySetInnerHTML={{ __html: t("Enterprise.subTitle") }}
              ></h4>
              <p>{t("Enterprise.descriptionTitle")}</p>
              <p>{t("Enterprise.descriptionTitleTwo")}</p>
              <div className="btn-essb">
                <NavLink to={"/contact"}>{t("Enterprise.serviceBtn")}</NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="step-container">
            <div className="circle-container">
              <div className="circle">1</div>
              <div className="step-text">
                <h5>{t("Enterprise.serviceOneTitle")}</h5>
                <p>{t("Enterprise.serviceOne")}</p>
              </div>
            </div>
            <div className="dotted-line"></div>
            <div className="circle-container">
              <div className="circle">2</div>
              <div className="step-text">
                <h5>{t("Enterprise.serviceTwoTitle")}</h5>
                <p>{t("Enterprise.serviceTwo")}</p>
              </div>
            </div>
            <div className="dotted-line"></div>
            <div className="circle-container">
              <div className="circle">3</div>
              <div className="step-text">
                <h5>{t("Enterprise.serviceThreeTitle")}</h5>
                <p>{t("Enterprise.serviceThree")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { Enteripse };
