import React, { useState, Component } from "react";
import Carousel from "react-spring-3d-carousel";
import { v4 as uuidv4 } from "uuid"; // Importar v4 de uuid
import { config } from "react-spring";
import cel1 from "../../../assets/img/portfolio/cel-1.webp";
import cel2 from "../../../assets/img/portfolio/cel-2.webp";
import cel3 from "../../../assets/img/portfolio/cel-3.webp";
import cel4 from "../../../assets/img/portfolio/cel-4.webp";
import cel5 from "../../../assets/img/portfolio/cel-5.webp";

const Example = () => {
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: false,
    config: config.gentle,
  });

  const onChangeInput = (e) => {
    setState({
      ...state,
      [e.target.name]: parseInt(e.target.value, 10) || 0,
    });
  };

  let slides = [
    {
      key: uuidv4(),
      content: (
        <img
          src={cel1}
          alt="charlieapps charlie apps portfolio"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          src={cel2}
          alt="charlieapps charlie apps portfolio"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          src={cel3}
          alt="charlieapps charlie apps portfolio"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          src={cel4}
          alt="charlieapps charlie apps portfolio"
          style={{ cursor: "pointer" }}
        />
      ),
    },
    {
      key: uuidv4(),
      content: (
        <img
          src={cel5}
          alt="charlieapps charlie apps portfolio"
          style={{ cursor: "pointer" }}
        />
      ),
    },
  ].map((slide, index) => {
    return {
      ...slide,
      onClick: () => setState({ ...state, goToSlide: index }),
    };
  });
  const handleTouchStart = (evt) => {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  };

  let xDown = null;
  let yDown = null;
  const getTouches = (evt) => {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  };

  const handleTouchMove = (evt) => {
    if (!xDown || !yDown) {
      return;
    }

    let xUp = evt.touches[0].clientX;
    let yUp = evt.touches[0].clientY;

    let xDiff = xDown - xUp;
    let yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        setState({ goToSlide: state.goToSlide + 1 });
      } else {
        /* right swipe */
        setState({ goToSlide: state.goToSlide - 1 });
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    xDown = null;
    yDown = null;
  };

  return (
    <div
      className="carousel-header "
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <Carousel
        slides={slides}
        goToSlide={state.goToSlide}
        offsetRadius={state.offsetRadius}
        showNavigation={state.showNavigation}
        animationConfig={state.config}
      />
    </div>
  );
};

export default Example;
