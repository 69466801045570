import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CustomModal from "./modalCustom";

const SliderPortfolio = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  /* images */
  const sl_a = isMobile
    ? require("../../../assets/img/portfolio/portfolio/sl-a-movil.webp")
    : require("../../../assets/img/portfolio/portfolio/sl-a.webp");
  const sl_b = isMobile
    ? require("../../../assets/img/portfolio/portfolio/sl-b-movil.webp")
    : require("../../../assets/img/portfolio/portfolio/sl-b.webp");
  const sl_c = isMobile
    ? require("../../../assets/img/portfolio/portfolio/sl-c-movil.webp")
    : require("../../../assets/img/portfolio/portfolio/sl-c.webp");
  const sl_d = isMobile
    ? require("../../../assets/img/portfolio/portfolio/sl-d-movil.webp")
    : require("../../../assets/img/portfolio/portfolio/sl-d.webp");
  const sl_e = isMobile
    ? require("../../../assets/img/portfolio/portfolio/sl-e-movil.webp")
    : require("../../../assets/img/portfolio/portfolio/sl-e.webp");
  const sl_f = isMobile
    ? require("../../../assets/img/portfolio/portfolio/sl-f-movil.webp")
    : require("../../../assets/img/portfolio/portfolio/sl-f.webp");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
  }, [isMobile]);

  const slidePortfolio = () => {
    const settings = {
      dots: true,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 10000,
    };

    return (
      <div className="slider-portfolio">
        <CustomModal
          size={sizeModal}
          show={showModal}
          onHide={handleClose}
          modal_title={modalTitle}
          modal_content={modalContent}
        />

        <Slider {...settings} className="">
          <div className="box">
            <div className="title">
              <img src={sl_a} alt="charlieapps CORPORATE BRAND IDENTITY" />
            </div>
          </div>
          <div className="box">
            <div className="title">
              <img src={sl_b} alt="charlieapps CORPORATE BRAND IDENTITY" />
            </div>
          </div>
          <div className="box">
            <div className="title">
              <img src={sl_c} alt="charlieapps CORPORATE BRAND IDENTITY" />
            </div>
          </div>
          <div className="box">
            <div className="title">
              <img src={sl_d} alt="charlieapps CORPORATE BRAND IDENTITY" />
            </div>
          </div>
          <div className="box">
            <div className="title">
              <img src={sl_e} alt="charlieapps CORPORATE BRAND IDENTITY" />
            </div>
          </div>
          <div className="box">
            <div className="title">
              <img src={sl_f} alt="charlieapps CORPORATE BRAND IDENTITY" />
            </div>
          </div>
        </Slider>
      </div>
    );
  };

  /* modal */
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [sizeModal, setSizeModal] = useState("");

  //module title
  const [titleModule, setTitleModule] = useState("");
  const [keyModule, setKeyModule] = useState("");
  const [itemData, setItemData] = useState([]);

  //modal content
  const modalTitle = titleModule;
  const modalContent = (
    <>
      {(() => {
        switch (keyModule) {
          case "reel":
            return <></>;
          default:
            return null;
        }
      })()}
    </>
  );
  return <>{slidePortfolio()}</>;
};

export default SliderPortfolio;
