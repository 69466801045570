import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CustomModal from "./modalCustom";
import VideoReel from "./videoReel";

const SliderReels = () => {
  /* images social array */
  const reelA = require("../../../assets/img/portfolio/reel/reel-a.webp");
  const reelB = require("../../../assets/img/portfolio/reel/reel-b.webp");
  const reelC = require("../../../assets/img/portfolio/reel/reel-c.webp");
  const reelD = require("../../../assets/img/portfolio/reel/reel-d.webp");
  const reelE = require("../../../assets/img/portfolio/reel/reel-e.webp");

  //videos
  const reelVideoA =
    "https://charlieapps.b-cdn.net/cdn/videos/portfolio/reel-a.mp4";
  const reelVideoB =
    "https://charlieapps.b-cdn.net/cdn/videos/portfolio/reel-b.mp4";
  const reelVideoC =
    "https://charlieapps.b-cdn.net/cdn/videos/portfolio/reel-c.mp4";
  const reelVideoD =
    "https://charlieapps.b-cdn.net/cdn/videos/portfolio/reel-d.mp4";
  const reelVideoE =
    "https://charlieapps.b-cdn.net/cdn/videos/portfolio/reel-e.mp4";

  useEffect(() => {}, []);

  const slider = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],
    };

    return (
      <div className="slider-reels">
        <CustomModal
          size={sizeModal}
          show={showModal}
          onHide={handleClose}
          modal_title={modalTitle}
          modal_content={modalContent}
        />
        <div className="title">
          <h1>Reels and Stories</h1>
        </div>
        <Slider {...settings} className="">
          <div className="box" onClick={() => {}}>
            <a
              onClick={() => {
                setTitleModule(`reel`);
                setItemData(reelVideoA);
                setKeyModule("reel");
                setSizeModal("md");
                handleShow();
              }}
            >
              <img src={reelA} alt="" />
              <div className="play">
                <i className="fa-regular fa-circle-play"></i>
              </div>
            </a>
          </div>
          <div className="box" onClick={() => {}}>
            <a
              onClick={() => {
                setTitleModule(`reel`);
                setItemData(reelVideoB);
                setKeyModule("reel");
                setSizeModal("md");
                handleShow();
              }}
            >
              <img src={reelB} alt="" />
              <div className="play">
                <i className="fa-regular fa-circle-play"></i>
              </div>
            </a>
          </div>
          <div className="box" onClick={() => {}}>
            <a
              onClick={() => {
                setTitleModule(`reel`);
                setItemData(reelVideoC);
                setKeyModule("reel");
                setSizeModal("md");
                handleShow();
              }}
            >
              <img src={reelC} alt="" />
              <div className="play">
                <i className="fa-regular fa-circle-play"></i>
              </div>
            </a>
          </div>
          <div className="box" onClick={() => {}}>
            <a
              onClick={() => {
                setTitleModule(`reel`);
                setItemData(reelVideoD);
                setKeyModule("reel");
                setSizeModal("md");
                handleShow();
              }}
            >
              <img src={reelD} alt="" />
              <div className="play">
                <i className="fa-regular fa-circle-play"></i>
              </div>
            </a>
          </div>
          <div className="box" onClick={() => {}}>
            <a
              onClick={() => {
                setTitleModule(`reel`);
                setItemData(reelVideoE);
                setKeyModule("reel");
                setSizeModal("md");
                handleShow();
              }}
            >
              <img src={reelE} alt="" />
              <div className="play">
                <i className="fa-regular fa-circle-play"></i>
              </div>
            </a>
          </div>
        </Slider>
      </div>
    );
  };

  /* modal */
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [sizeModal, setSizeModal] = useState("");

  //module title
  const [titleModule, setTitleModule] = useState("");
  const [keyModule, setKeyModule] = useState("");
  const [itemData, setItemData] = useState([]);

  //modal content
  const modalTitle = titleModule;
  const modalContent = (
    <>
      {(() => {
        switch (keyModule) {
          case "reel":
            return <VideoReel data={itemData} />;
          default:
            return null;
        }
      })()}
    </>
  );
  return <>{slider()}</>;
};

export default SliderReels;
