import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class ContactService {

    //create form contact
    createContactForm(data) {
        return http.post(`/contact/new`, data);
    }
    //get all contact dashboard
    getAllContact(authorization) {
        return http.get(`/contact/get`, authorization);
    }

    getAllContactById(id, authorization) {
        return http.get(`/contact/get/detail/${id}`, authorization);
    }


}
export default new ContactService();