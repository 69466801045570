import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//user service
import userService from "../../../services/user.service";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//validation form
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/(?=.*[0-9])/, "Password must contain a number."),
});

function NewUser() {
  const logo = require("../../../assets/img/logo.png");
  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  //formik
  const formik = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      email: "",
      password: "",
      gender: "",
    },
    validateOnBlur: true,
    onSubmit: (values) => {
      onLogin(values);
    },
    validationSchema: validationSchema,
  });

  const onLogin = async (values) => {
    try {
      let data = {
        useName: values.name,
        useLastName: values.lastName,
        useEmail: values.email,
        usePassword: values.password,
        useGender: values.gender ? values.gender : "other",
      };
      const createUserBD = await userService.createUser(data);
      //console.log(createUserBD)
      if (createUserBD.status === 200 && createUserBD.data.statusCode === 201) {
        setShow(true);
      } else if (
        createUserBD.status === 200 &&
        createUserBD.data.output.statusCode === 403
      ) {
        setShowAlert(true);
        setMessage({ error: true, msg: "Account already exist! try again" });
      }
    } catch (error) {
      setShowAlert(true);
      setMessage({ error: true, msg: "An error has occurred!" });
    }
  };
  return (
    <>
      <div className="container top-header">
        <div className="row">
          <main className="form-signin w-100 m-auto text-center">
            <form onSubmit={formik.handleSubmit}>
              <img
                className="mb-4 img-fluid mx-auto d-block"
                src={logo}
                loading="lazy"
              />
              <h1 className="h3 mb-3 mt-3 fw-normal"> Register</h1>
              {message ? (
                <Alert
                  show={showAlert}
                  variant={`${message.error ? "danger" : "success"}`}
                >
                  <p className="alert-message">{message.msg}</p>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => setShowAlert(false)}
                      size="sm"
                      variant={`outline-${
                        message.error ? "danger" : "success"
                      }`}
                    >
                      Close
                    </Button>
                  </div>
                </Alert>
              ) : null}

              <div className="form-floating">
                <input
                  name="name"
                  type="text"
                  placeholder="Enter your email"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.name && formik.touched.name ? (
                  <label className="text-danger">
                    Name: {formik.errors.name}
                  </label>
                ) : (
                  <label>Name</label>
                )}
              </div>
              <div className="form-floating">
                <input
                  name="lastName"
                  type="text"
                  placeholder="Enter your email"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.lastName && formik.touched.lastName ? (
                  <label className="text-danger">
                    Last Name: {formik.errors.lastName}
                  </label>
                ) : (
                  <label>Last Name</label>
                )}
              </div>
              <div className="form-floating">
                <input
                  name="email"
                  type="text"
                  placeholder="Enter your email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.email && formik.touched.email ? (
                  <label className="text-danger">
                    Email: {formik.errors.email}
                  </label>
                ) : (
                  <label>Email</label>
                )}
              </div>
              <div className="form-floating">
                <input
                  name="password"
                  type="password"
                  placeholder="Enter your email"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.password && formik.touched.password ? (
                  <label className="text-danger">
                    Password: {formik.errors.password}
                  </label>
                ) : (
                  <label>Password</label>
                )}
              </div>
              <div className="form-floating">
                <select
                  className="form-select"
                  id="gender"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select an option</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="other">Other</option>
                </select>

                {formik.errors.gender && formik.touched.gender && (
                  <div className="form-text login-create-help text-danger mb-2">
                    {formik.errors.gender}
                  </div>
                )}
                <label>Gender</label>
              </div>
              <button
                disabled={!formik.isValid}
                className="w-100 btn btn-lg primary-color-bg mt-3"
                type="submit"
              >
                Register
              </button>
              <div className="mt-3 mb-3">
                <hr />
                <Link to={"/login"} className="no-underline primary-color">
                  Sig In
                </Link>
              </div>
            </form>
            <p className="mt-3 mb-3 text-body-secondary">&copy; 2023</p>
          </main>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className=" modal-login"
      >
        <div className="modal-content content">
          <div className="modal-body">
            <p className="text-center">
              {" "}
              Account has been created! You will receive an email confirmation.
            </p>
          </div>
          <div className="modal-footer footer-login-modal  d-md-block  text-center">
            <NavLink to={"/login"}>
              <button type="button" className="btn btn-sm btn-send ">
                Close
              </button>
            </NavLink>
          </div>
        </div>
      </Modal>
    </>
  );
}
export { NewUser };
