import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class AuthService {

    //login
    login(data) {
        return http.post(`/auth/login`, data);
    }
    //recovery password
    recoveryPassword(email) {
        return http.post(`/auth/recovery/${email}`);
    }

    //recovery password
    changePassword(password,data,authorization) { 
        return http.patch(`/auth/changepassword/${password}`,data, authorization);
    }

}
export default new AuthService();