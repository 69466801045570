import React, { useState, useEffect, useLayoutEffect, Suspense } from "react";
import {
  Routes,
  Route,
  Link,
  NavLink,
  useParams,
  useLocation,
} from "react-router-dom";

//pages
import { Services } from "../../components/pages/services";
import { About } from "../../components/pages/about";
import { Blog } from "../../components/pages/blog";
import { Home } from "../pages/home";
import { BlogDetails } from "../pages/blog-details";
import { Contact } from "../pages/contact";
import { Footer } from "../layouts/footer";
//services
import { AppDevelopment } from "../pages/services/app-development";
import { WebDevelopment } from "../pages/services/web-development";
import { ProductSupportAndMaintenance } from "../pages/services/Product-support-and-Maintenance";
import { UiDesing } from "../pages/services/ui-design";
import { UxDesing } from "../pages/services/ux-design";
import { TechnicalProductResearch } from "../pages/services/technical-product-research.";
import { Branding } from "../pages/services/branding-page";
import { MarketingAndStrategy } from "../pages/services/marketingAndStrategy";
import { Enteripse } from "../pages/services/enteripse";
import { SecurityConsulting } from "../pages/services/security";
import { SystemCloudIntegration } from "../pages/services/cloudIntegration";
import { BackEnd } from "../pages/services/backend";
import { Portfolio } from "../pages/portfolio";

//terms privacy
import { TermsConditions } from "../pages/termsConditions";
import { PrivacyPolicy } from "../pages/privacyPolicy";
import { Faqs } from "../pages/faqs";

//traslate
import { useTranslation } from "react-i18next";

function Menu() {
  //traslate
  const { t, i18n } = useTranslation("global");
  //images
  const logo = require("../../assets/img/logos/logo-footer.png");
  const whatsapp = require("../../assets/img/icons/whatsapp.png");

  const favicon = require("../../assets/img/favicon.png");

  const [isScrolled, setIsScrolled] = useState(false);
  const [isEndOfPage, setIsEndOfPage] = useState(false);
  const [isMenuBlack, setIsMenuBlack] = useState(false);
  const location = useLocation();

  const [preLoader, setPreloader] = useState(false);

  const [mobil, setMobil] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const availableLanguages = ["en", "es", "fr", "de", "it"];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  //scrolled
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".menu-detected")) {
        setIsMenuOpen(false);
        setMobil(false);
      }
    };

    if (isMenuOpen === true) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    setPreloader(false);
    const timeoutId = setTimeout(() => {
      setPreloader(true);
    }, 1000);
    if (location.pathname === "/") {
      setIsMenuBlack(false);
    } else if (
      location.pathname === "/services" ||
      location.pathname === "/about" ||
      location.pathname === "/blog" ||
      location.pathname === location.pathname
    ) {
      setIsMenuBlack(true);
    } else {
      setIsMenuBlack(false);
    }

    const handleScroll = () => {
      const position = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (position > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
      let sumPosition = position + windowHeight + 200;
      if (sumPosition > documentHeight) {
        setIsEndOfPage(true);
      } else {
        setIsEndOfPage(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleClickOutside);
    };
    return () => {
      clearTimeout(timeoutId);
    };
  }, [location]);

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onmobil = () => {
    if (mobil === true) {
      setMobil(false);
    } else if (mobil === false) {
      setIsMenuOpen(true);
      setMobil(true);
      //  console.log('true')
    }
  };
  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    oncloseMenu();
  };

  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  const handleLanguageMenuOpen = () => {
    // console.log('handleLanguageMenuOpen')
    setIsLanguageMenuOpen(true);
  };

  const handleLanguageMenuClose = () => {
    // console.log('handleLanguageMenuClose')
    setIsLanguageMenuOpen(false);
  };
  const oncloseMenu = () => {
    setIsMenuOpen(false);
    setMobil(false);
  };
  return (
    <>
      <div className={`loading-wrap ${preLoader ? "fade-out" : ""}`}>
        {preLoader === false && (
          <div className="preloader">
            <div className="pre-container">
              <img
                loading="lazy"
                src={favicon}
                alt="charlieapps charlie apps"
              />
            </div>
          </div>
        )}
      </div>
      <header id="active-sticky" className="fixed ">
        <div
          className={`fixed-nav ${
            isMenuBlack ? "fixed-nav-black" : ""
          } sticky-top ${isScrolled ? "scrolled" : ""}`}
        >
          <div className="container-sm  ">
            <nav
              className="navbar navbar-expand-lg  position-sticky menu-detected"
              aria-label="Eleventh navbar example"
            >
              <div className="container-fluid logo-menu ">
                <NavLink className="navbar-brand" to={"/"}>
                  <img
                    loading="lazy"
                    className="nav-img-logo"
                    src={logo}
                    alt="charlieapps charlie apps"
                  />
                </NavLink>

                <div className={mobil === true ? `mainmenu` : ""}>
                  <button
                    onClick={onmobil}
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarsExample09"
                    aria-controls="navbarsExample09"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i
                      className="fa-solid fa-bars"
                      style={{ color: "#ffffff" }}
                    ></i>
                  </button>
                  <div
                    className={`collapse navbar-collapse component-menu ${
                      mobil === true ? `sohw` : "false"
                    }`}
                    id="navbarsExample09"
                  >
                    <ul className="navbar-nav col-lg-12 justify-content-lg-end mainmenu  ">
                      <li className="nav-item mx-2">
                        <NavLink
                          onClick={oncloseMenu}
                          className="nav-link"
                          to={"/"}
                        >
                          {" "}
                          {t("menu.home")}
                        </NavLink>
                      </li>
                      <li className="nav-item mx-2">
                        <NavLink
                          onClick={oncloseMenu}
                          className="nav-link"
                          to={"services"}
                        >
                          {t("menu.services")}
                        </NavLink>
                      </li>
                      <li className="nav-item mx-2">
                        <NavLink
                          onClick={oncloseMenu}
                          className="nav-link"
                          to={"blog"}
                        >
                          {t("menu.blog")}
                        </NavLink>
                      </li>
                      <li className="nav-item mx-2">
                        <NavLink
                          onClick={oncloseMenu}
                          className="nav-link"
                          to={"about"}
                        >
                          {t("menu.about")}
                        </NavLink>
                      </li>
                      <li className="nav-item mx-2">
                        <NavLink
                          onClick={oncloseMenu}
                          className="nav-link"
                          to={"contact"}
                        >
                          {t("menu.contact")}
                        </NavLink>
                      </li>
                      <li className="nav-item dropdown mb-dropdown  mx-2">
                        <a
                          className="nav-link dropdown-toggle"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {i18n.language.toUpperCase()}
                        </a>
                        <ul
                          className={`dropdown-menu text-center ${
                            isLanguageMenuOpen ? "show" : ""
                          }`}
                          onMouseOver={handleLanguageMenuOpen}
                          onMouseLeave={handleLanguageMenuClose}
                        >
                          {availableLanguages
                            .filter((lang) => lang !== i18n.language)
                            .map((lang) => (
                              <li key={lang}>
                                <a
                                  className="dropdown-item"
                                  onClick={() => handleLanguageChange(lang)}
                                >
                                  {lang.toUpperCase()}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>

                {/*                                 <div class="mainmenu hover-1">
                                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>
                                    <nav class="navbar-collapse clearfix collapse in" aria-expanded="false" >
                                        <ul id="main-nav" class="navigation clearfix">
                                            <li><a href="index.html" class="active">home</a></li>
                                            <li><a href="services.html">services</a>

                                            </li>
                                            <li><a href="blog.html">blog</a></li>
                                            <li><a href="about-us.html">about</a></li>
                                            <li><a href="contact-us.html">contact us</a></li>
                                        </ul>
                                    </nav>
                                </div> */}
              </div>
            </nav>
          </div>
        </div>
      </header>

      {isEndOfPage ? (
        <div className="whatsapp-fixed">
          <a
            className="d-flex justify-content-end whatsapp-fixed img"
            href="https://wa.me/+41782072008"
            target="_blank"
          >
            <img
              loading="lazy"
              src={whatsapp}
              alt="charlieapps charlie apps whatsapp"
            />
          </a>
          <Link className="btn-up" onClick={handleScrollUp}>
            <i className="fa fa-angle-up"></i>
          </Link>
        </div>
      ) : (
        <div className="whatsapp-fixed-no">
          <a
            className="d-flex justify-content-end whatsapp"
            href="https://wa.me/+41782072008"
            target="_blank"
          >
            <img
              loading="lazy"
              src={whatsapp}
              alt="charlieapps charlie apps whatsapp"
            />
          </a>
          <Link className="btn-up btn-up-no-visible" onClick={handleScrollUp}>
            <i className="fa fa-angle-up"></i>
          </Link>
        </div>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details/:id" element={<BlogDetails />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/app-development" element={<AppDevelopment />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route
            path="/Product-Support-Maintenance"
            element={<ProductSupportAndMaintenance />}
          />
          <Route path="/ui-design" element={<UiDesing />} />
          <Route path="/ux-design" element={<UxDesing />} />
          <Route
            path="/technical-product-research"
            element={<TechnicalProductResearch />}
          />
          <Route path="/branding" element={<Branding />} />
          <Route
            path="/marketing-and-strategy"
            element={<MarketingAndStrategy />}
          />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/services/enteripe-solutions" element={<Enteripse />} />
          <Route
            path="/services/software-security-consulting"
            element={<SecurityConsulting />}
          />
          <Route
            path="/services/system-cloud-integration"
            element={<SystemCloudIntegration />}
          />
          <Route
            path="/services/backend-api-development"
            element={<BackEnd />}
          />
          {/* portfolio */}
          <Route path="/portfolio" element={<Portfolio />} />
        </Routes>
      </Suspense>

      <Footer />
    </>
  );
}
export { Menu };
