import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class NewslatterService {

    //create form contact
    createNewslatter(data) {
        return http.post(`/newslatter/new`, data);
    }

    //get all subscriber
    getSubscriber(authorization) {
        return http.get(`/newslatter/get`,authorization);
    }

}

export default new NewslatterService();