import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function MarketingAndStrategy() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const shape = require("../../../assets/img/shape/3.webp");
  const element1 = require("../../../assets/img/MarketingAndStrategy/Marketing and Strategy Services/Elemento.png");
  const video1 =
    "https://charlieapps.b-cdn.net/cdn/videos//M-and-S/Landscape-for-Web.mp4";
  const Achieve = require("../../../assets/img/MarketingAndStrategy/02.png");
  const Increase = require("../../../assets/img/MarketingAndStrategy/03.png");
  const Target = require("../../../assets/img/MarketingAndStrategy/01.png");
  // const  = require('../../../');
  useEffect(() => {
    document.title = "Marketing And Strategy";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div id="mas-container" className="container-fluid">
        <div id="mas-main-img" className="center ">
          <img
            loading="lazy"
            src={element1}
            className="img-fluid"
            alt="charlieapps charlie apps marketing and strategy"
          />
        </div>
        <div id="mas-services-div" className="col-xs-12  mb-70">
          <div id="mas-shape" className="shape">
            <img
              loading="lazy"
              src={shape}
              alt="charlieapps charlie apps marketing and strategy"
            />
          </div>
          <h3 className="theme-color">{t("marketingStrategy.title")}</h3>
          <h1 className="mb-75">{t("marketingStrategy.subTitle")}</h1>
          <p id="mas-main-description">{t("marketingStrategy.titleText")}</p>
          <div className="RSbutton-div text-center mt-20">
            <Link
              to={"/contact"}
              id="mas-rsbutton"
              className="btn btn-primary RSBtn w-50"
            >
              {" "}
              {t("marketingStrategy.btnRequest")}{" "}
              <i className="fa fa-angle-right"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="row mas-mid-container">
        <div className="col-sm-12 web-item-tex">
          <div className="mas-mid-description">
            <h1 className="mb-20">{t("marketingStrategy.DigitalMarketing")}</h1>
            <p className="mb-20">
              {t("marketingStrategy.DigitalMarketingText")}
            </p>
          </div>
        </div>
        <div className="col-sm-12 mas-videos ">
          <video className="psm-video" controls>
            <source src={video1} type="video/mp4" />
            {t("productSupport.browser")}
          </video>
        </div>
        <div className="col-sm-8 mas-videos-2 ">
          <video className="psm-video" controls>
            <source src={video1} type="video/mp4" />
            {t("productSupport.browser")}
          </video>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mas-bottom-div pb-5">
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 mas-bottom-item web-item-text mt-3 ">
            <img
              loading="lazy"
              src={Target}
              alt="charlieapps charlie apps marketing and strategy Achieve Brand Awareness"
            />
            <h3>{t("marketingStrategy.Achieve")}</h3>
            <p>{t("marketingStrategy.AchieveText")}</p>
          </div>
          <div className="ccol-12 col-sm-12 col-md-12 col-lg-4 mas-bottom-item web-item-text mt-3">
            <img
              loading="lazy"
              src={Achieve}
              alt="charlieapps charlie apps marketing and strategy Increase Audiance Engagement Image"
            />
            <h3>{t("marketingStrategy.Increase")}</h3>
            <p>{t("marketingStrategy.IncreaseText")}</p>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 mas-bottom-item web-item-text mt-3">
            <img
              loading="lazy"
              src={Increase}
              alt="charlieapps charlie apps marketing and strategy Target Effectively Image"
            />
            <h3>{t("marketingStrategy.Target")}</h3>
            <p>{t("marketingStrategy.TargetText")}</p>
          </div>
        </div>
      </div>

      <div className="RSbutton-div text-center mt-20 mb-90 ui">
        <a
          id="mas-rsbutton-bottom"
          href="tel:+1-855-527-7932"
          className="btn btn-primary RSBtn w-25"
        >
          {" "}
          {t("marketingStrategy.btnCallUs")} <i className="fa fa-phone"></i>
        </a>
      </div>
    </>
  );
}
export { MarketingAndStrategy };
