import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import CustomModal from "./modalCustom";

const SliderIdentity = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  /* images */
  const identityA = isMobile
    ? require("../../../assets/img/portfolio/identity/ident-1-movil.webp")
    : require("../../../assets/img/portfolio/identity/ident-1.png");
  const identityB = isMobile
    ? require("../../../assets/img/portfolio/identity/ident-2-movil.webp")
    : require("../../../assets/img/portfolio/identity/ident-2.webp");
  const identityC = isMobile
    ? require("../../../assets/img/portfolio/identity/ident-3-movil.webp")
    : require("../../../assets/img/portfolio/identity/ident-4.webp");
  const identityD = isMobile
    ? require("../../../assets/img/portfolio/identity/ident-4-movil.webp")
    : require("../../../assets/img/portfolio/identity/ident-5.webp");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
  }, [isMobile]);

  const identity = () => {
    const settings = {
      dots: true,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 10000,
    };

    return (
      <div className="slider-identity">
        <CustomModal
          size={sizeModal}
          show={showModal}
          onHide={handleClose}
          modal_title={modalTitle}
          modal_content={modalContent}
        />
        <div className="title">
          <h1>Corporate Brand Identity</h1>
          {/*    <p>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Architecto
            vel beatae sequi nihil! Necessitatibus, itaque commodi? Vitae
            deleniti asperiores quisquam facilis fugiat dignissimos tenetur
            earum, praesentium, cumque quaerat ab dolore.
          </p> */}
        </div>
        <Slider {...settings} className="">
          <div className="box">
            <div className="title">
              <img src={identityA} alt="charlieapps identity" />
            </div>
          </div>
          <div className="box">
            <div className="title">
              <img src={identityB} alt="charlieapps identity" />
            </div>
          </div>
          <div className="box">
            <div className="title">
              <img src={identityC} alt="charlieapps identity" />
            </div>
          </div>
          <div className="box">
            <div className="title">
              <img src={identityD} alt="charlieapps identity" />
            </div>
          </div>
        </Slider>
      </div>
    );
  };

  /* modal */
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [sizeModal, setSizeModal] = useState("");

  //module title
  const [titleModule, setTitleModule] = useState("");
  const [keyModule, setKeyModule] = useState("");
  const [itemData, setItemData] = useState([]);

  //modal content
  const modalTitle = titleModule;
  const modalContent = (
    <>
      {(() => {
        switch (keyModule) {
          case "reel":
            return <></>;
          default:
            return null;
        }
      })()}
    </>
  );
  return <>{identity()}</>;
};

export default SliderIdentity;
