import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Link,
  NavLink,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

//blog service
import blogService from "../../services/blog.service";

function BlogDetails() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const gif = require("../../assets/img/blog/Animation/Animacion-iconos-Blog Interno.gif");
  const logoImg = require("../../assets/img/logo.png");

  //get url params
  const idBlog = useParams();
  const title = useParams();
  const originalTitle = decodeURIComponent(title.id.replace(/-/g, " "));

  const [getId, setGetId] = useState("");

  //traslate

  const location = useLocation();

  //data array blog
  const [arrBlog, setArrBlog] = useState([]);

  const [pageTitle, setPageTitle] = useState("");

  const [date, setDate] = useState("");
  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const currentLanguage = i18n.language.toUpperCase();
  const language_get = i18n.language;

  //language date
  const [languageDate, setLanguageDate] = useState([]);

  useEffect(() => {
    switch (language_get) {
      case "es":
        setLanguageDate("es-ES");
        break;
      case "fr":
        setLanguageDate("fr-FR");
        break;
      case "it":
        setLanguageDate("it-IT");
        break;
      case "de":
        setLanguageDate("de-DE");
        break;
      case "en":
        setLanguageDate("en-US");
        break;
      default:
        setLanguageDate("en-US");
    }
    window.scrollTo(0, 0);
    onGetBlogID();
  }, [language_get, languageDate]);

  const onGetBlogID = async () => {
    //console.log(originalTitle)
    try {
      const getBlogs = await blogService.getBlogByIdNamePublic(originalTitle);
      //console.log(getBlogs.data[0])
      if (getBlogs.status === 200 && getBlogs.data.statusCode === 404) {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      } else if (getBlogs.status === 200) {
        setArrBlog(getBlogs.data[0]);
        const fecha = new Date(getBlogs.data[0].bloCreateAt);
        const opcionesFecha = {
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        const fechaFormateada = fecha.toLocaleString(
          languageDate,
          opcionesFecha
        );
        setDate(fechaFormateada);

        setPageTitle(getBlogs.data[0].bloTitle || "Default Title");

        // Obtener la URL amigable del blog
        const friendlyUrl = generateFriendlyUrl(arrBlog.bloTitle);
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      setShowAlert(true);
      setMessage({ error: true, msg: "An error has occurred! try again" });
    }
  };
  const onGetBlog = async () => {
    try {
      const getBlogs = await blogService.getBlogByIdPublic(idBlog.id);
      //console.log(getBlogs.data[0])
      if (getBlogs.status === 200 && getBlogs.data.statusCode === 404) {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      } else if (getBlogs.status === 200) {
        setArrBlog(getBlogs.data[0]);
        const fecha = new Date(getBlogs.data[0].bloCreateAt);
        const opcionesFecha = {
          day: "numeric",
          month: "long",
          year: "numeric",
        };
        const fechaFormateada = fecha.toLocaleString(
          languageDate,
          opcionesFecha
        );
        setDate(fechaFormateada);

        setPageTitle(getBlogs.data[0].bloTitle || "Default Title");

        // Obtener la URL amigable del blog
        const friendlyUrl = generateFriendlyUrl(arrBlog.bloTitle);
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      setShowAlert(true);
      setMessage({ error: true, msg: "An error has occurred! try again" });
    }
  };

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  // Función para generar la URL amigable
  const generateFriendlyUrl = (title) => {
    const friendlyUrl = title
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+/g, "-")
      .replace(/(^-|-$)+/g, "");
    return friendlyUrl;
  };

  return (
    <>
      <div className="pagetitle slider-area height-600 bg-img-2">
        <div className="mouse-parallax layer-parent">
          <div data-depth="0.2" className="layer bg-img-2"></div>
        </div>
        <div className="d-table">
          <div className="dt-cell">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 text-center">
                  <div className="page-title  internal-blog-title">
                    <h1 className="gray-color no-margin">{arrBlog.bloTitle}</h1>
                    <img
                      src={gif}
                      style={{ width: "100%" }}
                      alt={arrBlog.bloTitle}
                      className="internal-blog-title-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-details white-bg section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-10 col-md-8 column-center">
              <div className="single-post pb-80">
                <div className="thumb fix mb-50">
                  <img
                    src={arrBlog.bloImage}
                    style={{ width: "100%" }}
                    alt={arrBlog.bloTitle}
                  />
                </div>
                <div className="content">
                  <h5 className="theme-color mb-10">{date}</h5>
                  <h3 className="text-capitalize mb-20">{arrBlog.bloTitle}</h3>
                  <p>{arrBlog.bloDescription}</p>
                  <div
                    className="content mt-5 "
                    dangerouslySetInnerHTML={{ __html: arrBlog.bloData }}
                  ></div>

                  <div className="tag-share clearfix pt-65 ">
                    <div className="pull-left d-flex justify-content-start">
                      <strong>Tags:</strong> <a href="#">Design,</a>{" "}
                      <a href="#">Development,</a>
                      <a href="#">Creative</a>
                    </div>
                    <div className="pull-right d-flex justify-content-end">
                      <strong>Share:</strong>
                      <div className="social-icon social-tag pull-right">
                        <ul className="clearfix d-inblock">
                          {/* {console.log(process.env.REACT_APP_PATH_WEB + location.pathname)} */}
                          <li>
                            <a
                              href={`https://www.facebook.com/sharer/sharer.php?u=${
                                process.env.REACT_APP_PATH_WEB +
                                location.pathname
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa-brands fa-facebook-f"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href={`https://twitter.com/share?url=${
                                process.env.REACT_APP_PATH_WEB +
                                location.pathname
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa-brands fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href={`https://www.instagram.com/share?url=${
                                process.env.REACT_APP_PATH_WEB +
                                location.pathname
                              }`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa-brands fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-box mb-75 fix">
                <div className="admin-img pull-left mr-30">
                  <img loading="lazy" src={logoImg} alt={arrBlog.bloTitle} />
                </div>
                <div className="text">
                  <h6>Written By</h6>
                  <h3 className="mb-10">{arrBlog.editor}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { BlogDetails };
