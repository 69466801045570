import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

//blog service
import blogService from "../../services/blog.service";

function Blog() {
  //traslate
  const { t, i18n } = useTranslation("global");

  const header_animation = require("../../assets/img/blog/Animation/Animacion-iconos-Blog-principal.gif");
  const blogImg = require("../../assets/img/blog/development-blog/image2.png");

  const location = useLocation();

  //data array blog
  const [arrBlog, setArrBlog] = useState([]);

  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  //language date
  const [languageDate, setLanguageDate] = useState([]);

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const currentLanguage = i18n.language.toUpperCase();
  const language_get = i18n.language;

  useEffect(() => {
    document.title = "Blogs";

    onGetBlog();
    switch (language_get) {
      case "es":
        setLanguageDate("es-ES");
        break;
      case "fr":
        setLanguageDate("fr-FR");
        break;
      case "it":
        setLanguageDate("it-IT");
        break;
      case "de":
        setLanguageDate("de-DE");
        break;
      case "en":
        setLanguageDate("en-US");
        break;
      default:
        setLanguageDate("en-US");
    }
    window.scrollTo(0, 0);
  }, [currentLanguage, language_get, i18n.language]);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onGetBlog = async () => {
    try {
      const getBlogs = await blogService.getAllBlogsPublic();
      // console.log(getBlogs)
      if (getBlogs.status === 200 && getBlogs.data.statusCode === 404) {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      } else if (getBlogs.status === 200) {
        setArrBlog(getBlogs.data);
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      //  console.log(error)
      setShowAlert(true);
      setMessage({ error: true, msg: "An error has occurred! try again" });
    }
  };

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = arrBlog.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(arrBlog.length / itemsPerPage);
  const handleClick = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 0);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 0);
  };
  const prevButtonDisabled = currentPage === 1;
  const nextButtonDisabled =
    currentPage === Math.ceil(arrBlog.length / itemsPerPage);
  //emd pagination

  return (
    <>
      <div className="bg-img-25 height-100" style={{ height: "26rem" }}>
        <div className="d-table">
          <div className="dt-cell d-flex justify-content-center">
            <div
              className="container "
              style={{ position: "absolute", bottom: "0" }}
            >
              <div className="row">
                <img
                  loading="lazy"
                  className="bg-img-25-1"
                  src={header_animation}
                  alt="charlie apps blog"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="white-bg blog-section ">
        <div className="blog-area standard white-bg pt-100 pb-70 ">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-xs-12 col-sm-10 col-md-8 column-center ">
                <div className=" ">
                  {currentItems.map((item, i) => {
                    if (item.bloLanguage === currentLanguage) {
                      const fecha = new Date(item.bloCreateAt);
                      const opcionesFecha = { month: "long" };
                      const dateMonth = fecha.toLocaleString(
                        `${languageDate}`,
                        opcionesFecha
                      );
                      const day = { day: "numeric" };
                      const dateDay = fecha.toLocaleString(
                        `${languageDate}`,
                        day
                      );
                      return (
                        <div className="blog-post mb-100 " key={i}>
                          <div className=" text-center">
                            <Link
                              to={`/blog-details/${encodeURIComponent(
                                item.bloTitle
                                  .replace(/^\s+|\s+$/g, "")
                                  .replace(/\s+/g, "-")
                              )}`}
                            >
                              <img
                                className="img-fluid"
                                src={item.bloImage ? item.bloImage : blogImg}
                                alt="charlie apps blog"
                              />
                            </Link>
                          </div>
                          <div className="content">
                            <div className="post-title mb-30">
                              <div className="title-date text-center mobi-mb-30">
                                <h5 className="day">{dateDay}</h5>
                                <hr className="hidden-xs d-none d-md-block" />
                                <h5 className="month text-uppercase">
                                  {dateMonth}
                                </h5>
                              </div>
                              <div className="title-text mt-1">
                                <Link
                                  to={`/blog-details/${encodeURIComponent(
                                    item.bloTitle
                                      .replace(/^\s+|\s+$/g, "")
                                      .replace(/\s+/g, "-")
                                  )}`}
                                >
                                  <h4 className="text-capitalize">
                                    {item.bloTitle}
                                  </h4>
                                </Link>
                                <hr />
                                <div className="post-info">
                                  <div className="info comments">
                                    <i className="fa-solid fa-user"></i>
                                    <span className="mx-2">Autor: </span>{" "}
                                    <span>{item.editor} </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="mb-10 mt-4 mb-3">
                              {item.bloDescription}
                            </p>
                            <Link
                              to={`/blog-details/${encodeURIComponent(
                                item.bloTitle
                                  .replace(/^\s+|\s+$/g, "")
                                  .replace(/\s+/g, "-")
                              )}`}
                              className="read-more"
                            >
                              {t("blogs.ReadMore")}{" "}
                              <i className="fa fa-long-arrow-right"></i>
                            </Link>{" "}
                          </div>
                        </div>
                      );
                    } else {
                      <div className="blog-post mb-100 ">
                        <div className=" text-center">
                          <Link to={""}>
                            <img
                              className="placeholder col-6"
                              src={""}
                              alt="charlie apps blog"
                            />
                          </Link>
                        </div>
                        <div className="content">
                          <div className="post-title mb-30">
                            <div className="title-date text-center mobi-mb-30">
                              <h5 className="day placeholder col-6"></h5>
                              <hr className="hidden-xs" />
                              <h5 className="month text-uppercase placeholder col-6"></h5>
                            </div>
                            <div className="title-text mt-1">
                              <Link to={``}>
                                <h4 className="text-capitalize placeholder col-6 "></h4>
                              </Link>
                              <hr />
                              <div className="post-info">
                                <div className="info comments">
                                  <i className="fa-solid fa-user"></i>
                                  <span className="mx-2 placeholder col-6">
                                    Autor:{" "}
                                  </span>{" "}
                                  <span> </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="mb-10 mt-4 mb-3">
                            {item.bloDescription}
                          </p>
                          <Link to={``} className="read-more">
                            {t("blogs.ReadMore")}{" "}
                            <i className="fa fa-long-arrow-right placeholder col-6"></i>
                          </Link>{" "}
                        </div>
                      </div>;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div className="rt-pagination text-center">
              <ul className=" pagination">
                <li
                  className={
                    prevButtonDisabled
                      ? "disabled rt-pagination-disable "
                      : "page-item"
                  }
                  id="prev"
                >
                  <a onClick={handlePrevClick} className="page-link">
                    <span>&lt;&lt;</span>{" "}
                    <span className="hidden-xs"> {t("blogs.Prev")}</span>
                  </a>
                </li>
                {pageNumbers.map((number) => (
                  <li
                    className={number === currentPage ? "active" : ""}
                    key={number}
                  >
                    <a href="#" onClick={(e) => handleClick(e, number)}>
                      {number}
                    </a>
                  </li>
                ))}
                <li
                  className={
                    nextButtonDisabled
                      ? "disabled rt-pagination-disable "
                      : "page-item"
                  }
                  id="next"
                >
                  <a onClick={handleNextClick} className="page-link">
                    <span className="hidden-xs"> {t("blogs.Next")}</span>{" "}
                    <span>&gt;&gt;</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export { Blog };
