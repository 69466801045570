const particlesConfig={
  "fullScreen": {
    "enable": false,
    "zIndex": 1
},
"fps_limit": 60,
"interactivity": {
    "detectsOn": "canvas",
    "events": {
        "onClick":
            { "enable": true, "mode": "push" },
        "onHover": { "enable": true, "mode": "repulse" },
        "resize": true
    }, "modes": {
        "push": { "particles_nb": 4 },
        "repulse": { "distance": 200, "duration": 0.4 }
    }
},
"particles": {
    "color": { "value": "#ffffff" },
    "links": { "color": "#ffffff", "distance": 150, "enable": true, "opacity": 0.4, "width": 1 },
    "move": {
        "bounce": false, "direction": "none",
        "enable": true, "outMode": "out", "random": false,
        "speed": 2, "straight": false
    },
    "number": { "density": { "enable": true, "area": 600 }, "value": 80 },
    "opacity": { "value": 0.5 },
    "shape": { "type": "circle" },
    "size": { "random": true, "value": 5 }
},
"detectRetina": true,
"background": {
    "color": "",
    "image": "",
    "position": "50% 50%",
    "repeat": "no-repeat",
    "size": "cover"
}
  }
export default particlesConfig