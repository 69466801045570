import React, { useContext, useState } from "react";

import http from "../configs/http.config";

class UserService {

    //create user
    createUser(data) {
        return http.post(`/user/new`, data);
    }

}
export default new UserService();