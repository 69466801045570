import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
//traslate
import { useTranslation, Trans } from "react-i18next";

//recapchat
import ReCAPTCHA from "react-google-recaptcha";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//user service
import NewslatterService from "../../services/newslatter.service";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//validation form
const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

function Footer({ ignoreScroll }) {
  //traslate
  const { t, i18n } = useTranslation("global");

  //images
  const logo = require("../../assets/img/logos/logo-footer.png");
  const facebook = require("../../assets/img/footer/facebook.png");
  const instagram = require("../../assets/img/footer/instagram.png");
  const youtube = require("../../assets/img/footer/youtube.png");
  const LinkedIn = require("../../assets/img/footer/linkedin.png");

  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  //recapchat
  const capchat = useRef(null);
  const [recapchatView, setRecapchatView] = useState(false);

  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //formik
  const formik = useFormik({
    initialValues: { email: "" },
    validateOnBlur: true,
    onSubmit: (values) => {
      onSubmitNewslatter(values);
      /*          if (recapchatView === true) {
                onSubmitNewslatter(values);
            } else {
                setShowAlert(true);
                setMessage({ error: true, msg: "please mark the recaptcha" });
            } */
    },
    validationSchema: validationSchema,
  });

  const onSubmitNewslatter = async (values) => {
    let data = {
      email: values.email,
      key: process.env.REACT_APP_URL_SECRET_KEY_FORM,
    };
    try {
      const createNewslatter = await NewslatterService.createNewslatter(data);
      if (
        createNewslatter.status === 200 &&
        createNewslatter.data.statusCode === 201
      ) {
        setShow(true);
      } else if (
        createNewslatter.status === 200 &&
        createNewslatter.data.statusCode === 404
      ) {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      } else if (
        createNewslatter.status === 200 &&
        createNewslatter.data.output.statusCode === 403
      ) {
        setShowAlert(true);
        setMessage({
          error: true,
          msg: "you are already registered in Charlieapps",
        });
      }
    } catch (error) {
      setShowAlert(true);
      setMessage({ error: true, msg: "An error has occurred! try again" });
    }
  };

  function onCapchat() {
    if (capchat.current.getValue()) {
      setRecapchatView(true);
    } else {
      setRecapchatView(false);
    }
  }

  return (
    <>
      <footer className="bg-dark-footer footer bg-pattern-footer">
        <div className="container">
          <div className="footer-container">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12 col-md-12 col-lg-4 colum order-lg-1 order-3 menuu list  mb-3">
                <ul className="list-unstyled">
                  <li className="brown-text font-45">
                    <NavLink to={"terms-conditions"}>
                      {t("footer.Terms")}
                    </NavLink>
                  </li>
                  <li className="brown-text font-45">
                    <NavLink to={"privacy-policy"}>
                      {t("footer.Privacy")}
                    </NavLink>
                  </li>
                  <li className="brown-text font-45">
                    <NavLink to={"faqs"}>{t("footer.FAQs")}</NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 colum text-center order-lg-2 order-1 dashed-border mb-3">
                <img
                  loading="lazy"
                  className="logo"
                  src={logo}
                  alt="charlieapps charlie apps"
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4 colum order-lg-3 order-2 dashed-border">
                <div className="row suscribe ">
                  <div className="box">
                    <p className="">{t("footer.suscribe")} </p>
                  </div>

                  <div className="custom-input-group col-lg-12 col-md-12 ">
                    <form
                      onSubmit={formik.handleSubmit}
                      id="newsletter"
                      className="custom-input"
                      noValidate="noValidate"
                    >
                      <input
                        type="text"
                        className={`requiredField form-control bg-ligth-gray ${
                          formik.errors.email && formik.touched.email
                            ? "text-danger"
                            : ""
                        }`}
                        placeholder={
                          formik.errors.email && formik.touched.email
                            ? "*Email " + formik.errors.email
                            : "Email"
                        }
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <button
                        disabled={!formik.isValid}
                        className="btn btn-primary"
                      >
                        {t("footer.send")}
                      </button>
                    </form>
                    {message ? (
                      <Alert
                        show={showAlert}
                        className="alert"
                        variant={`${message.error ? "danger" : "success"}`}
                      >
                        <p className="alert-message">{message.msg}</p>
                        <div className="d-flex justify-content-end">
                          <Button
                            onClick={() => setShowAlert(false)}
                            size="xm"
                            variant={`outline-${
                              message.error ? "danger" : "success"
                            }`}
                          >
                            X
                          </Button>
                        </div>
                      </Alert>
                    ) : null}
                  </div>
                  <div className="">
                    <div className="row">
                      <div className=" social-links">
                        <ul className="list-inline ">
                          <li className="list-inline-item">
                            <a
                              href="https://www.instagram.com/charlieapps/"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src={instagram}
                                alt="charlieapps charlie apps LinkedIn"
                              />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://www.facebook.com/charlieappsllc/"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src={facebook}
                                alt="charlieapps charlie apps Instagram"
                              />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://www.youtube.com/@charlieapps392"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src={youtube}
                                alt="charlieapps charlie apps youtube"
                              />
                            </a>
                          </li>
                          <li className="list-inline-item">
                            <a
                              href="https://www.linkedin.com/company/charlieapps/"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src={LinkedIn}
                                alt="charlieapps charlie apps LinkedIn"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="copyrigth">
          <p className="d-flex justify-content-center copyrigth font-45 txt-bold">
            {t("footer.Copyright")}
          </p>
        </div>
      </footer>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className=" modal-login"
      >
        <div className="modal-content content">
          <div className="modal-body">
            <p className="text-center">
              {" "}
              You have subscribed to the newsletter!.
            </p>
          </div>
          <div className="modal-footer footer-login-modal  d-md-block  text-center">
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-sm btn-send "
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
export { Footer };
