import React, { useState, useEffect } from 'react';
import { Link, NavLink, useParams, useNavigate } from 'react-router-dom';

//blog service
import contact from '../../../../services/contact.service'

//auth
import { useAuth } from '../../../../context/AuthProvider';

//bootstrap
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function ViewContactDashboard() {
    //data array blog
    const [arrContact, setArrContact] = useState([]);

    //message error
    const [message, setMessage] = useState(null);
    //alert
    const [showAlert, setShowAlert] = useState(true);

    //modal
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = () => setShow(true);

    //validation auth
    const auth = useAuth();

    //get url params
    const idBlog = useParams();



    useEffect(() => {
        onGetContact()
       // console.log('alasklasklkl')
    }, []);

    const onGetContact = async () => {
        try {
            let bearer_Token = {
                'headers': {
                    'Authorization': `Bearer ${auth.authToken()}`
                }
            };
            const getContact = await contact.getAllContactById(idBlog.id, bearer_Token);
            //console.log(getContact);

            if (getContact.status === 200 && getContact.data.statusCode === 404) {
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });

            }

            else if (getContact.status === 200) {
                setArrContact(getContact.data);

            } else {
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            }
        } catch (error) {
            if (error.response.status === 401) {
                auth.logout()
            } else {
                setShowAlert(true);
                setMessage({ error: true, msg: "An error has occurred! try again" });
            }
        }
    }
    //back button
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='d-flex justify-content-start mb-3'>
                        <Link onClick={goBack} type="button" className="btn btn-outline-dark btn-sm"> <i className="fa-solid fa-chevron-left"></i> Back</Link>
                    </div>

                    <div>
                        {message ?
                            <Alert show={showAlert} variant={`${message.error ? 'danger' : 'success'}`}>
                                <p className='alert-message'>{message.msg}</p>
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Button onClick={() => setShowAlert(false)} size="sm" variant={`outline-${message.error ? 'danger' : 'success'}`}>
                                        Close
                                    </Button>
                                </div>
                            </Alert>
                            : null
                        }
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-12 col-lg-4 mb-3'>
                    </div>

                    {arrContact.map((item, i) => {
                        const fecha = new Date(item.conCreateAt);
                        const opcionesFecha = { day: 'numeric', month: 'long', year: 'numeric' };
                        const fechaFormateada = fecha.toLocaleString('es-ES', opcionesFecha);
                        return (
                            <>
                                <div className='text-center'>
                                    <h1>Form data #{item.conId}</h1>
                                </div>

                                <div className='justify-content-around'>
                                    <p><span className="theme-color">Name:</span> {item.conName}</p>
                                    <p><span className="theme-color">Email:</span> {item.conEmail}</p>
                                    <p><span className="theme-color">Phone:</span> {item.conPhone}</p>
                                    <p><span className="theme-color">Subject:</span> {item.conName}</p>
                                    <p><span className="theme-color">Message:</span> <div dangerouslySetInnerHTML={{ __html: item.conMessage }} /></p>
                                    <br/>
                                    <p><span className="theme-color">Created At:</span> {fechaFormateada}</p>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
export { ViewContactDashboard }