import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

//contact
import { FormContact } from "../form-contact";

function ProductSupportAndMaintenance() {
  //traslate
  const { t, i18n } = useTranslation("global");
  const video =
    "https://charlieapps.b-cdn.net/cdn/videos/Video-prueba-Product-Support.mp4";

  const shape = require("../../../assets/img/shape/3.webp");
  const Img = require("../../../assets/img/Product-Support-and-Maintenance-Services/Img.png");

  // const  = require('../../../');
  const psamI = require("../../../assets/img/Product-Support-and-Maintenance-Services/Ico-1.png");
  const psamI2 = require("../../../assets/img/Product-Support-and-Maintenance-Services/Ico-2.png");
  const psamI3 = require("../../../assets/img/Product-Support-and-Maintenance-Services/Ico-3.png");

  //const video  = require('../../../assets/img/video/Video prueba Product Support.mp4');

  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    document.title = "Product Support And Maintenance";
    window.scrollTo(0, 0);
  }, []);
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    <>
      <div id="psm-slider" className="slider-area height-100 bg-img-24 fix">
        <div className="container pt-100">
          <div className="psm-title-div pt-100">
            <h1>{t("productSupport.title")}</h1>
            <p className="psm-hide">{t("productSupport.subTitle")}</p>
          </div>
          <div className="RSbutton-div text-center">
            <Link
              to={"/contact"}
              className="btn btn-primary RSBtn psm-title-button"
            >
              {" "}
              Request Service <i className="fa fa-angle-right"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="psm-main-img-div psm-box">
        <img
          loading="lazy"
          src={Img}
          alt="charlieapps charlie apps product support and maintenance"
        />
      </div>
      <div className="psm-info-div row">
        <div className="psm-item col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div className="psm-item-image"></div>
          <div className="psm-item-text web-item-text">
            <hr className="d-none d-lg-block" />
            <img
              loading="lazy"
              src={psamI}
              alt="charlieapps charlie apps 24/7"
            />
            <h3 className="mb-30">{t("productSupport.support")}</h3>
            <p className="mb-30">{t("productSupport.supporttext")}</p>
          </div>
        </div>
        <div className="psm-item col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div className="psm-item-image"></div>
          <div className="psm-item-text web-item-text">
            <hr className="d-none d-lg-block" />
            <img
              loading="lazy"
              src={psamI2}
              alt="charlieapps charlie apps system updates"
            />
            <h3 className="mb-30">{t("productSupport.SystemsUpdates")}</h3>
            <p className="mb-65">{t("productSupport.SystemsUpdatesText")}</p>
          </div>
        </div>

        <div className="psm-item col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div className="psm-item-image"></div>
          <div className="psm-item-tex web-item-text">
            <hr className="d-none d-lg-block psm-item-image-hr" />
            <img
              loading="lazy"
              src={psamI3}
              alt="charlieapps charlie apps performance optimization"
            />
            <h3 className="mb-30">
              {t("productSupport.PerformanceOptimization")}
            </h3>
            <p>{t("productSupport.PerformanceOptimizationText")}</p>
          </div>
        </div>
      </div>

      <div className="psm-mid-div">
        <h3 className="theme-color">
          {t("productSupport.TechnicalConsulting")}
        </h3>
        <p>{t("productSupport.TechnicalConsultingText")}</p>
        <div>
          <video className="psm-video" controls>
            <source src={video} type="video/mp4" />
            {t("productSupport.browser")}
          </video>
        </div>
      </div>
      <div className="contact-form-area section-pt pb-50 gray-bg psm-bottom-contact">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <div className="heading mb-85">
                <h3 className="theme-color">
                  {" "}
                  {t("productSupport.TellAbout")}
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="contact-form text-center">
                <div id="contact-response" className="mb-15 alert"></div>
                <div className="loader">
                  <i className="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <FormContact />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="psm-bottom-div">
        <div className="RSbutton-div text-center call-me-button">
          <a href="tel:+1855-527-7932">
            <button id="psm-rsbutton-bottom" className="btn btn-primary RSBtn ">
              {" "}
              Call Us <i className="fa fa-phone"></i>
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
export { ProductSupportAndMaintenance };
