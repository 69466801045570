import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

//traslate
import { useTranslation, Trans } from "react-i18next";

function Services() {
  //traslate
  const { t, i18n } = useTranslation("global");
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const shape = require("../../assets/img/shape/3.webp");
  const app_development = require("../../assets/img/service/Services/App-Development.png");
  const web_development = require("../../assets/img/service/Services/Web-Development.png");
  const product_maintenance = require("../../assets/img/service/Services/Technical-Product-Maintenance.png");
  const ui_interface = require("../../assets/img/service/Services/UI-User-Interface-Design.png");
  const ux_experience = require("../../assets/img/service/Services/UX-User-Experience-Design.png");
  const TechnicalProductResearch = require("../../assets/img/service/Services/Technical-Product-Research.png");
  const Branding = require("../../assets/img/service/Services/Branding.png");
  const DigitalMarketing = require("../../assets/img/service/Services/Digital-Marketing-And-Strategy.png");

  const discovery = require("../../assets/img/service/discovery.png");
  const blueprint = require("../../assets/img/service/blueprint.png");
  const design = require("../../assets/img/service/design.png");
  const development_service = require("../../assets/img/service/development_service.png");
  const circles = require("../../assets/img/service/circles.png");

  const about06 = require("../../assets/img/about/06.webp");
  const enterprise_solucionsBG = require("../../assets/img/service/enterprise.png");
  const systems_and_cloud_integrationBG = require("../../assets/img/service/systemscloud.png");
  const security_consultingBG = require("../../assets/img/service/software security.png");
  const backend_and_api_developmentBG = require("../../assets/img/service/backend.png");

  const enterprise_solucions = require("../../assets/img/service/enterprise_solucions.png");
  const systems_and_cloud_integration = require("../../assets/img/service/systems_and_cloud_integration.png");
  const security_consulting = require("../../assets/img/service/security_consulting.png");
  const backend_and_api_development = require("../../assets/img/service/backend_and_api_development.png");

  const topService2 = require("../../assets/img/service/top-service2.png");
  const charlieLg = require("../../assets/img/service/charlielg.png");

  const handleResize = () => {
    setIsMobile(window.innerWidth < 992);
  };

  useEffect(() => {
    document.title = "services";

    AOS.init();
    window.addEventListener("resize", handleResize);
    const handleScroll = () => {
      const overlay = document.querySelector(".overlay");
      if (!overlay) return; // Asegura que overlay no sea null

      const imageContainer = document.querySelector(".image-container");
      const imageContainerTop = imageContainer.offsetTop;
      const imageContainerHeight = imageContainer.clientHeight;
      const windowScroll = window.scrollY;

      const scrollPercentage = Math.min(
        (windowScroll - imageContainerTop) / imageContainerHeight,
        1
      );
      overlay.style.backgroundColor = `rgba(0, 0, 255, ${scrollPercentage})`;
    };

    window.addEventListener("scroll", handleScroll);

    // Llama a handleScroll inicialmente para establecer el color correcto
    handleScroll();

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const onMouseEnterServices = (event) => {
    if (event === "1") {
      setIsHovered1(true);
      setIsHovered2(false);
      setIsHovered3(false);
      setIsHovered4(false);
    } else if (event === "2") {
      setIsHovered2(true);
      setIsHovered1(false);
      setIsHovered3(false);
      setIsHovered4(false);
    } else if (event === "3") {
      setIsHovered3(true);
      setIsHovered1(false);
      setIsHovered2(false);
      setIsHovered4(false);
    } else if (event === "4") {
      setIsHovered4(true);
      setIsHovered1(false);
      setIsHovered2(false);
      setIsHovered3(false);
    }
  };

  const onMouseLeaveServices = () => {
    setIsHovered1(false);
    setIsHovered2(false);
    setIsHovered3(false);
    setIsHovered4(false);
  };

  function truncateDescription(text, maxWords) {
    const words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    } else {
      return text;
    }
  }
  const MAX_DESCRIPTION_WORDS = 16;

  const services = [
    {
      title: t("services.AppDevelopment"),
      img: app_development,
      description: truncateDescription(
        t("services.AppDevelopmentText"),
        MAX_DESCRIPTION_WORDS
      ),
      link: "/app-development",
      alt: "services charlie apps app development",
    },
    {
      title: t("services.WebDevelopment"),
      img: web_development,
      description: truncateDescription(
        t("services.WebDevelopmentText"),
        MAX_DESCRIPTION_WORDS
      ),
      link: "/web-development",
      alt: "services charlie apps web development",
    },
    {
      title: t("services.ProductSupportandMaintenance"),
      img: product_maintenance,
      description: truncateDescription(
        t("services.ProductSupportandMaintenanceText"),
        MAX_DESCRIPTION_WORDS
      ),

      link: "/Product-Support-Maintenance",
      alt: "services charlie apps  product support and maintenance",
    },
    {
      title: t("services.UIDesign"),
      img: ui_interface,
      description: truncateDescription(
        t("services.UIDesignText"),
        MAX_DESCRIPTION_WORDS
      ),

      link: "/ui-design",
      alt: "services charlie apps ui design",
    },
    {
      title: t("services.UXDesign"),
      img: ux_experience,
      description: truncateDescription(
        t("services.UXDesignText"),
        MAX_DESCRIPTION_WORDS
      ),

      link: "/ux-design",
      alt: "services charlie apps ux design",
    },
    {
      title: t("services.TechnicalProductReasearch"),
      img: TechnicalProductResearch,
      description: truncateDescription(
        t("services.TechnicalProductReasearchText"),
        MAX_DESCRIPTION_WORDS
      ),
      link: "/technical-product-research",
      alt: "services charlie apps technical product research",
    },
    {
      title: t("services.Branding"),
      img: Branding,
      description: truncateDescription(
        t("services.BrandingText"),
        MAX_DESCRIPTION_WORDS
      ),
      link: "/branding",
      alt: "services charlie apps branding",
    },
    {
      title: t("services.DigitalMarketingandStrategy"),
      img: DigitalMarketing,
      description: truncateDescription(
        t("services.DigitalMarketingandStrategyText"),
        MAX_DESCRIPTION_WORDS
      ),
      link: "/marketing-and-strategy",
      alt: "services charlie apps digital marketing and strategy",
    },
  ];
  //console.log(services)
  return (
    <>
      <section className="top-service-padding white-bg featured-area white-bg top-service-padding ">
        <div className="container">
          <div className="col-md-12 text-center">
            <div className="services-top-shape ">
              <img loading="lazy" src={shape} alt="services charlie apps" />
            </div>
            <div className="services-title">
              <h3 className="theme-color">{t("services.subTitle")}</h3>
              <h1>{t("services.title")}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="new-cards-services">
        <div className="container pd-box">
          <div className="row">
            {services.map((item, i) => {
              return (
                <div className="col-12 col-sm-12 col-md-6 col-lg-4 " key={i}>
                  <NavLink to={item.link}>
                    <div className="box rounded">
                      <div className="image-container">
                        <img
                          loading="lazy"
                          className="img-fluid"
                          src={item.img}
                          alt={item.alt}
                        />
                      </div>

                      <div className="description">
                        <h5>{item.title}</h5>
                        <hr />
                        <p>{item.description}</p>
                        <NavLink to={item.link}>
                          {t("services.SeeMore")}
                        </NavLink>
                      </div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="our-process mt-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="services-top-shape our-process-shape ">
                <img loading="lazy" src={shape} alt="charlie apps services" />
              </div>
              <div className="services-title process-title ">
                <h3 className="theme-color>">
                  {t("services.processSubTitle")}
                </h3>
                <h1>{t("services.processTitle")}</h1>
              </div>
            </div>
          </div>

          <div className="row process-service">
            <div
              className=" col-sm-12 col-md-5 col-lg-2  text-center single-service"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="500"
            >
              <div className="relative">
                <img
                  loading="lazy"
                  src={discovery}
                  alt="charlie apps services concepting and strategy"
                />
                <h4>{t("services.Discovery")}</h4>
              </div>
            </div>
            <div
              className="col-md-1  d-none d-lg-block text-center single-service-cicle d-flex align-items-center justify-content-center "
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="500"
            >
              <img
                loading="lazy"
                src={circles}
                alt="charlie apps services concepting and strategy"
              />
            </div>
            <div
              className=" col-sm-12 col-md-5 col-lg-2 text-center single-service"
              data-aos="fade-up"
              data-aos-delay="400"
              data-aos-duration="500"
            >
              <div className="relative">
                <img
                  loading="lazy"
                  src={blueprint}
                  alt="charlie apps services concepting and strategy"
                />
                <h4>{t("services.BluePrinting")}</h4>
              </div>
            </div>
            <div
              className="col-md-1 d-none d-lg-block text-center single-service-cicle d-flex align-items-center justify-content-center "
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="500"
            >
              <img
                loading="lazy"
                src={circles}
                alt="charlie apps services concepting and strategy"
              />
            </div>
            <div
              className="col-sm-12 col-md-5 col-lg-2 text-center single-service"
              data-aos="fade-up"
              data-aos-delay="600"
              data-aos-duration="500"
            >
              <div className="relative">
                <img
                  loading="lazy"
                  src={design}
                  alt="charlie apps services concepting and strategy"
                />
                <h4>{t("services.Design")}</h4>
              </div>
            </div>
            <div
              className="col-md-1  d-none d-lg-block text-center single-service-cicle d-flex align-items-center justify-content-center"
              data-aos="fade-up"
              data-aos-delay="700"
              data-aos-duration="500"
            >
              <img
                loading="lazy"
                src={circles}
                alt="charlie apps services concepting and strategy"
              />
            </div>
            <div
              className="col-sm-12 col-md-5 col-lg-2  text-center single-service"
              data-aos="fade-up"
              data-aos-delay="800"
              data-aos-duration="500"
            >
              <div className="relative">
                <img
                  loading="lazy"
                  src={development_service}
                  alt="charlie apps services concepting and strategy"
                />
                <h4>{t("services.Development")}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="best-service-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="shape ">
                <img loading="lazy" src={shape} alt="charlie apps services " />
              </div>
              <div className="shape-title ">
                <h1>{t("services.GetMoreUithUs")}</h1>
              </div>
              <div className="custom-tab">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="freeapps-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#freeapps"
                      type="button"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      {t("services.FreeApps")}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="getsocial-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#getsocial"
                      type="button"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      {t("services.GetSocial")}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="customer-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#customer"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      {t("services.Customer")}{" "}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="usability-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#usability"
                      type="button"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      {t("services.GreatUsabiliti")}{" "}
                    </button>
                  </li>
                </ul>
                <div className="tab-content mt-3" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="freeapps"
                    role="tabpanel"
                    aria-labelledby="freeapps-tab"
                  >
                    <p>{t("services.FreeAppsText")}</p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="getsocial"
                    role="tabpanel"
                    aria-labelledby="getsocial-tab"
                  >
                    <p>{t("services.GetSocialText")}</p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="customer"
                    role="tabpanel"
                    aria-labelledby="customer-tab"
                  >
                    <p>{t("services.CustomerText")}</p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="usability"
                    role="tabpanel"
                    aria-labelledby="usability-tab"
                  >
                    <p>{t("services.GreatUsabilitiT")}</p>
                  </div>
                </div>
                <Link to={"/contact"} className="btn rt-btn2 mt-4">
                  {t("services.getInTouch")}
                </Link>
              </div>
            </div>
            <div className="col-md-6 tab-container-img">
              <img
                loading="lazy"
                className="img06 img-fluid"
                src={about06}
                alt="charlie apps services "
              />
            </div>
          </div>
        </div>
      </section>
      <section className="services-new">
        <div>
          {isMobile ? (
            <div>
              <div className="row ">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 services">
                  <div className="row">
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 box "
                      onMouseEnter={() => onMouseEnterServices("1")}
                      onMouseLeave={onMouseLeaveServices}
                      onTouchStart={() => onMouseEnterServices("1")}
                    >
                      <img
                        className="opacity-services"
                        src={enterprise_solucionsBG}
                        alt="charlie apps services enterprise solutions"
                      />
                      {isHovered1 ? (
                        <div className="title-hover">
                          <img
                            loading="lazy"
                            src={enterprise_solucions}
                            alt="charlie apps services enterprise solutions"
                          />
                          <h6>{t("services.EnterpriseSolutions")}</h6>
                          <p className="description-hover">
                            {t("services.EnterpriseSolutionsText")}
                          </p>
                          <NavLink to={"enteripe-solutions"}>
                            {t("services.SeeMore")}
                          </NavLink>
                        </div>
                      ) : (
                        <div className="title">
                          <img
                            loading="lazy"
                            src={enterprise_solucions}
                            alt="charlie apps services enterprise solutions"
                          />
                          <h6>{t("services.EnterpriseSolutions")}</h6>
                        </div>
                      )}
                    </div>
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 box"
                      onMouseEnter={() => onMouseEnterServices("2")}
                      onMouseLeave={onMouseLeaveServices}
                      onTouchStart={() => onMouseEnterServices("2")}
                    >
                      <img
                        className="opacity-services"
                        src={security_consultingBG}
                        alt="charlie apps services software consulting"
                      />
                      {isHovered2 ? (
                        <div className="title-hover">
                          <img
                            loading="lazy"
                            src={security_consulting}
                            alt="charlie apps services software consulting"
                          />
                          <h6>{t("services.SoftwareSecurityConsulting")}</h6>
                          <p className="description-hover">
                            {t("services.SoftwareSecurityConsultingText")}
                          </p>
                          <NavLink to={"software-security-consulting"}>
                            {t("services.SeeMore")}
                          </NavLink>
                        </div>
                      ) : (
                        <div className="title">
                          <img
                            loading="lazy"
                            src={security_consulting}
                            alt="charlie apps services software consulting"
                          />
                          <h6>{t("services.SoftwareSecurityConsulting")}</h6>
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 description">
                      <img
                        loading="lazy"
                        src={charlieLg}
                        alt="charlie apps services software consulting"
                      />
                      <p>{t("services.feoe")}</p>
                      <h3>{t("services.feoeText")}</h3>
                    </div>
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 box"
                      onMouseEnter={() => onMouseEnterServices("3")}
                      onMouseLeave={onMouseLeaveServices}
                      onTouchStart={() => onMouseEnterServices("3")}
                    >
                      <img
                        className="opacity-services"
                        src={systems_and_cloud_integrationBG}
                      />
                      {isHovered3 ? (
                        <div className="title-hover">
                          <img
                            loading="lazy"
                            src={systems_and_cloud_integration}
                            alt="charlie apps services system and cloud integration"
                          />
                          <h6>{t("services.SystemsCloudIntegration")}</h6>
                          <p className="description-hover">
                            {t("services.SystemsCloudIntegrationText")}
                          </p>
                          <NavLink to={"system-cloud-integration"}>
                            {t("services.SeeMore")}
                          </NavLink>
                        </div>
                      ) : (
                        <div className="title">
                          <img
                            loading="lazy"
                            src={systems_and_cloud_integration}
                            alt="charlie apps services system and cloud integration"
                          />
                          <h6>{t("services.SystemsCloudIntegration")}</h6>
                        </div>
                      )}
                    </div>
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 box"
                      onMouseEnter={() => onMouseEnterServices("4")}
                      onMouseLeave={onMouseLeaveServices}
                      onTouchStart={() => onMouseEnterServices("4")}
                    >
                      <img
                        className="opacity-services"
                        src={backend_and_api_developmentBG}
                        alt="charlie apps services backend and api development"
                      />
                      {isHovered4 ? (
                        <div className="title-hover">
                          <img
                            loading="lazy"
                            src={backend_and_api_development}
                            alt="charlie apps services backend and api development"
                          />
                          <h6>{t("services.BackendAPIDevelopment")}</h6>
                          <p className="description-hover">
                            {t("services.BackendAPIDevelopmentText")}
                          </p>
                          <NavLink to={"backend-api-development"}>
                            {t("services.SeeMore")}
                          </NavLink>
                        </div>
                      ) : (
                        <div className="title">
                          <img
                            loading="lazy"
                            src={backend_and_api_development}
                            alt="charlie apps services backend and api development"
                          />
                          <h6>{t("services.BackendAPIDevelopment")}</h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <img loading="lazy" className="top" src={topService2} />
              <div className="row ">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 description">
                  <img
                    loading="lazy"
                    src={charlieLg}
                    alt="charlie apps services From enhancing operational efficiency to securing your digital frontiers."
                  />
                  <p>{t("services.feoe")}</p>
                  <h3>{t("services.feoeText")}</h3>
                </div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-8 services">
                  <div className="row">
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 box "
                      onMouseEnter={() => onMouseEnterServices("1")}
                      onMouseLeave={onMouseLeaveServices}
                      onTouchStart={() => onMouseEnterServices("1")}
                    >
                      <img
                        className="opacity-services"
                        src={enterprise_solucionsBG}
                        alt="charlie apps services enterprise solutions"
                      />
                      {isHovered1 ? (
                        <div className="title-hover">
                          <img
                            loading="lazy"
                            src={enterprise_solucions}
                            alt="charlie apps services enterprise solutions"
                          />
                          <h6>{t("services.EnterpriseSolutions")}</h6>
                          <p className="description-hover">
                            {t("services.EnterpriseSolutionsText")}
                          </p>
                          <NavLink to={"enteripe-solutions"}>
                            {t("services.SeeMore")}
                          </NavLink>
                        </div>
                      ) : (
                        <div className="title">
                          <img
                            loading="lazy"
                            src={enterprise_solucions}
                            alt="charlie apps services enterprise solutions"
                          />
                          <h6>{t("services.EnterpriseSolutions")}</h6>
                        </div>
                      )}
                    </div>
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 box"
                      onMouseEnter={() => onMouseEnterServices("2")}
                      onMouseLeave={onMouseLeaveServices}
                      onTouchStart={() => onMouseEnterServices("2")}
                    >
                      <img
                        className="opacity-services"
                        src={security_consultingBG}
                        alt="charlie apps services software security consulting"
                      />
                      {isHovered2 ? (
                        <div className="title-hover">
                          <img
                            loading="lazy"
                            src={security_consulting}
                            alt="charlie apps services software security consulting"
                          />
                          <h6>{t("services.SoftwareSecurityConsulting")}</h6>
                          <p className="description-hover">
                            {t("services.SoftwareSecurityConsultingText")}
                          </p>
                          <NavLink to={"software-security-consulting"}>
                            {t("services.SeeMore")}
                          </NavLink>
                        </div>
                      ) : (
                        <div className="title">
                          <img
                            loading="lazy"
                            src={security_consulting}
                            alt="charlie apps services software security consulting"
                          />
                          <h6>{t("services.SoftwareSecurityConsulting")}</h6>
                        </div>
                      )}
                    </div>
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 box"
                      onMouseEnter={() => onMouseEnterServices("3")}
                      onMouseLeave={onMouseLeaveServices}
                      onTouchStart={() => onMouseEnterServices("3")}
                    >
                      <img
                        className="opacity-services"
                        src={systems_and_cloud_integrationBG}
                        alt="charlie apps services system and cloud integrations"
                      />
                      {isHovered3 ? (
                        <div className="title-hover">
                          <img
                            loading="lazy"
                            src={systems_and_cloud_integration}
                            alt="charlie apps services system and cloud integrations"
                          />
                          <h6>{t("services.SystemsCloudIntegration")}</h6>
                          <p className="description-hover">
                            {t("services.SystemsCloudIntegrationText")}
                          </p>
                          <NavLink to={"system-cloud-integration"}>
                            {t("services.SeeMore")}
                          </NavLink>
                        </div>
                      ) : (
                        <div className="title">
                          <img
                            loading="lazy"
                            src={systems_and_cloud_integration}
                            alt="charlie apps services system and cloud integrations"
                          />
                          <h6>{t("services.SystemsCloudIntegration")}</h6>
                        </div>
                      )}
                    </div>
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 box"
                      onMouseEnter={() => onMouseEnterServices("4")}
                      onMouseLeave={onMouseLeaveServices}
                      onTouchStart={() => onMouseEnterServices("4")}
                    >
                      <img
                        className="opacity-services"
                        src={backend_and_api_developmentBG}
                        alt="charlie apps services backend and api development"
                      />
                      {isHovered4 ? (
                        <div className="title-hover">
                          <img
                            loading="lazy"
                            src={backend_and_api_development}
                            alt="charlie apps services backend and api development"
                          />
                          <h6>{t("services.BackendAPIDevelopment")}</h6>
                          <p className="description-hover">
                            {t("services.BackendAPIDevelopmentText")}
                          </p>
                          <NavLink to={"backend-api-development"}>
                            {t("services.SeeMore")}
                          </NavLink>
                        </div>
                      ) : (
                        <div className="title">
                          <img
                            loading="lazy"
                            src={backend_and_api_development}
                            alt="charlie apps services backend and api development"
                          />
                          <h6>{t("services.BackendAPIDevelopment")}</h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <section className="section-padding-service white-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center servces-start">
              <h1
                dangerouslySetInnerHTML={{ __html: t("services.HaveIdea") }}
              ></h1>
              <Link to={"/contact"} className="btn rt-btn2 mt-4">
                {t("services.StartProject")}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export { Services };
