import React, { useState, useEffect } from "react";
import {
  Link,
  NavLink,
  parsePath,
  useNavigate,
  useParams,
} from "react-router-dom";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

import { useAuth } from "../../../../context/AuthProvider";
//user service
import blogService from "../../../../services/blog.service";

//editor
import Editor from "./editor";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//validation form
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

function EditBlog() {
  //validation auth
  const auth = useAuth();

  //get url params
  const idBlog = useParams();

  const logo = require("../../../../assets/img/logo.png");

  //avatar
  const [photo, setPhoto] = useState(null);

  const [imageBlog, setimageBlog] = useState("");

  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    onGetBlog();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  //text editor
  const [editorContent, setEditorContent] = useState("");

  const [editorInitial, setEditorInitial] = useState("");

  //data array blog
  const [arrBlog, setArrBlog] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  //get languages
  const [languages, setLanguages] = useState("EN");
  const [btnLanguages, setBtnLanguages] = useState("");

  //load blog
  useEffect(() => {
    onGetBlog();
  }, [btnLanguages, editorInitial]);

  const handleBtnLG = (language) => {
    setBtnLanguages(language);
  };

  const onGetBlog = async () => {
    let getBlogLgId = "";
    setIsLoading(true);
    switch (btnLanguages) {
      case "EN":
        setBtnLanguages("EN");
        onGetBlogAll();
        break;
      case "ES":
        getBlogLgId = idBlog.id;
        onLanguages(getBlogLgId, "ES");
        break;
      case "FR":
        getBlogLgId = idBlog.id;
        onLanguages(getBlogLgId, "FR");
        break;
      case "IT":
        getBlogLgId = idBlog.id;
        onLanguages(getBlogLgId, "IT");
        break;
      case "DE":
        getBlogLgId = idBlog.id;
        onLanguages(getBlogLgId, "DE");
        break;
      default:
        setBtnLanguages("EN");
        onGetBlogAll();
        break;
    }
  };

  const onGetBlogAll = async () => {
    try {
      let bearer_Token = {
        headers: {
          Authorization: `Bearer ${auth.authToken()}`,
        },
      };
      const getBlogs = await blogService.getBlogById(idBlog.id, bearer_Token);
      if (getBlogs.status === 200 && getBlogs.data.statusCode === 404) {
        setShowAlert(true);
        setMessage({ error: true, msg: "There is no associated information" });
      } else if (getBlogs.status === 200) {
        setArrBlog(getBlogs.data[0]);
        setimageBlog(getBlogs.data[0].bloImage);

        // Convert date string to a JavaScript date object
        let date = new Date(
          getBlogs.data[0].bloCreateAt ? getBlogs.data[0].bloCreateAt : ""
        );

        // Get date components separately
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");

        // Build the string in the format "YYYY-MM-DD
        let formattedDate = `${year}-${month}-${day}`;

        formik.setValues({
          title: getBlogs.data[0].bloTitle ? getBlogs.data[0].bloTitle : "",
          description: getBlogs.data[0].bloDescription
            ? getBlogs.data[0].bloDescription
            : "",
          create_at: formattedDate ? formattedDate : "",
        });
        setEditorInitial(getBlogs.data[0].bloData);
        setIsLoading(false); // Marcar que los datos se han cargado correctamente
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      if (error.response.status === 401) {
        auth.logout();
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    }
  };

  const onLanguages = async (id, language) => {
    try {
      let bearer_Token = {
        headers: {
          Authorization: `Bearer ${auth.authToken()}`,
        },
      };
      const data = {
        language: language,
        idParent: id,
      };
      const getBlogs = await blogService.getBlogByIdLg(id, data, bearer_Token);

      if (getBlogs.status === 200 && getBlogs.data.statusCode === 404) {
        setShowAlert(true);
        setMessage({ error: true, msg: "There is no associated information" });
      } else if (getBlogs.status === 200) {
        setArrBlog(getBlogs.data[0]);
        setimageBlog(getBlogs.data[0].bloImage);

        // Convert date string to a JavaScript date object
        let date = new Date(
          getBlogs.data[0].bloCreateAt ? getBlogs.data[0].bloCreateAt : ""
        );

        // Get date components separately
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");

        // Build the string in the format "YYYY-MM-DD
        let formattedDate = `${year}-${month}-${day}`;

        formik.setValues({
          title: getBlogs.data[0].bloTitle ? getBlogs.data[0].bloTitle : "",
          description: getBlogs.data[0].bloDescription
            ? getBlogs.data[0].bloDescription
            : "",
          create_at: formattedDate ? formattedDate : "",
        });
        setEditorInitial(getBlogs.data[0].bloData);
        setIsLoading(false); // Marcar que los datos se han cargado correctamente
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      if (error.response.status === 401) {
        auth.logout();
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    }
  };

  //imaghe blog
  const handlePhotoSelect = (event) => {
    const file = event.target.files[0];
    let error = false;
    if (file && !/^(image\/jpeg|image\/png)$/.test(file.type)) {
      setPhoto("");
      error = true;
      setMessage({ error: true, msg: "The file must be of type PNG or JPG" });
      return;
    }
    const maxSize = 1 * 1024 * 1024; // 5 MB
    if (file && file.size > maxSize) {
      setPhoto("");
      error = true;
      setMessage({
        error: true,
        msg: `The file must be less than or equal to  ${
          maxSize / 1024 / 1024
        } MB`,
      });
      return;
    }
    setPhoto(event.target.files[0]);

    if (error === false) {
      handleSubmitAvatar(file);
    }
  };
  const handleSubmitAvatar = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    if (file) {
      let bearer_Token = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.authToken()}`,
        },
      };
      try {
        const uploadImage = await blogService.createBlogImages(
          formData,
          bearer_Token
        );
        console.log(uploadImage);
        if (uploadImage.status === 200 && uploadImage.data.statusCode === 201) {
          //resolve(uploadImage.data.imaUrl);
          setimageBlog(uploadImage.data.imaUrl);
        } else {
          setShowAlert(true);
          setMessage({ error: true, msg: "An error has occurred! try again" });
        }
      } catch (error) {
        if (error.response.status === 401) {
          auth.logout();
        } else {
          setShowAlert(true);
          setMessage({ error: true, msg: "An error has occurred! try again" });
        }
      }
    }
  };

  //text editor and formmik send data
  const handleEditorContentChange = (content) => {
    setEditorContent(content);
  };
  const handleSubmit = async (values) => {
    // console.log(values);
    const data = {
      title: values.title,
      description: values.description,
      image: imageBlog ? imageBlog : "image",
      editorContent: editorContent ? editorContent : editorInitial,
      create_at: values.create_at,
    };
    let idBlog = arrBlog.bloId;
    // console.log(idBlog);
    // console.log(data);
    try {
      let bearer_Token = {
        headers: {
          Authorization: `Bearer ${auth.authToken()}`,
        },
      };
      const updateBlog = await blogService.updateBlog(
        idBlog,
        data,
        bearer_Token
      );
      // console.log(updateBlog)
      if (updateBlog.status === 200 && updateBlog.data.statusCode === 200) {
        //create blog
        setShow(true);
      }
      if (updateBlog.status === 200 && updateBlog.data.statusCode === 400) {
        //error
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    } catch (error) {
      if (error.response.status === 401) {
        auth.logout();
      } else {
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! try again" });
      }
    }
  };
  //formik
  const formik = useFormik({
    initialValues: { title: "", description: "", create_at: "" },
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  //back button
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="d-flex justify-content-start mb-3">
            <Link
              onClick={goBack}
              type="button"
              className="btn btn-outline-dark btn-sm"
            >
              {" "}
              <i className="fa-solid fa-chevron-left"></i> Back
            </Link>
          </div>
          <h3>Edit Blog</h3>
          <div className="mb-5">
            <Link
              onClick={() => handleBtnLG("EN")}
              type="button"
              className={`btn btn-primary btn-sm  ${
                btnLanguages === "EN" ? "btn-cms-active" : ""
              }`}
            >
              {" "}
              <i className="fa-solid fa-language"></i> EN
            </Link>
            <Link
              onClick={() => handleBtnLG("ES")}
              type="button"
              className={`btn btn-primary btn-sm  ${
                btnLanguages === "ES" ? "btn-cms-active" : ""
              }`}
            >
              {" "}
              <i className="fa-solid fa-language"></i> ES
            </Link>
            <Link
              onClick={() => handleBtnLG("FR")}
              type="button"
              className={`btn btn-primary btn-sm  ${
                btnLanguages === "FR" ? "btn-cms-active" : ""
              }`}
            >
              {" "}
              <i className="fa-solid fa-language"></i> FR
            </Link>
            <Link
              onClick={() => handleBtnLG("IT")}
              type="button"
              className={`btn btn-primary btn-sm  ${
                btnLanguages === "IT" ? "btn-cms-active" : ""
              }`}
            >
              {" "}
              <i className="fa-solid fa-language"></i> IT
            </Link>
            <Link
              onClick={() => handleBtnLG("DE")}
              type="button"
              className={`btn btn-primary btn-sm  ${
                btnLanguages === "DE" ? "btn-cms-active" : ""
              }`}
            >
              {" "}
              <i className="fa-solid fa-language"></i> DE
            </Link>
          </div>
          {message ? (
            <Alert
              show={showAlert}
              variant={`${message.error ? "danger" : "success"}`}
            >
              <p className="alert-message">{message.msg}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => setShowAlert(false)}
                  size="sm"
                  variant={`outline-${message.error ? "danger" : "success"}`}
                >
                  Close
                </Button>
              </div>
            </Alert>
          ) : null}

          <form onSubmit={formik.handleSubmit}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="mb-3 ">
                <label className="form-label">Title</label>
                <input
                  name="title"
                  type="text"
                  placeholder="Enter Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.title && formik.touched.title && (
                  <label className="text-danger">{formik.errors.title}</label>
                )}
              </div>
              <div className="mb-3 ">
                <label className="form-label">Description</label>
                <textarea
                  name="description"
                  type="text"
                  placeholder="Enter Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.description && formik.touched.description && (
                  <label className="text-danger">
                    {formik.errors.description}
                  </label>
                )}
              </div>
              <div className="mb-3 col-sm-4 ">
                <label className="form-label">Created At</label>
                <input
                  name="create_at"
                  type="date"
                  defaultValue={formik.values.create_at}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.create_at && formik.touched.create_at && (
                  <label className="text-danger">
                    {formik.errors.create_at}
                  </label>
                )}
              </div>
              <div className="row mb-3">
                <div className="col-lg-6  col-md-12 col-sm-12 col-xs-12">
                  <img
                    loading="lazy"
                    src={imageBlog ? imageBlog : logo}
                    className="w-50 avatar img-circle img-thumbnail"
                    alt="item"
                  />
                  <div className="form-text">Image Item.</div>
                </div>
                <div className="col-lg-9  col-md-12 col-sm-12 col-xs-12">
                  <input
                    id="item_image"
                    onChange={handlePhotoSelect}
                    name="item_image"
                    className="form-control form-control-sm mt-3"
                    type="file"
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 border">
              {!isLoading && (
                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 border">
                  <Editor
                    onEditorContentChange={handleEditorContentChange}
                    initialContent={editorInitial}
                  />
                </div>
              )}
            </div>
            <button
              disabled={!formik.isValid}
              className="w-100 btn btn-lg primary-color-bg mt-3"
              type="submit"
            >
              Save
            </button>
          </form>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className=" modal-login"
      >
        <div className="modal-content content">
          <div className="modal-body">
            <p className="text-center"> Blog has been Update!.</p>
          </div>
          <div className="modal-footer footer-login-modal  d-md-block  text-center">
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-sm btn-send "
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
export { EditBlog };
