import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

//traslate
import { useTranslation, Trans } from "react-i18next";

function PrivacyPolicy() {
  //traslate
  const { t, i18n } = useTranslation("global");

  useEffect(() => {
    document.title = "Privacy Policy";

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="container terms-conditions">
        <header className="bg-dark text-white text-center py-5">
          <h1 className="">{t("privacy.privacy")}</h1>
        </header>
        <div className="row">
          <div className="content">
            <h2>{t("privacy.introduction")}</h2>
            <p>{t("privacy.introductionText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.whatDataTitle")}</h2>
            <h2>{t("privacy.whatSubDataTitle")}</h2>
            <p>{t("privacy.whatDataText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.location")}</h2>
            <p>{t("privacy.locationText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.HowWeUseData")}</h2>
            <p>{t("privacy.HowWeUseDataText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.WhoWeShareDataWith")}</h2>
            <p>{t("privacy.WhoWeShareDataWithText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.SecurityMeasures")}</h2>
            <p>{t("privacy.SecurityMeasuresText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.Cookies")}</h2>
            <p>{t("privacy.CookiesText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.YourRights")}</h2>
            <p>{t("privacy.YourRightsText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.ChangestoThisPolicy")}</h2>
            <p>{t("privacy.ChangestoThisPolicyText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.ContactUs")}</h2>
            <p>{t("privacy.ContactUsText")}</p>
            <ul>
              <li>{t("privacy.email")}</li>
              <li>{t("privacy.postal")}</li>
              <li>{t("privacy.phone")}</li>
            </ul>
          </div>
          <div className="content">
            <h2>{t("privacy.Consent")}</h2>
            <p>{t("privacy.ConsentText")}</p>
          </div>
          <div className="content">
            <h2>{t("privacy.GoverningLaw")}</h2>
            <p>{t("privacy.GoverningLawText")}</p>
          </div>
        </div>
      </div>
    </>
  );
}
export { PrivacyPolicy };
