import React, { useState, useEffect } from "react";
import { Link, NavLink, Route, Routes } from "react-router-dom";

//auth
import { useAuth } from "../../../context/AuthProvider";

//dashboard
import { BlogDashboard } from "./blog/blog";
import { NewBlog } from "./blog/new";
import { EditBlog } from "./blog/edit";
import { ContactDashboard } from "./contact/contact";
import { ViewContactDashboard } from "./contact/view";
import { SubscribeDashboard } from "./subscribe/subscribe";

function Dashboard() {
  const logo = require("../../../assets/img/logo.png");
  //validation auth
  const auth = useAuth();

  const onLogout = () => {
    auth.logout();
  };
  return (
    <>
      <div className="cms">
        <aside className="primary-color-bg">
          <div className="menu">
            <div className="d-flex flex-column flex-shrink-0 p-3 ">
              <p
                href="/"
                className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
              >
                <span className="fs-4">Home</span>
              </p>
              <hr />
              <ul className="nav nav-pills flex-column mb-auto">
                <li>
                  <Link to={"/dashboard"} className="nav-link text-white">
                    <i className="fa-solid fa-gauge-simple-high"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/dashboard/blog-dashboard"}
                    className="nav-link text-white"
                  >
                    <i className="fa-brands fa-blogger"></i> Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to={"ContactDashboard"}
                    className="nav-link text-white "
                  >
                    <i className="fa-regular fa-address-card"></i> Contact
                  </Link>
                </li>
                <li>
                  <Link to={"subscribe"} className="nav-link text-white">
                    <i className="fa-solid fa-square-rss"></i> Subscribers
                  </Link>
                </li>
              </ul>
              <hr />
              <p className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                <span className="fs-4">Profile</span>
              </p>
              <hr />
              <ul className="nav nav-pills flex-column mb-auto">
                <li>
                  <a href="#" className="nav-link text-white">
                    <i className="fa-solid fa-pen"></i> Edit
                  </a>
                  <a onClick={onLogout} className="nav-link text-white">
                    <i className="fa-solid fa-right-from-bracket"></i> Log out
                  </a>
                </li>
              </ul>
              <hr />
            </div>
          </div>
        </aside>

        <main>
          <nav className="navbar bg-body-tertiary nav-bg mb-3">
            <div className="container">
              <a className="navbar-brand" href="#">
                <img loading="lazy" src={logo} alt="Bootstrap" className="" />
              </a>
            </div>
          </nav>
          <Routes>
            <Route path="/blog-dashboard" element={<BlogDashboard />} />
            <Route path="/new-blog" element={<NewBlog />} />
            <Route path="/edit-blog/:id" element={<EditBlog />} />
            <Route path="/ContactDashboard" element={<ContactDashboard />} />
            <Route
              path="/view-contact/:id"
              element={<ViewContactDashboard />}
            />
            <Route path="/subscribe" element={<SubscribeDashboard />} />
          </Routes>
        </main>
      </div>
    </>
  );
}
export { Dashboard };
