import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
//modal
import CustomModal from "./portfolio/modalCustom";
import {
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

/* slider */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//pages
import SliderSocial from "./portfolio/sliderSocial";
//3d slide
import Example from "./portfolio/carousel3D";
//slider reel
import SliderReels from "./portfolio/sliderReels";
//slider identity
import SliderIdentity from "./portfolio/identity";
//slider portfolio
import SliderPortfolio from "./portfolio/sliderPortfolio";

function Portfolio() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  //images
  const header = isMobile
    ? require("../../assets/img/portfolio/Banner-movil.png")
    : require("../../assets/img/portfolio/banner.png");
  const fb = require("../../assets/img/portfolio/fon-fijo.webp");
  const btnFb = require("../../assets/img/portfolio/boton-fijo.png");
  const bannerA = require("../../assets/img/portfolio/banner-a.webp");
  const bannerB = require("../../assets/img/portfolio/banner-b.webp");
  const bannerC = require("../../assets/img/portfolio/banner-c.webp");

  const logoA = require("../../assets/img/portfolio/logo-a.webp");
  const logoB = require("../../assets/img/portfolio/logo-b.webp");
  const logoC = require("../../assets/img/portfolio/logo-c.webp");

  //slide social
  const post1 = require("../../assets/img/portfolio/post/post-1.webp");
  const post2 = require("../../assets/img/portfolio/post/post-2.webp");
  const post3 = require("../../assets/img/portfolio/post/post-3.webp");
  const post4 = require("../../assets/img/portfolio/post/post-4.webp");
  const post5 = require("../../assets/img/portfolio/post/post-5.webp");
  //post 1
  //post 2
  const postDetail1_1 = require("../../assets/img/portfolio/post/post1/Aero-1.webp");
  const postDetail1_2 = require("../../assets/img/portfolio/post/post1/Aero-2.webp");
  const postDetail1_3 = require("../../assets/img/portfolio/post/post1/Aero-3.webp");
  const postDetail1_4 = require("../../assets/img/portfolio/post/post1/Aero-4.webp");
  //post 2
  const postDetail2_1 = require("../../assets/img/portfolio/post/post2/Epix-1.webp");
  const postDetail2_2 = require("../../assets/img/portfolio/post/post2/Epix-2.webp");
  const postDetail2_3 = require("../../assets/img/portfolio/post/post2/Epix-3.webp");
  const postDetail2_4 = require("../../assets/img/portfolio/post/post2/Epix-4.webp");
  //post3
  const postDetail3_1 = require("../../assets/img/portfolio/post/post3/juve-1.webp"); //cambia por inyeccion
  const postDetail3_2 = require("../../assets/img/portfolio/post/post3/juve-2.webp");
  const postDetail3_3 = require("../../assets/img/portfolio/post/post3/juve-3.webp"); //por el del vesitdo de banio
  const postDetail3_4 = require("../../assets/img/portfolio/post/post3/juve-4.webp");
  //post 4
  const postDetail4_1 = require("../../assets/img/portfolio/post/post4/dhl-1.webp");
  const postDetail4_2 = require("../../assets/img/portfolio/post/post4/dhl-2.webp");
  const postDetail4_3 = require("../../assets/img/portfolio/post/post4/dhl-3.webp");
  const postDetail4_4 = require("../../assets/img/portfolio/post/post4/dhl-4.webp");
  //post 5
  const postDetail5_1 = require("../../assets/img/portfolio/post/post5/ADM-1.webp");
  const postDetail5_2 = require("../../assets/img/portfolio/post/post5/ADM-2.webp");
  const postDetail5_3 = require("../../assets/img/portfolio/post/post5/ADM-3.webp");
  const postDetail5_4 = require("../../assets/img/portfolio/post/post5/ADM-4.webp");

  /* images social array */
  const arrPost1 = [postDetail1_1, postDetail1_2, postDetail1_3, postDetail1_4];
  const arrPost2 = [postDetail2_1, postDetail2_2, postDetail2_3, postDetail2_4];
  const arrPost3 = [postDetail3_1, postDetail3_2, postDetail3_3, postDetail3_4];
  const arrPost4 = [postDetail4_1, postDetail4_2, postDetail4_3, postDetail4_4];
  const arrPost5 = [postDetail5_1, postDetail5_2, postDetail5_3, postDetail5_4];

  //social images

  // State for managing banner images
  const [bannerImages, setBannerImages] = useState([bannerA, bannerB, bannerC]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [bannerImagesLogo, setBannerImagesLogo] = useState([
    logoA,
    logoB,
    logoC,
  ]);
  const [currentBannerIndexLogo, setCurrentBannerIndexLogo] = useState(0);
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    const timeout = setTimeout(() => {
      setShowBanner(false);
      setTimeout(() => {
        setCurrentBannerIndex(
          (prevIndex) => (prevIndex + 1) % bannerImages.length
        );
        setCurrentBannerIndexLogo(
          (prevIndex) => (prevIndex + 1) % bannerImagesLogo.length
        );
        setShowBanner(true);
      }, 0);
    }, 6000);
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", handleResize);
    };
  }, [currentBannerIndex, currentBannerIndexLogo, showBanner, isMobile]);
  const feedFB = () => {
    return (
      <section className="feedFB">
        <div className="title">
          <h1>Creative Campaigns</h1>
        </div>
        <ParallaxProvider>
          <ParallaxBanner
            style={{
              aspectRatio: "2/ 1",
            }}
          >
            <ParallaxBannerLayer
              image={fb}
              speed={isMobile ? 1 : -3}
              className="one"
            />
            <ParallaxBannerLayer
              image={bannerImages[currentBannerIndex]}
              speed={isMobile ? -1 : 2}
              className={`two ${showBanner ? "fade-in" : "fade-out"}`}
            />
            {/*  <ParallaxBannerLayer image={btnFb} speed={-2} className="one" /> */}

            <ParallaxBannerLayer
              image={bannerImagesLogo[currentBannerIndexLogo]}
              speed={isMobile ? 1 : -3}
              className={`tree ${showBanner ? "fade-in" : "fade-out"}`}
            />
          </ParallaxBanner>
        </ParallaxProvider>
      </section>
    );
  };

  const slider = () => {
    const settings = {
      dots: true,
      speed: 1500,
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 10000,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };

    return (
      <div className="slider-1">
        <div className="title">
          <h1>Social Media Spotlights</h1>
        </div>
        <Slider {...settings} className="">
          <div
            className="box"
            onClick={() => {
              setTitleModule(`test`);
              setItemData(arrPost1);
              setKeyModule("user");
              setSizeModal("xl");
              handleShow();
            }}
          >
            <img src={post1} alt="" />
          </div>
          <div
            className="box"
            onClick={() => {
              setTitleModule(`test`);
              setItemData(arrPost2);
              setKeyModule("user");
              setSizeModal("xl");
              handleShow();
            }}
          >
            <img src={post2} alt="" />
          </div>
          <div
            className="box"
            onClick={() => {
              setTitleModule(`test`);
              setItemData(arrPost3);
              setKeyModule("user");
              setSizeModal("xl");
              handleShow();
            }}
          >
            <img src={post3} alt="" />
          </div>

          <div
            className="box"
            onClick={() => {
              setTitleModule(`test`);
              setItemData(arrPost5);
              setKeyModule("user");
              setSizeModal("xl");
              handleShow();
            }}
          >
            <img src={post5} alt="" />
          </div>
        </Slider>
      </div>
    );
  };

  /* modal */
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [sizeModal, setSizeModal] = useState("");

  //module title
  const [titleModule, setTitleModule] = useState("");
  const [keyModule, setKeyModule] = useState("");
  const [itemData, setItemData] = useState([]);

  //modal content
  const modalTitle = titleModule;
  const modalContent = (
    <>
      {(() => {
        switch (keyModule) {
          case "user":
            return <SliderSocial data={itemData} />;
          default:
            return null;
        }
      })()}
    </>
  );
  return (
    <>
      <CustomModal
        size={sizeModal}
        show={showModal}
        onHide={handleClose}
        modal_title={modalTitle}
        modal_content={modalContent}
      />

      <div className="portfolio">
        <div className="header bg-img">
          {/*  <img className="bg-img" alt="charlieapps charlie apps portfolio" /> */}
          <Example />
          <div className="title">
            <h1>User-Centered </h1>
            <h1 className="span">Interfaces and Experiences</h1>
          </div>
        </div>
        {/* portfolio */}
        <div className="slider-portfolio-bg">
          <div className="container">
            <SliderPortfolio />
          </div>
        </div>
        <div className="container">
          {/* parallax */}
          {feedFB()}
        </div>
        {/* slider */}
        <div className="slider-social-bg">
          <div className="container">{slider()}</div>
        </div>
        {/* reels */}
        <div className="slider-reels-bg ">
          <div className="container">
            <SliderReels />
          </div>
        </div>
        {/* identity */}
        <div className="slider-identity-bg">
          <div className="container">
            <SliderIdentity />
          </div>
        </div>
      </div>
    </>
  );
}
export { Portfolio };
