import React, { useEffect, useState } from "react";
import {
  HashRouter,
  Route,
  Routes,
  useLocation,
  BrowserRouter,
} from "react-router-dom";

import {
  AuthProvider,
  AuthRoute,
  PrivateRoute,
} from "./context/AuthProvider.js";

//located
import { useGeolocated } from "react-geolocated";

import mapboxSdk from "@mapbox/mapbox-sdk/services/geocoding";

//pages layout
import { Menu } from "./components/layouts/menu";
import { Footer } from "./components/layouts/footer";
//pages
import { Home } from "./components/pages/home";
import { Services } from "./components/pages/services";
import { About } from "./components/pages/about";
import { Blog } from "./components/pages/blog";
import { NotFound } from "./components/pages/NotFound.js";

//auth
import { LogIn } from "./components/pages/ auth/login";
import { NewUser } from "./components/pages/ auth/createUser";
import { RecoveryPassword } from "./components/pages/ auth/recoverPassword.js";
import { Password } from "./components/pages/ auth/password.js";

//Dashboard
import { Dashboard } from "./components/pages/cms/dashboard";

//traslate
import { useTranslation, Trans } from "react-i18next";

//languages
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import global_en from "./langugages/en/global.json";
import global_es from "./langugages/es/global.json";
import global_fr from "./langugages/fr/global.json";
import global_de from "./langugages/de/global.json";
import global_it from "./langugages/it/global.json";

//poner aqui la localizacion que apenas abra la pagina sepa donde estoy

i18next.init({
  interpolation: {
    escapeValue: false,
  },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    es: {
      global: global_es,
    },
    fr: {
      global: global_fr,
    },
    de: {
      global: global_de,
    },
    it: {
      global: global_it,
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <I18nextProvider i18n={i18next}>
          <AppContent />
        </I18nextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
async function getCountryFromCoordinates(latitude, longitude, geocodingClient) {
  try {
    const response = await geocodingClient
      .reverseGeocode({
        query: [longitude, latitude],
        types: ["country", "region"],
      })
      .send();
    // console.log(response)

    if (response && response.body.features.length > 0) {
      const country =
        response.body.features[1].properties.short_code.toLowerCase();
      const countryRegion =
        response.body.features[0].properties.short_code.toLowerCase();

      if (country === "ch") {
        switch (countryRegion) {
          case "de":
            return "de";
          case "fr":
            return "fr";
          case "it":
            return "it";
          default:
            return "en";
        }
      }
      return country;
    } else {
      return "en";
    }
  } catch (error) {
    console.error(error);
    return "en";
  }
}

function AppContent() {
  const [country, setCountry] = useState("");
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState("");
  const { t, i18n } = useTranslation("global");
  const isGeolocated = useGeolocated();

  const geocodingClient = mapboxSdk({
    accessToken:
      "pk.eyJ1IjoibWZobCIsImEiOiJjbGxxc2hsODAwZnA4M2ZzNTk2aG16MXNzIn0.S3cNANoj_B7yuXX6PYNLjA",
  });

  useEffect(() => {
    //get geoposition and switch country
    const fetchLocationAndSetLanguage = async () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const resolvedCountry = await getCountryFromCoordinates(
            position.coords.latitude,
            position.coords.longitude,
            geocodingClient
          );
          switch (resolvedCountry) {
            case "co":
              i18n.changeLanguage("es");
              break;
            case "fr":
              i18n.changeLanguage("fr");
              break;
            case "de":
              i18n.changeLanguage("de");
              break;
            case "it":
              i18n.changeLanguage("it");
              break;
            case "ch":
              i18n.changeLanguage("de");
              break;
            default:
              i18n.changeLanguage("en");
          }
        });
      }
    };
    fetchLocationAndSetLanguage();
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<LogIn />}>
        {" "}
      </Route>
      <Route path="/recovery" element={<RecoveryPassword />}>
        {" "}
      </Route>
      <Route path="/password/:token" element={<Password />}>
        {" "}
      </Route>
      <Route path="/new-user" element={<NewUser />}>
        {" "}
      </Route>
      <Route path="/*" element={<Menu />}>
        {" "}
      </Route>
      <Route
        path="/dashboard/*"
        element={
          <AuthRoute>
            <Dashboard />
          </AuthRoute>
        }
      >
        {" "}
      </Route>
    </Routes>
  );
}

export default App;
