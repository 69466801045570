import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
// #1 import quill-image-uploader
import ImageUploader from "quill-image-uploader";

import { useAuth } from '../../../../context/AuthProvider';

//user service
import blogService from '../../../../services/blog.service'

// #2 register module
Quill.register("modules/imageUploader", ImageUploader);

function NewBlog() {
    //validation auth
    const auth = useAuth();
}
const withAuth = (Component) => {
    return function WrappedComponent(props) {
        const auth = useAuth();
        return <Component auth={auth} {...props} />;
    };
};
class Editor extends Component {
    constructor(props) {
        super(props);
        //this.state = { editorHtml: "" };
        this.state = { editorHtml: props.initialContent || "" };
        this.handleChange = this.handleChange.bind(this);
        this.textInput = React.createRef();
        this.handlePost = this.handlePost.bind(this);
    }

    handleChange(html) {
        this.setState({ editorHtml: html });
        this.props.onEditorContentChange(html);
    }

    handleSubmit() {
        const editor = this.reactQuillRef.getEditor();
        this.setState({
            editorHtml: editor.root.innerHTML
        });
    }
    handlePost() {
        const { editorHtml } = this.state;
    }

    modules = {
        // #3 Add "image" to the toolbar
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" }
            ],
            ["link", "image"],
            ["clean"]
        ],
        // # 4 Add module and upload function
        imageUploader: {
            upload: (file) => {
                return new Promise(async (resolve, reject) => {
                    const formData = new FormData();
                    formData.append("image", file);
                    let bearer_Token = {
                        'headers': {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.props.auth.authToken()}`
                        }
                        
                    };
                    try {
                        const uploadImage = await blogService.createBlogImages(formData, bearer_Token);
                        if (uploadImage.status === 200 && uploadImage.data.statusCode === 201) {
                            //console.log(uploadImage.data.imaUrl);
                            resolve(uploadImage.data.imaUrl);
                        } else {
                            
                            reject("Upload failed");
                        }
                    } catch (error) {
                        reject("Upload failed");
                    }
                });
            }
        }
    };

    formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "imageBlot" // #5 Optinal if using custom formats
    ];

    render() {
        return (
            <>
                {/*   <div dangerouslySetInnerHTML={{ __html: this.state.editorHtml }} /> */}

                <ReactQuill
                    onChange={this.handleChange}
                    theme="snow"
                    style={{
                        minHeight: "30vh"
                    }}
                    modules={this.modules}
                    formats={this.formats}
                    value={this.state.editorHtml}
                    ref={(el) => {
                        this.reactQuillRef = el;
                    }}
                />
            </>
        );
    }
}

export default withAuth(Editor);
