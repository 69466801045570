import React, { useState, useEffect } from "react";
import {
  Link,
  NavLink,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";

//user service
import AuthService from "../../../services/auth.service";

//auth
import { useAuth, AuthRoute } from "../../../context/AuthProvider.js";

//formik
import { Field, useFormik } from "formik";
import * as Yup from "yup";

//bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

//validation form
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/(?=.*[0-9])/, "Password must contain a number."),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

function Password() {
  const logo = require("../../../assets/img/logo.png");

  //naviagte
  const navigate = useNavigate();
  const location = useLocation();
  const tokenUrl = useParams();

  //validation auth
  const auth = useAuth();
  //message error
  const [message, setMessage] = useState(null);
  //alert
  const [showAlert, setShowAlert] = useState(true);

  //modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const onUpdatePassword = async (values) => {
    try {
      const token = tokenUrl.token;
      let data = {
        token: token,
      };
      const bearer_Token = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const urlEncodedValuePassword = encodeURIComponent(values.password);
      const changePassword = await AuthService.changePassword(
        urlEncodedValuePassword,
        data,
        bearer_Token
      );
      console.log(changePassword);
      if (
        (changePassword.status === 200) &
        (changePassword.data.statusCode === 200)
      ) {
        setShow(true);
        setMessage({ error: false, msg: "Password successfully updated!" });
      } else if (
        (changePassword.status === 200) &
        (changePassword.data.statusCode === 400)
      ) {
        setShow(true);
        setShowAlert(true);
        setMessage({ error: true, msg: "An error has occurred! Try again" });
      }
    } catch (error) {
      setShow(true);
      setShowAlert(true);
      setMessage({ error: true, msg: "An error has occurred! Try again" });
    }
  };

  //formik
  const formik = useFormik({
    initialValues: { password: "", passwordConfirmation: "" },
    validateOnBlur: true,
    onSubmit: (values) => {
      onUpdatePassword(values);
    },
    validationSchema: validationSchema,
  });

  return (
    <>
      <div className="container top-header">
        <div className="row">
          <main className="form-signin w-100 m-auto text-center">
            <form onSubmit={formik.handleSubmit}>
              <img
                loading="lazy"
                className="mb-4 img-fluid mx-auto d-block"
                src={logo}
              />
              <h1 className="h3 mb-3 mt-3 fw-normal"> sign in</h1>
              {message ? (
                <Alert
                  show={showAlert}
                  variant={`${message.error ? "danger" : "success"}`}
                >
                  <p className="alert-message">{message.msg}</p>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <Button
                      onClick={() => setShowAlert(false)}
                      size="sm"
                      variant={`outline-${
                        message.error ? "danger" : "success"
                      }`}
                    >
                      Close
                    </Button>
                  </div>
                </Alert>
              ) : null}
              <div className="form-floating">
                <input
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />
                {formik.errors.password && formik.touchedpasswordl ? (
                  <label className="text-danger">
                    Password: {formik.errors.password}
                  </label>
                ) : (
                  <label>Password</label>
                )}
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  name="passwordConfirmation"
                  placeholder="Enter your password"
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control"
                />

                {formik.errors.passwordConfirmation &&
                formik.touched.passwordConfirmation ? (
                  <label className="text-danger">
                    Password Confirmation: {formik.errors.passwordConfirmation}
                  </label>
                ) : (
                  <label>Password Confirmation</label>
                )}
              </div>
              <button
                disabled={!formik.isValid}
                className="w-100 btn btn-lg  primary-color-bg"
                type="submit"
              >
                Change
              </button>
            </form>
            <div className="mt-3 mb-3">
              <Link to={"/login"} className="no-underline primary-color">
                Sig In
              </Link>
              <hr />
              <Link to={"/new-user"} className="no-underline primary-color">
                Register
              </Link>
            </div>
            <p className="mt-3 mb-3 text-body-secondary">&copy; 2023</p>
          </main>
          <div></div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        className=" modal-login"
      >
        <div className="modal-content content">
          <div className="modal-body">
            <p className="text-center">{message ? message.msg : ""}</p>
          </div>
          <div className="modal-footer footer-login-modal  d-md-block  text-center">
            <NavLink to={"/login"}>
              <button type="button" className="btn btn-sm btn-send ">
                Close
              </button>
            </NavLink>
          </div>
        </div>
      </Modal>
    </>
  );
}
export { Password };
